import { Dispatch, bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { toastr } from "react-redux-toastr";
import { useTranslation } from "react-i18next";

import { Action, setLoading } from "../../actions";
import { postImpersonated } from "../../actions/impersonation";
import useActiveImpersonatedSsn from "../../hooks/impersonation/useActiveImpersonatedSsn";
import useImpersonationData from "../../hooks/impersonation/useImpersonationData";

import AlertBar from "../AlertBar";
import { localStorageKeys } from "../../constants";
import { toaster } from "../../utils/toaster";

interface Props {
  setLoading: (value: boolean) => Action;
  postImpersonated: (ssn: string) => any;
}

const ImpersonatedAlertBar = ({ setLoading, postImpersonated }: Props) => {
  const { t: translate } = useTranslation(["app", "impersonation"]);
  const t = (key: string, defaultValue: string) =>
    translate(key, { ns: "impersonation", defaultValue });

  const activeImpersonatedSsn = useActiveImpersonatedSsn();
  const impersonationData = useImpersonationData();

  const handleRemoveImpersonation = async () => {
    try {
      setLoading(true);

      localStorage.setItem(
        localStorageKeys.showImpersonatedMessage,
        JSON.stringify(true)
      );

      await postImpersonated(activeImpersonatedSsn);

      window.location.reload();
    } catch (e) {
      toaster({
        message: t(
          "validation.error.changingProfile",
          "Fehler beim Ändern des Profils"
        ),
        type: "error",
      });

      localStorage.removeItem(localStorageKeys.showImpersonatedMessage);

      setLoading(false);
    }
  };

  return (
    <AlertBar
      callback={handleRemoveImpersonation}
      closeButtonText={translate("buttons.close.label", {
        ns: "app",
        defaultValue: "Schliessen",
      })}
      text={impersonationData.text}
      type="warning"
    />
  );
};

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  setLoading: (value: boolean) => dispatch(setLoading(value)),
  postImpersonated: bindActionCreators(postImpersonated, dispatch),
});

const connector = connect(null, mapDispatchToProps);
export default withRouter(connector(ImpersonatedAlertBar));
