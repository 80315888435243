import { useEffect } from "react";
import { Button, ButtonColors, ButtonTypes } from "icr-shared-components/dist";
import { bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";

import Card, { Type } from "../../components/Card";
import { ReactComponent as Arrow } from "../../assets/icons/arrow-right.svg";
import { Top } from "../../components/Card/Content/Top";
import { ReactComponent as ExitIcon } from "../../assets/icons/Desktop/exit-icon.svg";
import { RootState } from "../../reducers";
import { Action, setLoading } from "../../actions";
import { AustrittData, getAustrittData } from "../../actions/austritt";
import { formatMoney } from "../../utils/currency";
import { useTranslation } from "react-i18next";
import { cmsSchemas } from "../../constants";
import CustomRichText from "../../components/RichText";
import { getRouteByLanguage, DefaultRouteNames } from "../../routes";
import { useGetCMSContent, useGetSidebarCMSContent } from "../../hooks/cms";
import { HygraphSidebarComponents } from "../../components/sidebar/HygraphSidebarComponents";

interface Props extends RouteComponentProps {
  getAustrittData: any;
  setLoading: any;
  austrittData: AustrittData;
}

const Austritt = (props: Props) => {
  const schema = cmsSchemas.leavingBenefit.plural;
  const { content: cmsMainContent } = useGetCMSContent({ schema });
  const { content: cmsSidebarContent } = useGetSidebarCMSContent({ schema });
  const sidebarBoxes = cmsSidebarContent?.sidebar;

  const getInitialData = async () => {
    try {
      props.setLoading(true);
      await props.getAustrittData();
      props.setLoading(false);
    } catch (error) {
      console.error("error in getting austritt page initial data", error);
    }
  };

  useEffect(() => {
    getInitialData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { t: translate } = useTranslation(["leavingPage", "app"]);
  const t = (key: string, defaultValue: string) =>
    translate(key, { ns: "leavingPage", defaultValue });

  const data = props.austrittData;

  return (
    <div className="pensionierung-page">
      <div className="page-top">
        <Button
          type={ButtonTypes.Text}
          color={ButtonColors.Action}
          onClick={() =>
            props.history.push(getRouteByLanguage(DefaultRouteNames.DASHBOARD))
          }
        >
          <Arrow className="rotate-180" />{" "}
          <span className="ml-16">
            {translate("buttons.backToDashboard", {
              ns: "app",
              defaultValue: "Zurück zum dashboard",
            })}
          </span>
        </Button>
      </div>

      <h1 className="mb-16">
        {t("title", "Neuer Arbeitgeber / Selbstständigkeit / Auswandern")}
      </h1>

      <p className="text-large mb-48">
        {t(
          "text",
          "Sie treten aus der Pensionskasse aus? Hier erfahren Sie, was Sie unternehmen müssen."
        )}
      </p>

      <div className="versicherungen-page-content two-column-layout">
        <div className="left">
          <Card className="mb-48" type={Type.Standard}>
            <Top
              title={formatMoney(data.leavingBenefitBox.value)}
              icon={<ExitIcon />}
              text={t(
                "leavingBenefitBox.label",
                "aktuelle Austrittsleistung in CHF"
              )}
            />
          </Card>

          {cmsMainContent?.main && (
            <Card type={Type.Standard}>
              <CustomRichText content={cmsMainContent?.main} />
            </Card>
          )}
        </div>

        <div className="right">
          <Card>
            {sidebarBoxes && (
              <HygraphSidebarComponents boxes={sidebarBoxes} data={data} />
            )}
          </Card>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  austrittData: state.austritt.austrittData,
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  getAustrittData: bindActionCreators(getAustrittData, dispatch),
  setLoading: (val) => dispatch(setLoading(val)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default withRouter(connector(Austritt));
