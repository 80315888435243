import { Children } from "react";
import {
    EMPTY_VALIDATION_MESSAGE_CONFIG,
    SUCCESS_VALIDATION_MESSAGE_CONFIG,
    EValidationMessageType,
} from "../components/ValidationMessage";
import {EValidationTestType, VALIDATION_MESSAGE} from "./validationConfigs";
import {testsFailed} from "./validationTests";
import {defined} from "./variableEvaluation";

export type IFromConfig = {
    model: string;
    name: string;
    standardMessage?: {message: string};
    validationTests: EValidationTestType[];
    visible: boolean;
    showSuccess?:boolean
};

type TValidationMessage = {
    formName: string;
    modelName: string;
    inputName: string;
    validationTests: EValidationTestType[];
    formObj: IFromConfig;
    showSuccess: boolean;
    checkAgainstValidationValues?: any;
};
const validationMessage = ({
    formName,
    modelName,
    inputName,
    validationTests,
    formObj,
    showSuccess,
    checkAgainstValidationValues,
}: TValidationMessage) => {
    let testsFailedArr: EValidationTestType[] = [];
    if (modelName.length > 0) {
        if (checkAgainstValidationValues) {
            testsFailedArr = testsFailed(
                validationTests,
                formObj[modelName][inputName],
                checkAgainstValidationValues
            );
        } else {
            testsFailedArr = testsFailed(
                validationTests,
                formObj[modelName][inputName]
            );
        }
    } else {
        if (checkAgainstValidationValues) {
            testsFailedArr = testsFailed(
                validationTests,
                formObj[inputName],
                checkAgainstValidationValues
            );
        } else {
            testsFailedArr = testsFailed(validationTests, formObj[inputName]);
        }
    }

    if (testsFailedArr.length > 0) {
        if (checkAgainstValidationValues) {
            return VALIDATION_MESSAGE[testsFailedArr[0]](
                checkAgainstValidationValues
            );
        } else {
            return VALIDATION_MESSAGE[testsFailedArr[0]]();
        }
    } else if (showSuccess) {
        return SUCCESS_VALIDATION_MESSAGE_CONFIG;
    } else {
        return EMPTY_VALIDATION_MESSAGE_CONFIG;
    }
};

type TValidationMessageConfig = {
    formName: string;
    modelName: string;
    inputName: string;
    defaultValidationMessageConfig: {message: string};
    validationTests: EValidationTestType[];
    formObj: IFromConfig;
    showSuccess: boolean;
    checkAgainstValidationValues?: any;
};
const validationMessageConfig = ({
    formName,
    modelName,
    inputName,
    defaultValidationMessageConfig,
    validationTests,
    formObj,
    showSuccess,
    checkAgainstValidationValues,
}: TValidationMessageConfig) => {
    // console.log("validationMessageConfig", formName, modelName, inputName, defaultValidationMessageConfig, validationTests, formObj);
    if (true) {
        if (defined(validationTests)) {
            if (checkAgainstValidationValues) {
                return validationMessage({
                    formName,
                    modelName,
                    inputName,
                    validationTests,
                    formObj,
                    showSuccess,
                    checkAgainstValidationValues,
                });
            } else {
                return validationMessage({
                    formName,
                    modelName,
                    inputName,
                    validationTests,
                    showSuccess,
                    formObj,
                });
            }
        } else {
            return EMPTY_VALIDATION_MESSAGE_CONFIG;
        }
    } else {
        return (
            defaultValidationMessageConfig || EMPTY_VALIDATION_MESSAGE_CONFIG
        );
    }
};

type TValidationMessageConf = {
    formName: string;
    modelName: string;
    inputName: string;
    standardMessage: {message: string};
    validationTests: EValidationTestType[];
    formObj: any;
    showSuccess: boolean;
    checkValidationAgainst?: any;
};
export const validationMessageConf = (args: TValidationMessageConf) => {
    const firstValidation = validationMessageConfig({
        ...args,
        defaultValidationMessageConfig: args.standardMessage,
        checkAgainstValidationValues: args.checkValidationAgainst,
    });
    //const secondValidation = this.props.backendValidation[formName] && this.props.backendValidation[formName][modelName] && this.props.backendValidation[formName][modelName][inputName] ? this.props.backendValidation[formName][modelName][inputName] : { message:  "", type: "" };
    //if (firstValidation.type == EValidationMessageType.Error) {
        return firstValidation;
    //}
    // else {
    //   return secondValidation;
    // }
};
