import { ReactNode, useState } from "react";

import { useClickOutside } from "../../utils/useClickOutside";
import { ReactComponent as CloseIcon } from "../../assets/icons/close-rounded.svg";
import { ReactComponent as MoreIcon } from "../../assets/icons/more.svg";

import {
  StyledButton,
  StyledButtonOverflow,
  StyledCard,
  StyledIconWrapper,
  StyledFixedWrapper,
  StyledWrapper,
} from "./styles";

interface Props {
  children: ReactNode;
  buttonLabel?: string;
}

const ActionButton = ({ children, buttonLabel }: Props) => {
  const [isOpen, setIsOpen] = useState(false);

  const ref = useClickOutside(() => setIsOpen(false));

  const toggleOpen = () => {
    setIsOpen((currentState) => !currentState);
  };

  return (
    <StyledFixedWrapper ref={ref}>
      <StyledWrapper>
        {isOpen && <StyledCard>{children}</StyledCard>}

        <StyledButton onClick={toggleOpen} $isOpen={isOpen}>
          <StyledButtonOverflow>
            <span>{buttonLabel}</span>
            <StyledIconWrapper>
              {isOpen ? <CloseIcon /> : <MoreIcon />}
            </StyledIconWrapper>
          </StyledButtonOverflow>
        </StyledButton>
      </StyledWrapper>
    </StyledFixedWrapper>
  );
};

export default ActionButton;
