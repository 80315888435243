import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useQuery } from "urql";
import { RootState } from "../../reducers";
import {
  HYGRAPH_RETRY_LIMIT,
  SET_HYGRAPH_RETRY_SIDEBAR_LIST,
} from "../../constants";
import { i18n } from "../../locale";

interface Props {
  schema: string;
}

const defaultLanguage = "de_CH";

const useGetSidebarCMSContent = ({ schema }: Props) => {
  const dispatch = useDispatch();
  const schemaRetryContentList = useSelector(
    (state: RootState) => state?.app?.hygraphSchemaRetrySidebarList
  );

  const nextRetryCount = schemaRetryContentList.hasOwnProperty(schema)
    ? schemaRetryContentList[schema]
    : 0;

  const tenant = window.localStorage.getItem("tenantName").toLowerCase();
  const parsedLocale = i18n.language.replace("-", "_");

  const availableTenant = nextRetryCount < 1 ? tenant : "default";
  const availableLanguage = nextRetryCount < 2 ? parsedLocale : defaultLanguage;

  const [result] = useQuery({
    query: `
      query {
        ${schema}(locales: ${availableLanguage}) {
          ${availableTenant} {
            sidebar {
              boxes {
                ... on UISidebarBox {
                  id
                  box {
                    id
                    boxKey
                    component {
                      ... on UITitle {
                        id
                        text
                        customFontSize
                        customLineHeight
                      }
                      ... on UIDescription {
                        id
                        text
                        marginBottom
                      }
                      ... on UIButton {
                        buttonId
                        icon
                        id
                        label
                        link
                        variant
                      }
                      ... on UIContent {
                        id
                        __typename
                        content {
                          raw
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    `,
  });

  const { data, fetching, error } = result;

  const content = {
    sidebar: data?.[schema][0]?.[availableTenant]?.sidebar?.boxes || null,
  };

  useEffect(() => {
    if (nextRetryCount >= HYGRAPH_RETRY_LIMIT) return;

    if (
      (!fetching && error && error.name === "CombinedError") ||
      (!fetching && data && content.sidebar === null)
    ) {
      dispatch({
        type: SET_HYGRAPH_RETRY_SIDEBAR_LIST,
        payload: {
          schema,
          initialCount: availableLanguage === defaultLanguage ? 2 : 1,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetching]);

  return {
    content,
    fetching,
    error,
  };
};

export default useGetSidebarCMSContent;
