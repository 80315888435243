import React, {FC, ReactElement, useEffect, useState} from 'react';

const Delayed: FC<{ wait: number, children: ReactElement }> = ({wait, children}) => {
    const [show, setShow] = useState(false);

    useEffect(() => {
        let timer = setTimeout(() => setShow(true), wait * 1000)

        return () => clearTimeout(timer);
    }, []);

    return show ? children : null;
};

export default Delayed;
