import { Button, ButtonColors, ButtonTypes } from "icr-shared-components";
import { FC } from "react";
import { ReactComponent as Arrow } from "../../assets/icons/arrow-right.svg";
import { ReactComponent as ExternalLink } from "../../assets/icons/external-link.svg";
import { FinanceBoxProps } from "../../types";
import SlateTranslator from "../SlateTranslator";

// TODO: apply approach to translations
const FinanceBox: FC<FinanceBoxProps> = (props) => {
  const { actionsList, content, isVisible, subTitle, text, title } = props;
  return props && isVisible ? (
    <>
      <div className="p-48">
        {title && <h4 className="mb-16">{title}</h4>}

        {subTitle && <p className=" mb-32">{subTitle}</p>}

        {actionsList?.map((item, index) => {
          const isTargetBlank = !!item.externalLink;
          return (
            <Button
              key={index}
              type={ButtonTypes.Text}
              color={ButtonColors.Action}
              onClick={() =>
                window.open(
                  item.link,
                  isTargetBlank ? "_blank" : "_self",
                  "noopener noreferrer"
                )
              }
              className="mt-4 mb-4 p-0 inline-block text-left"
            >
              {item.linkText}
              {!isTargetBlank ? (
                <Arrow className="ml-16" />
              ) : (
                <ExternalLink className="ml-16" />
              )}
            </Button>
          );
        })}
        {content && <SlateTranslator data={content} />}
        {text && <p className="text-medium mb-32 mt-32">{text}</p>}
      </div>
      <hr />
    </>
  ) : null;
};

export default FinanceBox;
