import {
  Button,
  ButtonColors,
  ButtonStyles,
  ButtonTypes,
} from "icr-shared-components";
import { useHistory } from "react-router";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import Document from "./Document";

import { RootState } from "../../reducers";
import { Action, setLoading } from "../../actions";
import { ZusatzsparenApplyData } from "../../actions/zusatzsparen";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { getRouteByLanguage, DefaultRouteNames } from "../../routes";

interface Props {
  zusatzsparenApplyData: ZusatzsparenApplyData;
  planId: string;
  setLoading: any;
}

const ZusatzsparenApply = ({ zusatzsparenApplyData, setLoading }: Props) => {
  const { t: translate } = useTranslation(["additionalSavingsPage", "app"]);
  const t = (key: string, defaultValue: string) =>
    translate(key, { defaultValue });
  const history = useHistory();
  const [pdfLink, setPdfLink] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const onDocumentOpen = (link) => {
    setLoading(true);
    setPdfLink(link);
    setIsModalOpen(true);
  };

  const onDocumentLoad = (e) => {
    setLoading(false);
  };

  return (
    <>
      <h4 className="mb-32 profile-mobile-modal-text">
        {zusatzsparenApplyData?.subTitle}
      </h4>

      <p
        className="text-medium mt-32 mb-48"
        dangerouslySetInnerHTML={{ __html: zusatzsparenApplyData?.text }}
      ></p>

      <div className="modal-buttons">
        <Button
          type={ButtonTypes.Text}
          color={ButtonColors.Action}
          onClick={() => onDocumentOpen(zusatzsparenApplyData.pdfDownloadLink)}
        >
          {t(
            "customize.apply.downloadChangeConfirmation.label",
            "ÄNDERUNGSBESTÄTIGUNG HERUNTERLADEN"
          )}
        </Button>

        <Button
          type={ButtonTypes.Standard}
          style={ButtonStyles.Primary}
          color={ButtonColors.Action}
          onClick={() =>
            history.push(
              getRouteByLanguage(DefaultRouteNames.ADDITIONALSAVINGS)
            )
          }
        >
          {t("customize.apply.returnToHome.label", "ZUM VERSICHERTENPORTAL")}
        </Button>
      </div>
      <Document
        closeModal={closeModal}
        isModalOpen={isModalOpen}
        onDocumentLoad={onDocumentLoad}
        pdfLink={pdfLink}
      ></Document>
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  zusatzsparenApplyData: state.zusatzsparen.zusatzsparenApplyData,
  planId: state.zusatzsparen.selectedPlan,
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  setLoading: (val) => dispatch(setLoading(val)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(ZusatzsparenApply);
