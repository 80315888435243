import React, {ReactElement, useState} from "react";
import classNames from "classnames";

import styles from "./Dropdown.module.scss";
import {ReactComponent as IconArrow} from "../../assets/icons/down-arrow.svg";
import {useClickOutside} from '../../utils/useClickOutside'

interface Props {
    title: any;
    type?: any;
    icon?: any;
    classNameWrapper?: any;
    classNameContent?: any;
    children(close: () => void): ReactElement;
    visibleOnMobile?: boolean;
}

export enum DropdownTypes {
    Account = "Account"
}

const Dropdown: React.FC<Props> = ({
    title,
    type,
    children,
    icon,
    visibleOnMobile,
    classNameWrapper,
    classNameContent
}) => {
    const [open, setOpen] = useState(false);

    const classes = classNames(
        styles.dropdown,
        classNameWrapper,
        {[styles.isOpen]: open},
        {[styles.account]: type === DropdownTypes.Account}
    );

    const ref = useClickOutside(() => setOpen(false));

    return (
        <div className={classes} ref={ref}>
            <div className={styles.dropdownTop} onClick={() => setOpen(!open)}>
                <div className={`${styles.dropdownTitle} ${visibleOnMobile ? styles.mobileVisible : ""}`}>
                    {title}
                </div>

                <div className={`${styles.dropdownArrow} ${visibleOnMobile ? styles.mobileVisible : ""}`}>
                    <IconArrow/>
                </div>

                {icon && <div className={styles.dropdownIcon}>
                    {icon}
                </div>}
            </div>

            <div className={classNames(styles.dropdownContent, classNameContent)}>
                {children(() => setOpen(false))}
            </div>
        </div>
    )
}

export default Dropdown;
