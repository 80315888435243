import { Button, ButtonStyles } from "icr-shared-components";
import "icr-shared-components/dist/index.css";
import { useEffect, useState } from "react";
import { useTranslation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Dispatch, bindActionCreators } from "redux";
import { Action, current, setLoading } from "../../actions";
import { DashboardData, getDashboardData } from "../../actions/dashboard";
import Card from "../../components/Card";
import { Dashboard as Content } from "../../components/Card/Content/Dashboard";
import Chart from "../../components/Chart";
import BarChart from "../../components/Chart/BarChart";
import DonutChart from "../../components/Chart/DonutChart";
import Delayed from "../../components/Delayed";
import { RootState } from "../../reducers";
import { formatMoney as fm } from "../../utils/currency";
import "./Dashboard.scss";

import { withRouter } from "react-router-dom";
import { ReactComponent as ChartIcon } from "../../assets/icons/Desktop/chart-icon.svg";
import { ReactComponent as DocumentIcon } from "../../assets/icons/Desktop/document-icon.svg";
import { ReactComponent as ExitIcon } from "../../assets/icons/Desktop/exit-icon.svg";
import { ReactComponent as HomeIcon } from "../../assets/icons/Desktop/house-icon.svg";
import { ReactComponent as PensionIcon } from "../../assets/icons/Desktop/pension-icon.svg";
import { ReactComponent as SparenIcon } from "../../assets/icons/Desktop/sparen-icon.svg";
import { ReactComponent as TodesfallIcon } from "../../assets/icons/Desktop/todesfall-icon.svg";
import { ReactComponent as UrlaubIcon } from "../../assets/icons/Desktop/urlaub-icon.svg";
import { ReactComponent as ZivilstandIcon } from "../../assets/icons/Desktop/zivistand-icon.svg";
import { ReactComponent as QuestionMark } from "../../assets/icons/question-mark.svg";
import ComingSoonStatus from "../../components/ComingSoonStatus";
import { DefaultRouteNames, getRouteByLanguage } from "../../routes";

interface DashboardProps {
  history: any;
  t: any;
  dashboardDataLoaded: boolean;
  getDashboardData: () => void;
  dashboardData: DashboardData;
  setLoading: (value: any) => any;
}

interface DashboardState {
  miniLineChartWidth: number;
  removedYears: number[];
}

const Dashboard = (props: DashboardProps) => {
  const [state, setState] = useState<DashboardState>({
    miniLineChartWidth: 0,
    removedYears: [],
  });

  const { t: translate } = useTranslation(["dashboard"]);
  const t = (key: string, defaultValue: string) =>
    translate(key, { defaultValue });

  const formatMoney = (value: number | null | undefined) => {
    return value ? fm(value) : "";
  };

  useEffect(() => {
    if (props.dashboardData.balanceBox.futureGraph.length > 0) {
      buildRemovedYears();
    }
  }, [props.dashboardData.balanceBox]);

  const buildRemovedYears = async () => {
    const { futureGraph } = dashboardData.balanceBox;
    const firstDateYear = new Date(futureGraph[0]?.date).getFullYear();
    const lastDateYear = new Date(
      futureGraph[futureGraph.length - 1]?.date
    ).getFullYear();

    if (firstDateYear !== lastDateYear) {
      const foundDatesWithSameInitialYear = futureGraph.filter(
        (item) => new Date(item.date).getFullYear() === firstDateYear
      );

      let newRemovedYears = [];

      // Removes the first year if have less then 10 months
      if (foundDatesWithSameInitialYear.length < 10) {
        newRemovedYears.push(firstDateYear);
        setState({
          ...state,
          removedYears: newRemovedYears,
        });
      }

      // Mobile removes the last year
      if (window.innerWidth < 897) {
        newRemovedYears.push(lastDateYear);
        setState({
          ...state,
          removedYears: newRemovedYears,
        });
      }
    }
  };

  const getDashboardInitialData = () => {
    try {
      props.setLoading(true);
      props.getDashboardData();
    } catch (error) {
      console.error(error);
    } finally {
      props.setLoading(false);
    }
  };

  useEffect(() => {
    getDashboardInitialData();
  }, []);

  const getQueue = () => {
    const { dashboardData, history } = props;

    if (!dashboardData) return;

    const {
      balanceBox,
      inboxBox,
      quittingBox,
      purchaseBox,
      homeOnwershipBox,
      retirementBox,
      civilStatusBox,
      fatalityBox,
      unpaidVacationBox,
      additionalSavingsBox,
    } = dashboardData;
    const { miniLineChartWidth } = state;

    let lastxAxisElement = 15;
    let lastxAxisElementHomeownership = 15;

    return [
      <Card
        isBlurred={!balanceBox.isActive}
        className="main dashboard-card fade-in"
      >
        <Content
          isBlurred={!balanceBox.isActive}
          subtitle={t("balanceBox.title", "Übersicht aktuelle Versicherung")}
          text={t("balanceBox.subTitle", "Aktuelles Sparguthaben CHF")}
          title={formatMoney(balanceBox.value)}
          icon={<SparenIcon />}
          onClick={() =>
            history.push(getRouteByLanguage(DefaultRouteNames.INSURANCE))
          }
        >
          {(balanceBox.futureGraph?.length > 0 ||
            balanceBox.historicalGraph.length > 0) && (
            <Chart
              historicalGraph={
                balanceBox.futureGraph?.length > 0
                  ? balanceBox.futureGraph
                  : balanceBox.historicalGraph
              }
              name="top"
              xAxisFormat={(d, xIndex) => {
                if (
                  d.getFullYear() !== lastxAxisElement &&
                  !state.removedYears.includes(d.getFullYear())
                ) {
                  lastxAxisElement = d.getFullYear();
                  return `${d.getFullYear()}`;
                } else {
                  return "";
                }
              }}
              yTicksDivision={1000}
            />
          )}
        </Content>
        {!balanceBox.isActive && !balanceBox.hideAwaitBox && (
          <ComingSoonStatus
            title={t("balanceBox.title", "Übersicht aktuelle Versicherung")}
          />
        )}
      </Card>,
      inboxBox.isVisible && (
        <Card isBlurred={!inboxBox.isActive} className="dashboard-card fade-in">
          <Content
            isBlurred={!inboxBox.isActive}
            subtitle={t("inboxBox.title", "Dokumente")}
            text={t("inboxBox.unreadDocumentLabel", "ungelesene Dokumente")}
            title={inboxBox.amount}
            icon={<DocumentIcon />}
            onClick={() =>
              history.push(getRouteByLanguage(DefaultRouteNames.DOCUMENTS))
            }
          />
          {!inboxBox.isActive && !inboxBox.hideAwaitBox && (
            <ComingSoonStatus title={t("inboxBox.title", "Dokumente")} />
          )}
        </Card>
      ),
      quittingBox.isVisible && (
        <Card
          isBlurred={!quittingBox.isActive}
          className="dashboard-card fade-in"
        >
          <Content
            isBlurred={!quittingBox.isActive}
            subtitle={t(
              "quittingBox.title",
              "Neuer Arbeitgeber/ Selbständig/ Auswandern"
            )}
            text={t(
              "quittingBox.subTitle",
              "aktuelle Austrittsleistung in CHF"
            )}
            title={formatMoney(quittingBox.amount)}
            icon={<ExitIcon />}
            onClick={() =>
              history.push(getRouteByLanguage(DefaultRouteNames.EXIT))
            }
          />
          {!quittingBox.isActive && !quittingBox.hideAwaitBox && (
            <ComingSoonStatus
              title={t(
                "quittingBox.title",
                "Neuer Arbeitgeber/ Selbständig/ Auswandern"
              )}
            />
          )}
        </Card>
      ),
      additionalSavingsBox.isVisible && (
        <Card
          isBlurred={!additionalSavingsBox.isActive}
          className="middle dashboard-card fade-in"
        >
          <Content
            isBlurred={!additionalSavingsBox.isActive}
            subtitle={t(
              "additionalSavingsBox.title",
              "Altersleistungen erhöhen und Steuern sparen"
            )}
            text={t(
              "additionalSavingsBox.planSubtitle",
              "Zusatzsparen Option aktuell"
            )}
            doubleText={{
              doubleTextTitle: formatMoney(
                additionalSavingsBox.maxPurchaseAmount
              ),
              doubleTextSubtitle: t(
                "additionalSavingsBox.subTitle",
                "aktuell maximal möglicher Einkauf"
              ),
            }}
            title={additionalSavingsBox.planName}
            icon={<ChartIcon />}
            onClick={() =>
              history.push(
                getRouteByLanguage(DefaultRouteNames.ADDITIONALSAVINGS)
              )
            }
          >
            <div className="dashboard-donut-chart-wrapper">
              <DonutChart
                data={{
                  total: additionalSavingsBox.maxBalanceAmount,
                  ratio: additionalSavingsBox.balanceMaxRatio,
                  items: [
                    {
                      title: purchaseBox?.tooltipTitle,
                      subtitle: purchaseBox?.tooltipSubtitle,
                      label: purchaseBox?.tooltipLabel?.replace(
                        "{0}",
                        formatMoney(
                          additionalSavingsBox.currentCreditBalance
                        ).toString()
                      ),
                      value: additionalSavingsBox?.currentCreditBalance,
                    },
                  ],
                }}
                size={300}
                backgroundStyles={{ width: "20rem", height: "20rem" }}
              />
            </div>
          </Content>
          {!additionalSavingsBox.isActive &&
            !additionalSavingsBox.hideAwaitBox && (
              <ComingSoonStatus
                title={t(
                  "additionalSavingsBox.title",
                  "Altersleistungen erhöhen und Steuern sparen"
                )}
              />
            )}
        </Card>
      ),
      purchaseBox.isVisible && (
        <Card
          isBlurred={!purchaseBox.isActive}
          className="middle dashboard-card fade-in"
        >
          <Content
            isBlurred={!purchaseBox.isActive}
            subtitle={t(
              "purchaseBox.title",
              "Altersleistungen erhöhen und Steuern sparen"
            )}
            text={t(
              "purchaseBox.subTitle",
              "Aktuell maximal möglicher Einkauf in CHF"
            )}
            title={formatMoney(purchaseBox.maxPurchaseAmount)}
            icon={<ChartIcon />}
            onClick={() =>
              history.push(getRouteByLanguage(DefaultRouteNames.BENEFITS))
            }
          >
            <div className="dashboard-donut-chart-wrapper">
              <DonutChart
                data={{
                  total: purchaseBox.maxBalanceAmount,
                  ratio: purchaseBox.balanceMaxRatio,
                  items: [
                    {
                      title: purchaseBox?.tooltipTitle,
                      subtitle: purchaseBox?.tooltipSubtitle,
                      label: purchaseBox?.tooltipLabel?.replace(
                        "{0}",
                        formatMoney(purchaseBox.currentCreditBalance).toString()
                      ),
                      value: purchaseBox?.currentCreditBalance,
                    },
                  ],
                }}
                size={300}
                backgroundStyles={{ width: "20rem", height: "20rem" }}
              />
            </div>
          </Content>
          {!purchaseBox.isActive && !purchaseBox.hideAwaitBox && (
            <ComingSoonStatus
              title={t(
                "purchaseBox.title",
                "Altersleistungen erhöhen und Steuern sparen"
              )}
            />
          )}
        </Card>
      ),
      homeOnwershipBox.isVisible && (
        <Card
          isBlurred={!homeOnwershipBox.isActive}
          className="middle dashboard-card fade-in"
        >
          <Content
            isBlurred={!homeOnwershipBox.isActive}
            subtitle={t("homeOwnershipBox.title", "Wohneigentum finanzieren")}
            text={t(
              "homeOwnershipBox.subTitle",
              "aktuell maximal möglicher WEF-Vorbezug"
            )}
            title={formatMoney(homeOnwershipBox.maxAmount)}
            icon={<HomeIcon />}
            onClick={() =>
              history.push(getRouteByLanguage(DefaultRouteNames.HOMEOWNERSHIP))
            }
          >
            {homeOnwershipBox.projectedGraphUnavailable ? (
              <div className="without-chart-content">
                {translate(
                  "homeOwnershipBox.noDataInfo",
                  "Wohneigentumsvorbezug ab {{maxAge}} Jahren ist nicht mehr möglich",
                  { maxAge: homeOnwershipBox?.maxAge }
                )}
              </div>
            ) : (
              <Chart
                chartWidth={miniLineChartWidth}
                chartHeight={180}
                yTicksDivision={1000}
                historicalGraph={homeOnwershipBox.projectedGraph}
                name="bottom-middle"
                disableResize
                xAxisFormat={(d) => {
                  if (d.getFullYear() !== lastxAxisElementHomeownership) {
                    lastxAxisElementHomeownership = d.getFullYear();
                    return `${d.getFullYear().toString().substr(2)}`;
                  } else {
                    return "";
                  }
                }}
              />
            )}
          </Content>
          {!homeOnwershipBox.isActive && !homeOnwershipBox.hideAwaitBox && (
            <ComingSoonStatus
              title={t("homeOwnershipBox.title", "Wohneigentum finanzieren")}
            />
          )}
        </Card>
      ),
      retirementBox.isVisible && (
        <Card
          isBlurred={!retirementBox.isActive}
          className="middle dashboard-card fade-in"
        >
          <Content
            isBlurred={!retirementBox.isActive}
            subtitle={t("retirementBox.title", "Pensionierung")}
            text={t("retirementBox.subTitle", "mögliche Optionen für Beiträge")}
            title={formatMoney(retirementBox.amount)}
            icon={<PensionIcon />}
            onClick={() =>
              history.push(getRouteByLanguage(DefaultRouteNames.RETIREMENT))
            }
          >
            <div className="dashboard-bar-chart-wrapper">
              <BarChart
                data={retirementBox.projectedGraph}
                defaultRetirementAge={retirementBox.defaultRetirementAge}
                hideTicks
              />
            </div>
          </Content>
          {!retirementBox.isActive && !retirementBox.hideAwaitBox && (
            <ComingSoonStatus
              title={t("retirementBox.title", "Pensionierung")}
            />
          )}
        </Card>
      ),
      civilStatusBox.isVisible && (
        <Card
          isBlurred={!civilStatusBox.isActive}
          className="dashboard-card fade-in"
        >
          <Content
            isBlurred={!civilStatusBox.isActive}
            subtitle={t("civilStatusBox.title", "Zivilstand / Konbubinat")}
            title={civilStatusBox.civilStatus}
            text={civilStatusBox.subTitle}
            icon={<ZivilstandIcon />}
            onClick={() =>
              history.push(getRouteByLanguage(DefaultRouteNames.CIVILSTATUS))
            }
          />
          {!civilStatusBox.isActive && !civilStatusBox.hideAwaitBox && (
            <ComingSoonStatus
              title={t("civilStatusBox.title", "Zivilstand / Konbubinat")}
            />
          )}
        </Card>
      ),
      fatalityBox.isVisible && (
        <Card
          isBlurred={!fatalityBox.isActive}
          className="dashboard-card fade-in"
        >
          <Content
            isBlurred={!fatalityBox.isActive}
            subtitle={t("fatalityBox.title", "Im Todesfall")}
            text={t("fatalityBox.subTitle", "Todesfallkapital in CHF")}
            title={formatMoney(fatalityBox.amount)}
            icon={<TodesfallIcon />}
            onClick={() =>
              history.push(getRouteByLanguage(DefaultRouteNames.DEATH))
            }
          />
          {!fatalityBox.isActive && !fatalityBox.hideAwaitBox && (
            <ComingSoonStatus title={t("fatalityBox.title", "Im Todesfall")} />
          )}
        </Card>
      ),
      unpaidVacationBox.isVisible && (
        <Card
          isBlurred={!unpaidVacationBox.isActive}
          className="dashboard-card fade-in"
        >
          <Content
            isBlurred={!unpaidVacationBox.isActive}
            subtitle={t("unpaidVacationBox.title", "Unbezahlter Urlaub")}
            text={t("unpaidVacationBox.subTitle", "mögliche Finanzierung")}
            title={unpaidVacationBox.amount}
            icon={<UrlaubIcon />}
            onClick={() =>
              history.push(getRouteByLanguage(DefaultRouteNames.VACATION))
            }
          />
          {!unpaidVacationBox.isActive && !unpaidVacationBox.hideAwaitBox && (
            <ComingSoonStatus
              title={t("unpaidVacationBox.title", "Unbezahlter Urlaub")}
            />
          )}
        </Card>
      ),
    ];
  };
  const queue = getQueue();
  const { dashboardDataLoaded, dashboardData } = props;

  return (
    <div className="dashboard-page">
      {dashboardDataLoaded && (
        <div className="top">
          {queue.map((el, i) => (
            <Delayed key={`el-${i}`} wait={i * 0.5} children={el} />
          ))}
        </div>
      )}

      {dashboardData.faqBox.isVisible && (
        <Button
          className="faq-button"
          style={ButtonStyles.Sticky}
          onClick={() =>
            props.history.push(getRouteByLanguage(DefaultRouteNames.QUESTIONS))
          }
        >
          <QuestionMark />
        </Button>
      )}
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  dashboardData: state.dashboard.dashboardData,
  dashboardDataLoaded: state.dashboard.dashboardDataLoaded,
});
const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  current: bindActionCreators(current, dispatch),
  getDashboardData: bindActionCreators(getDashboardData, dispatch),
  setLoading: (val) => dispatch(setLoading(val)),
});
const reduxConnector = connect(mapStateToProps, mapDispatchToProps);
const connector = (component) => withTranslation()(component);
export default withRouter(reduxConnector(connector(Dashboard)));
