import { useEffect } from "react";
import { Button, ButtonColors, ButtonTypes } from "icr-shared-components";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Action, bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";

import Card, { Type } from "../../components/Card";
import "../Versicherungen/Versicherungen.scss";
import { ReactComponent as Arrow } from "../../assets/icons/arrow-right.svg";
import { Top } from "../../components/Card/Content/Top";
import { ReactComponent as ZivilstandIcon } from "../../assets/icons/Desktop/zivistand-icon.svg";
import { getZivilstandData, ZivilstandData } from "../../actions/zivilstand";
import { RootState } from "../../reducers";
import { setLoading } from "../../actions";
import { useTranslation } from "react-i18next";
import { cmsSchemas } from "../../constants";
import CustomRichText from "../../components/RichText";
import { getRouteByLanguage, DefaultRouteNames } from "../../routes";
import { useGetCMSContent, useGetSidebarCMSContent } from "../../hooks/cms";
import { HygraphSidebarComponents } from "../../components/sidebar/HygraphSidebarComponents";

interface Props extends RouteComponentProps {
  getZivilstandData: any;
  setLoading: any;
  zivilstandData: ZivilstandData;
}

const Zivilstand = (props: Props) => {
  const schema = cmsSchemas.civilStatus.plural;
  const { content: cmsContent } = useGetCMSContent({ schema });
  const { content: cmsSidebarContent } = useGetSidebarCMSContent({ schema });
  const sidebarBoxes = cmsSidebarContent?.sidebar;

  const getInitialData = async () => {
    try {
      props.setLoading(true);
      await props.getZivilstandData();
      props.setLoading(false);
    } catch (error) {
      console.error("error in getting austritt page initial data", error);
    }
  };

  const { t: translate } = useTranslation(["civilStatusPage", "app", "common"]);
  const t = (key: string, defaultValue: string) =>
    translate(key, { ns: "civilStatusPage", defaultValue });

  useEffect(() => {
    getInitialData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const data = props.zivilstandData;

  return (
    <div className="altersleitung-page">
      <div className="page-top">
        <Button
          type={ButtonTypes.Text}
          color={ButtonColors.Action}
          onClick={() =>
            props.history.push(getRouteByLanguage(DefaultRouteNames.DASHBOARD))
          }
        >
          <Arrow className="rotate-180" />{" "}
          <span className="ml-16">
            {translate("buttons.backToDashboard", {
              ns: "app",
              defaultValue: "Zurück zum dashboard",
            })}
          </span>
        </Button>
      </div>

      <h1 className="mb-16">{t("title", "Zivilstand / Konkubinat")}</h1>

      <p className="text-large mb-48 max-w-lg-100 max-w-xl-75 max-w-50">
        {t(
          "text",
          "Ihr Zivilstand resp. Ihre Lebenspartnerschaft (Konkubinat) hat einen Einfluss auf die Leistungen."
        )}
      </p>

      <div className="versicherungen-page-content two-column-layout">
        <div className="left">
          {data.civilStatusBox && data.civilStatusBox.isVisible && (
            <Card className="mb-48" type={Type.Standard}>
              <Top
                title={data.civilStatusBox.civilStatus}
                text={data.civilStatusBox.subTitle}
                icon={<ZivilstandIcon />}
              />
            </Card>
          )}

          {cmsContent?.main && (
            <Card type={Type.Standard}>
              <CustomRichText
                content={cmsContent?.main}
                simulationBox={data.simulationBox}
              />
            </Card>
          )}
        </div>

        <div className="right">
          <Card>
            {sidebarBoxes && (
              <HygraphSidebarComponents boxes={sidebarBoxes} data={data} />
            )}
          </Card>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  zivilstandData: state.zivilstand.zivilstandData,
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  getZivilstandData: bindActionCreators(getZivilstandData, dispatch),
  setLoading: (val) => dispatch(setLoading(val)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default withRouter(connector(Zivilstand));
