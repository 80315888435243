export const SET_LOADING = "SET_LOADING";
export const SET_DRAWER_LOADING = "SET_DRAWER_LOADING";
export const SET_I18N_LOADING = "SET_I18N_LOADING";

export const LOGIN = "LOGIN";
export const LOG_OUT = "LOG_OUT";
export const SET_EMAIL = "SET_EMAIL";
export const SET_LOGIN_SMS_CODE = "SET_LOGIN_SMS_CODE";
export const SET_SESSION = "SET_SESSION";

export const SET_NAME = "SET_NAME";
export const SET_LASTNAME = "SET_LASTNAME";
export const SET_BIRTHDAY = "SET_BIRTHDAY";
export const SET_AHV_NUMBER = "SET_AHV_NUMBER";
export const SET_PHONE_NUMBER = "SET_PHONE_NUMBER";
export const SET_LOGIN_PHONE_NUMBER = "SET_LOGIN_PHONE_NUMBER";
export const SET_REGISTRATION_CODE = "SET_REGISTRATION_CODE";
export const SET_REGISTER_SMS_CODE = "SET_REGISTER_SMS_CODE";
export const SET_REGISTRATION_ID = "SET_REGISTRATION_ID";

export const SET_CURRENT_USER = "SET_CURRENT_USER";

export const SET_IMPERSONATION_DATA = "SET_IMPERSONATION_DATA";
export const SET_IMPERSONATED_USERS = "SET_IMPERSONATED_USERS";

export const GLOBAL_SETTINGS = "GLOBAL_SETTINGS";

export const SET_PROFILE_PERSONAL_INFORMATION =
  "SET_PROFILE_PERSONAL_INFORMATION";
export const SET_PROFILE_LOGIN_DATA = "SET_PROFILE_LOGIN_DATA";
export const SET_PROFILE_ADDRESS = "SET_PROFILE_ADDRESS";
export const SET_NEW_EMAIL_ADDRESS = "SET_NEW_EMAIL_ADDRESS";
export const SET_NEW_RESTORE_EMAIL_ADDRESS = "SET_NEW_RESTORE_EMAIL_ADDRESS";
export const SET_NEW_PHONE_NUMBER = "SET_NEW_PHONE_NUMBER";
export const SET_PROFILE = "SET_PROFILE";
export const SET_CONFIRMATION_TOKEN = "SET_CONFIRMATION_TOKEN";

export const SET_FAQ = "SET_FAQ";

export const SET_DESKTOP_DATA = "SET_DESKTOP_DATA";

export const SET_DOCUMENTS_SUMMARY = "SET_DOCUMENTS_SUMMARY";
export const SET_DOCUMENT_LIST = "SET_DOCUMENT_LIST";
export const SET_DOCUMENT_DATA = "SET_DOCUMENT_DATA";
export const SET_OPEN_DOCUMENT = "SET_OPEN_DOCUMENT";

export const SET_VERSICHERUNGEN_DATA = "SET_VERSICHERUNGEN_DATA";

export const SET_AUSTRITT_DATA = "SET_AUSTRITT_DATA";

export const SET_ALTERSLEISTUNG_DATA = "SET_ALTERSLEISTUNG_DATA";

export const SET_ZUSATZSPAREN_DATA = "SET_ZUSATZSPAREN_DATA";
export const SET_ZUSATZSPAREN_CUSTOMIZE_DATA =
  "SET_ZUSATZSPAREN_CUSTOMIZE_DATA";
export const SET_ZUSATZSPAREN_CONFIRMATION_DATA =
  "SET_ZUSATZSPAREN_CONFIRMATION_DATA";
export const SET_ZUSATZSPAREN_PLAN = "SET_ZUSATZSPAREN_PLAN";
export const SET_ZUSATZSPAREN_APPLY_DATA = "SET_ZUSATZSPAREN_APPLY_DATA";

export const SET_PENSIONIERUNG_DATA = "SET_PENSIONIERUNG_DATA";

export const SET_WOHNEIGENTUM_DATA = "SET_WOHNEIGENTUM_DATA";

export const SET_TODESFALL_DATA = "SET_WOHNEIGENTUM_DATA";

export const SET_ZIVILSTAND_DATA = "SET_ZIVILSTAND_DATA";

export const SET_URLAUB_DATA = "SET_URLAUB_DATA";

export const SET_CONTACT = "SET_CONTACT";
export const SET_LANGUAGES_LIST = "SET_LANGUAGES_LIST";

export const SET_HYGRAPH_RETRY_CONTENT_LIST = "SET_HYGRAPH_RETRY_CONTENT_LIST";
export const SET_HYGRAPH_RETRY_SIDEBAR_LIST = "SET_HYGRAPH_RETRY_SIDEBAR_LIST";
