import {combineReducers} from "redux";
import app, {AppState} from "./app";
import dashboard, {DashboardState} from "./dashboard";
import {reducer as toastrReducer} from "react-redux-toastr";
import profile, {ProfileState} from "./profile";
import document, {DocumentState} from "./document";
import versicherungen, {VersicherungenState} from "./versicherungen";
import austritt, {AustrittState} from "./austritt";
import altersleistung, {AltersleistungState} from "./altersleistung";
import pensionierung, {PensionierungState} from "./pensionierung";
import wohneigentum, {WohneigentumState} from "./wohneigentum";
import todesfall, {TodesfallState} from "./todesfall";
import zivilstand, {ZivilstandState} from "./zivilstand";
import urlaub, {UrlaubState} from "./urlaub";
import zusatzsparen, { ZusatzsparenState } from "./zusatzsparen";

export interface RootState {
    app: AppState;
    profile: ProfileState;
    toastr: toastrReducer;
    dashboard: DashboardState;
    document: DocumentState;
    versicherungen: VersicherungenState;
    austritt: AustrittState;
    altersleistung: AltersleistungState;
    zusatzsparen: ZusatzsparenState;
    pensionierung: PensionierungState;
    wohneigentum: WohneigentumState;
    todesfall: TodesfallState;
    zivilstand: ZivilstandState;
    urlaub: UrlaubState;
}

export default () =>
    combineReducers({
        app,
        profile,
        toastr: toastrReducer,
        dashboard,
        document,
        versicherungen,
        austritt,
        altersleistung,
        zusatzsparen,
        pensionierung,
        wohneigentum,
        todesfall,
        zivilstand,
        urlaub,
    });
