import { Fragment } from "react";
import { TElement } from "../../types/slate";
import { serializeChildren } from "../../utils/slate";
import "./SlateTranslator.scss";

type SlateTranslatorProps = {
  data: string;
};

const SlateTranslator = ({ data }: SlateTranslatorProps) => {
  if (!data) return null;
  return (
    <div className="slate-translator-wrapper">
      {JSON.parse(data).map((item: TElement, i: number) => (
        <Fragment key={i}>{serializeChildren(item)}</Fragment>
      ))}
    </div>
  );
};

export default SlateTranslator;
