import { SET_AUSTRITT_DATA } from "../constants/actions";
import { api } from "../utils/api";

export type AustrittData = {
  title: string;
  text: string;
  leavingBenefitBox: {
    label: string;
    value: number;
  };
  paymentInstructionBox: {
    title: string;
    text: string;
    link: string;
    linkText: string;
    isVisible: boolean;
  };
  reinstatementBox: {
    title: string;
    text: string;
    link: string;
    linkText: string;
    isVisible: boolean;
  };
  contactBox: {
    title: string;
    text: string;
    link: string;
    linkText: string;
    isVisible: boolean;
  };
  leavingActionsBox: {
    title: string;
    text: string;
    actionsList: {
      title: string;
      text: string;
      link: string;
      linkText: string;
    }[];
    isVisible: boolean;
  };
  leavingPageContentBox: {
    content: string;
  };
};

export const getAustrittData = (payload) => {
  return async function (dispatch) {
    try {
      const response: { data: { data: AustrittData } } = await api.get(
        "pages/leaving"
      );
      dispatch({ type: SET_AUSTRITT_DATA, payload: response.data.data });
      return response;
    } catch (err) {
      return err;
    }
  };
};
