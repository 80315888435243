import React, { useEffect, useState } from "react";
import { Button, ButtonColors, ButtonTypes } from "icr-shared-components";
import { bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";

import Card from "../../components/Card";
import "./Versicherungen.scss";
import { ReactComponent as SparenIcon } from "../../assets/icons/Desktop/sparen-icon.svg";
import { ReactComponent as Arrow } from "../../assets/icons/arrow-right.svg";
import Document from "../../components/Modals/Document";
import { Top } from "../../components/Card/Content/Top";
import Columns from "../../components/Columns";
import Chart from "../../components/Chart";
import { RootState } from "../../reducers";
import { Action, setLoading } from "../../actions";
import {
  getVersicherungenPageData,
  VersicherungenData,
} from "../../actions/versicherungen";
import { navigateToSimulator, parseDate } from "../../utils";
import { formatMoney } from "../../utils/currency";
import CreditBalanceDetailsBox from "../../components/Versicherungen/CreditBalanceDetailsBox";
import PayrollAndContributionBox from "../../components/Versicherungen/PayrollAndContributionBox";
import RegularRetirementBox from "../../components/Versicherungen/RegularRetirementBox";
import InvalidityAndDeathBenefitBox from "../../components/Versicherungen/InvalidityAndDeathBenefitBox";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { getRouteByLanguage, DefaultRouteNames } from "../../routes";
import { cmsSchemas, hashtagLinksCMSKeys } from "../../constants";
import { useGetSidebarCMSContent } from "../../hooks/cms";
import { HygraphSidebarComponents } from "../../components/sidebar/HygraphSidebarComponents";

interface Props extends RouteComponentProps {
  getVersicherungenPageData: any;
  setLoading: any;
  versicherungenData: VersicherungenData;
}

const Versicherungen: React.FC<Props> = (props) => {
  const schema = cmsSchemas.insurance.plural;
  const { content: cmsSidebarContent } = useGetSidebarCMSContent({ schema });
  const sidebarBoxes = cmsSidebarContent?.sidebar;

  const [pdfLink, setPdfLink] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [removedYears, setRemovedYears] = useState<number[]>([]);

  const { t: translate } = useTranslation(["creditBalancePage", "app"]);
  const t = (key: string, defaultValue: string) =>
    translate(key, { ns: "creditBalancePage", defaultValue });

  const getInitialData = async () => {
    try {
      props.setLoading(true);
      await props.getVersicherungenPageData();
      props.setLoading(false);
    } catch (error) {
      console.error("error in getting Versicherungen page initial data", error);
    }
  };

  useEffect(() => {
    if (props.versicherungenData.graphBox.futureGraph.length > 0) {
      buildRemovedYears();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.versicherungenData]);

  const buildRemovedYears = () => {
    const { futureGraph } = props.versicherungenData.graphBox;
    const firstDateYear = new Date(futureGraph[0]?.date).getFullYear();
    const lastDateYear = new Date(
      futureGraph[futureGraph.length - 1]?.date
    ).getFullYear();

    if (firstDateYear !== lastDateYear) {
      const foundDatesWithSameInitialYear = futureGraph.filter(
        (item) => new Date(item.date).getFullYear() === firstDateYear
      );

      if (foundDatesWithSameInitialYear.length < 10) {
        setRemovedYears([firstDateYear]);
      }
    }
  };

  useEffect(() => {
    getInitialData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const onDocumentOpen = (link: string) => {
    props.setLoading(true);
    setPdfLink(link);
    setIsModalOpen(true);
  };

  const onDocumentLoad = () => {
    props.setLoading(false);
  };

  const data = props.versicherungenData;
  let lastxAxisElement = 15;

  const handleSidebarCallback = (hashTagLink: string) => {
    if (hashTagLink === hashtagLinksCMSKeys.insuranceCertificate) {
      onDocumentOpen(data.insuranceCertificateBox.orderLink);
    }
  };

  return (
    <div className="versicherungen-page">
      <div className="page-top">
        <Button
          type={ButtonTypes.Text}
          color={ButtonColors.Action}
          onClick={() =>
            props.history.push(getRouteByLanguage(DefaultRouteNames.DASHBOARD))
          }
        >
          <Arrow className="rotate-180" />{" "}
          <span className="ml-16">
            {translate("buttons.backToDashboard", {
              ns: "app",
              defaultValue: "Zurück zum dashboard",
            })}
          </span>
        </Button>
      </div>

      <h1 className="mb-16">{t("title", "Versicherungsübersicht")}</h1>

      <p className="mb-48 max-w-lg-100 max-w-xl-75 max-w-50">
        {t(
          "text",
          "Hier finden Sie alle Angaben zu Ihrer Versicherung. Schnell und übersichtlich."
        )}
      </p>

      <div className="versicherungen-page-content two-column-layout">
        <div className="left">
          <Card className="mb-48 mb-32-tablet">
            <div className="p-48">
              <Top
                className="mb-58"
                title={formatMoney(data.graphBox.value)}
                icon={<SparenIcon />}
                text={t("balanceBox.subTitle", "aktuelles Sparguthaben CHF")}
              />

              {(data.graphBox.futureGraph?.length > 0 ||
                data.graphBox.historicalGraph.length > 0) && (
                <Chart
                  historicalGraph={
                    data.graphBox.futureGraph?.length > 0
                      ? data.graphBox.futureGraph
                      : data.graphBox.historicalGraph
                  }
                  name="top"
                  xAxisFormat={(d, xIndex) => {
                    if (
                      d.getFullYear() !== lastxAxisElement &&
                      !removedYears.includes(d.getFullYear())
                    ) {
                      lastxAxisElement = d.getFullYear();
                      return `${d.getFullYear()}`;
                    } else {
                      return "";
                    }
                  }}
                  yTicksDivision={1000}
                />
              )}

              {data.simulationBox && data.simulationBox.isVisible && (
                <Button
                  type={ButtonTypes.Text}
                  color={ButtonColors.Action}
                  onClick={() => navigateToSimulator(data.simulationBox.link)}
                >
                  {translate("buttons.simulateNow", {
                    ns: "app",
                    defaultValue: "Jetzt simulieren",
                  })}
                  <Arrow className="ml-16" />
                </Button>
              )}
            </div>

            <hr />

            <div className="p-48">
              <h4 className="mb-32">
                {t("personalInfo.title", "Persönliche Daten")}
              </h4>

              <Columns
                className="mb-32"
                width="25%"
                titles={[
                  t("personalInfo.firstname.label", "Vorname"),
                  t("personalInfo.birthdate.label", "Geburtsdatum"),
                  t("personalInfo.admissionDate.label", "Eintrittsdatum"),
                  t("personalInfo.companyName.label", "Firma"),
                ]}
                data={[
                  data.personalInformation.firstname,
                  parseDate(data.personalInformation.birthdate),
                  parseDate(data.personalInformation.admissionDate),
                  data.personalInformation.companyName,
                ]}
              />

              <Columns
                className="mb-32"
                width="25%"
                titles={[
                  t("personalInfo.lastname.label", "Name"),
                  t("personalInfo.civilStatusName.label", "Zivilstand"),
                  t("personalInfo.activityRate.label", "Arbeitspensum"),
                  t("personalInfo.socialSecurityNumber.label", "AHV-Nummer"),
                ]}
                data={[
                  data.personalInformation.lastname,
                  data.personalInformation.civilStatusName,
                  `${data.personalInformation.activityRate}%`,
                  data.personalInformation.socialSecurityNumber,
                ]}
              />

              {data.personalInformation.showTenantPlanInfo && (
                <Columns
                  width="25%"
                  titles={[
                    t("personalInfo.tenantName.label", "Kasse"),
                    t("personalInfo.planName.label", "Plan"),
                  ]}
                  data={[
                    data.personalInformation.tenantName,
                    data.personalInformation.planName,
                  ]}
                />
              )}
            </div>
          </Card>

          <CreditBalanceDetailsBox data={data.creditbalanceDetailsBox} />

          <PayrollAndContributionBox data={data.payrollAndContributionBox} />

          <RegularRetirementBox data={data.regularRetirementBox} />

          <InvalidityAndDeathBenefitBox
            data={data.invalidityAndDeathBenefitBox}
          />
        </div>

        <div className="right">
          <Card>
            {sidebarBoxes && (
              <HygraphSidebarComponents
                boxes={sidebarBoxes}
                data={data}
                callback={handleSidebarCallback}
              />
            )}
          </Card>
        </div>
      </div>
      <Document
        closeModal={closeModal}
        isModalOpen={isModalOpen}
        onDocumentLoad={onDocumentLoad}
        pdfLink={pdfLink}
      ></Document>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  versicherungenData: state.versicherungen.versicherungenData,
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  getVersicherungenPageData: bindActionCreators(
    getVersicherungenPageData,
    dispatch
  ),
  setLoading: (val) => dispatch(setLoading(val)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default withRouter(connector(Versicherungen));
