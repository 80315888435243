import { SET_VERSICHERUNGEN_DATA } from "../constants/actions";
import { api } from "../utils/api";

export type VersicherungenData = {
  title: string;
  text: string;
  graphBox: {
    title: string;
    value: number;
    subTitle: string;
    historicalGraph: {
      date: string;
      value: number;
    }[];
    futureGraph: {
      date: string;
      value: number;
    }[];
  };
  simulationBox: {
    title: string;
    toolTip: string;
    text: string;
    link: string;
    linkText: string;
    isVisible: boolean;
  };
  personalInformation: {
    firstname: string;
    lastname: string;
    tenantName: string;
    planName: string;
    showTenantPlanInfo: boolean;
    civilStatusName: string;
    birthdate: string;
    socialSecurityNumber: string;
    admissionDate: string;
    companyName: string;
    activityRate: number;
  };
  insuranceCertificateBox: {
    title: string;
    toolTip: string;
    downloadLink: string;
    orderLink: string;
    isVisible: boolean;
  };
  knowledgeBox: {
    title: string;
    text: string;
    link: string;
    isVisible: boolean;
  };
  contactBox: {
    title: string;
    text: string;
    link: string;
    linkText: string;
    isVisible: boolean;
  };
  creditbalanceDetailsBox: CreditBalanceDetailsBox;
  payrollAndContributionBox: PayrollAndContributionBox;
  regularRetirementBox: RegularRetirementBox;
  invalidityAndDeathBenefitBox: InvalidityAndDeathBenefitBox;
};

export type CreditBalanceDetailsBox = {
  title: string;
  subTitle: string;
  creditBalanceDetailsList: {
    text: string;
    value: number;
  }[];
  isVisible: boolean;
};

export type PayrollAndContributionBox = {
  title: string;
  payrollDetailsBox: {
    title: string;
    value: number;
    payrollDetailsList: {
      text: string;
      valuePerMonth: number;
      valuePerYear: number;
      helpText?: string;
    }[];
    isVisible: boolean;
  };
  contributionDetailsBox: {
    title: string;
    subTitle: string;
    perMonthLabel: string;
    perYearLabel: string;
    contributionDetailsList: {
      text: string;
      valuePerMonth: number;
      valuePerYear: number;
    }[];
    isVisible: boolean;
  };
  isVisible: boolean;
};

export type InvalidityAndDeathBenefitBox = {
  title: string;
  invalidityBenefitsList: {
    label: string;
    helpText: string;
    value: number;
  }[];
  fatalityBenefitsList: {
    label: string;
    helpText: string;
    value: number;
  }[];
  moreInformationLabel: string;
  benficiaryLinkText: string;
  civilStatusLinkText: string;
  isVisible: boolean;
};

export type RegularRetirementBox = {
  title: string;
  subTitle: string;
  annuityDetailsObject: {
    capitalGroupLabel: string;
    capitalLabel: string;
    capitalHelpText: string;
    conversionRateLabel: string;
    conversionRateHelpText: string;
    capital: number;
    conversionRate: string;
    annuityGroupLabel: string;
    valuePerMonthLabel: string;
    valuePerYearLabel: string;
    annuityLabel: string;
    annuityPerMonth: number;
    annuityPerYear: number;
  };
  isVisible: boolean;
};

export const getVersicherungenPageData = (payload) => {
  return async function (dispatch) {
    try {
      const response: { data: { data: VersicherungenData } } = await api.get(
        "pages/creditbalance"
      );
      dispatch({
        type: SET_VERSICHERUNGEN_DATA,
        payload: response.data.data,
      });
      return response;
    } catch (err) {
      return err;
    }
  };
};
