// import { IDictionary } from '../models';

import { EValidationMessageType } from "../components/ValidationMessage/index";
import i18n from "../locale/i18n";
import { parseDate } from "./react";

// Tests //

export enum EValidationTestType {
  hasValue = "hasValue",
  hasAnyValue = "hasAnyValue",
  acceptAGBS = "acceptAGBS",
  isPasswordMatch = "isPasswordMatch",
  isEmail = "isEmail",
  isPhoneNumber = "isPhoneNumber",
  isPassword = "isPassword",
  isPin = "isPin",
  isSsn = "isSsn",
  isDate = "isDate",
  isFirstDayOfTheMonth = "isFirstDayOfTheMonth",
  isLastDayOfTheMonth = "isLastDayOfTheMonth",
  isBeschaftingungsgrad = "isBeschaftingungsgrad",
  isNumber = "isNumber",
  isPositive = "isPositive",
  isText = "isText",
  isDegree = "isDegree",
  dateBetween = "dateBetween",
  backendValid = "backendValid",
  isDateBeforeCurrentDate = "isDateBeforeCurrentDate",
}

// Test Messages //
export const VALIDATION_MESSAGE = {
  hasValue(messages?) {
    return {
      message: i18n.t("requiredField", "Pflichtfeld darf nicht leer sein", {
        ns: "validation",
      }),
      type: EValidationMessageType.Error,
    };
  },
  hasAnyValue(messages?) {
    return {
      message: i18n.t("requiredField", "Pflichtfeld darf nicht leer sein", {
        ns: "validation",
      }),
      type: EValidationMessageType.Error,
    };
  },
  isPasswordMatch(messages?) {
    return {
      message: i18n.t("passwordNotMatch", "Passwörter stimmen nicht überein", {
        ns: "validation",
      }), // tslint:disable-line
      type: EValidationMessageType.Error,
    };
  },
  isEmail(messages?) {
    return {
      message: i18n.t("invalidEmail", "E-Mail ist ungültig", {
        ns: "validation",
      }),
      type: EValidationMessageType.Error,
    };
  },
  isPhoneNumber(messages?) {
    return {
      message: i18n.t("invalidPhoneNumber", "Telefonnummer ist ungültig", {
        ns: "validation",
      }),
      type: EValidationMessageType.Error,
    };
  },
  isPin(messages?) {
    return {
      message: i18n.t("invalidPin", "PIN ist ungültig", { ns: "validation" }),
      type: EValidationMessageType.Error,
    };
  },
  isPassword(messages?) {
    return {
      message: i18n.t(
        "invalidPasswordLength",
        "Das Passwort muss mindestens 8 Zeichen lang sein und muss mindestens 1 Sonderzeichen enthalten.",
        { ns: "validation" }
      ),
      type: EValidationMessageType.Error,
    };
  },
  isSsn(messages?) {
    return {
      message: i18n.t(
        "somethingWentWrong",
        "Da ist etwas schiefgelaufen. Bitte korrigieren.",
        { ns: "validation" }
      ),
      type: EValidationMessageType.Error,
    };
  },
  isDate(messages?) {
    return {
      message: i18n.t(
        "useCorrectDateFormat",
        "Bitte korrektes Datumsformat “DD.MM.YYYY” verwenden",
        { ns: "validation" }
      ),
      type: EValidationMessageType.Error,
    };
  },
  isFirstDayOfTheMonth(messages?) {
    return {
      message: i18n.t(
        "selectFirstDayOfTheMonth",
        "Bitte wählen Sie den ersten Tag des Monats",
        { ns: "validation" }
      ),
      type: EValidationMessageType.Error,
    };
  },
  isLastDayOfTheMonth(messages?) {
    return {
      message: i18n.t(
        "selectLastDayOfTheMonth",
        "Bitte letzten Tag des Monats auswählen",
        { ns: "validation" }
      ),
      type: EValidationMessageType.Error,
    };
  },
  isBeschaftingungsgrad(messages?) {
    return {
      message: i18n.t(
        "enterDegreeAsPercentage",
        "Beschäftigungsgrad in Prozent eingeben. (0 - 140)",
        { ns: "validation" }
      ),
      type: EValidationMessageType.Error,
    };
  },
  isNumber(messages?) {
    return {
      message: i18n.t("invalidNumber", "Bitte eine Nummer eingeben.", {
        ns: "validation",
      }),
      type: EValidationMessageType.Error,
    };
  },
  isPositive(messages?) {
    return {
      message: i18n.t("numberBetween0and100", "Zahl zwischen 0 und 100.", {
        ns: "validation",
      }),
      type: EValidationMessageType.Error,
    };
  },
  dateBetween(messages?) {
    var message = "";
    if (!messages.from && !messages.until) {
      message = i18n.t("fillOtherFields", "Bitte andere Felder ausfüllen", {
        ns: "validation",
      });
    } else if (messages.from && !messages.until) {
      message = `Das Datum muss sollte nach oder gleich dem ${parseDate(
        messages.from
      )} liegen.`;
    } else {
      message = `Das Datum muss zwischen dem ${parseDate(
        messages.from
      )} und dem ${parseDate(messages.until)} liegen.`;
    }
    return {
      message: message,
      type: EValidationMessageType.Error,
    };
  },
  isDateBeforeCurrentDate(messages?) {
    return {
      message: i18n.t(
        "useDateBeforeCurrentDate",
        "Das Datum muss vor dem aktuellen Datum liegen.",
        { ns: "validation" }
      ),
      type: EValidationMessageType.Error,
    };
  },

  backendValid(messages) {
    return {
      message: messages,
      type: EValidationMessageType.Error,
    };
  },
};
