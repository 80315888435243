import { LOG_OUT, SET_TODESFALL_DATA } from "../constants/actions";
import { Action } from "../actions";
import { TodesfallData } from "../actions/todesfall";

export interface TodesfallState {
  todesfallData: TodesfallData;
}

const getInitialState = () => {
  return {
    todesfallData: {
      title: "Im Todesfall",
      text: "Entscheiden Sie, we im Todesfall als Begünstigte Person eingesetzt wird.",
      fatalityBox: {
        amount: 0,
        subTitle: "Todesfallkapital in CHF",
        isVisible: false,
      },
      beneficariesList: [],
      actionsBox: {
        title: "Wie eird mein Kapital im Todesfall aufgeteilt?",
        text: "Im Gegensatz zu Ihrem übrigen Vermögen fallen Ihre Pensionskassengelder nicht in die Erbamsse. Sie haben unter Berücksichtigung der gesetzlichen Vorschriften die Möglichkeit, die Anteile der Begünstigten selber zu bestimmen.",
        isVisible: false,
        actionList: [],
      },
      simulationBox: {
        title: "Simulation",
        toolTip: "Erklärung zur Simulation als Tooltip",
        text: "Simulieren Sie verschiedene Vorsorgeszenarien und fin den Sie heraus, wie siche dies auf Ihre Finazielle Situation auswirken",
        link: "",
        linkText: "SIMULIEREN STARTEN",
        isVisible: false,
      },
      civilStatusReportBox: {
        title: "Lebenspartnerschaft (Konkubinat melden)",
        text: "Melden Sie eine LEeenspartnerschaft (Konkubinat) an, um im Versicherungsfall gut geschützt zu sein.",
        link: "",
        linkText: "MELDEN",
        isVisible: false,
      },
      reportBeneficiaryOrderBox: {
        title: "Reihenfolge der Begünstigten anpassen",
        text: "Teilen Sie uns mit, we und welche Anteile die Begünstigten haben sollen.",
        link: "",
        linkText: "ANPASSEN",
        isVisible: false,
      },
      contactBox: {
        title: "Kontakt",
        text: "Gerne beraten wir Sie persönlich.",
        link: "",
        linkText: "KONTAKT AUFNEHMEN",
        isVisible: false,
      },
      fatalityPageContentBox: {
        content: "",
      },
    },
  };
};

const app = (state: TodesfallState = getInitialState(), action: Action) => {
  switch (action.type) {
    case SET_TODESFALL_DATA: {
      return { ...state, todesfallData: action.payload };
    }

    case LOG_OUT: {
      console.log("log out");
      return { ...getInitialState() };
    }

    default: {
      return { ...state };
    }
  }
};

export default app;
