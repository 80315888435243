import React from "react";
import styles from "./Steps.module.scss";
import classNames from "classnames";
import { ReactComponent as IconCheckmark } from "../../assets/icons/checkmark-bold-white.svg";

interface Props {
  steps: Array<any>;
  current: number;
}

export default function Steps({ steps, current }: Props) {
  return (
    <div className={styles.steps}>
      {steps.map(({ number, text }) => {
        const isCurrent = current == number;
        const isCompleted = current > number;
        return (
          <div
            key={number}
            className={classNames(styles.step, {
              [styles.current]: isCurrent,
              [styles.completed]: isCompleted,
            })}
          >
            <div className={styles.stepIcon}>
              {isCompleted ? <IconCheckmark /> : number}
            </div>
            <div className={styles.stepText}>{text}</div>
          </div>
        );
      })}
    </div>
  );
}
