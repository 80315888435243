import { palette } from "@icr-ui/theme";

import { ReactComponent as CloseIcon } from "../../assets/icons/close-rounded.svg";
import { StyledWrapper } from "./styles";

interface Props {
  callback: () => unknown;
  closeButtonText?: string;
  text: string;
  type?: "error" | "info" | "success" | "warning";
}

const AlertBar = ({
  callback,
  closeButtonText,
  text,
  type = "info",
}: Props) => {
  const color = {
    error: palette.system.error,
    info: palette.system.info,
    success: palette.system.success,
    warning: palette.system.warning,
  };

  return (
    <StyledWrapper $colorType={color[type]}>
      <p>{text}</p>
      <button onClick={callback}>
        <span>{closeButtonText}</span>
        <CloseIcon />
      </button>
    </StyledWrapper>
  );
};

export default AlertBar;
