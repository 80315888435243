import React from "react";
import classNames from "classnames";

import styles from "./Content.module.scss";

interface Props {
  title: any;
  text: any;
  subText?: any;
  icon: any;
  onIconClick?: any;
  doubleText?: {
    doubleTextTitle: string | number;
    doubleTextSubtitle: string | number;
  };
  className?: any;
}

export function Top({
  title,
  text,
  subText,
  icon,
  onIconClick,
  className,
  doubleText,
}: Props) {
  const classes = classNames(styles.cardTop, className);

  return (
    <div className={classes}>
      <div className={styles.top}>
        <div className={styles.title}>{title}</div>

        {doubleText && (
          <div className={styles.doubleTitleTop}>
            {doubleText.doubleTextTitle}
          </div>
        )}

        <div
          className={styles.icon}
          onClick={onIconClick}
          style={{ cursor: onIconClick ? "pointer" : "inherit" }}
        >
          {icon}
        </div>
      </div>

      <div className={styles.flexWrapper}>
        <div className={styles.text}>{text}</div>

        {doubleText && (
          <>
            <div className={styles.text}>{doubleText.doubleTextSubtitle}</div>
            <div className={styles.finalSpacer}></div>
          </>
        )}
      </div>

      {subText && <div className={styles.text}>{subText}</div>}
    </div>
  );
}
