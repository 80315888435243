import {FC} from "react";
import Tooltip from "rc-tooltip";
import "rc-tooltip/assets/bootstrap.css";

import {ReactComponent as IconHelp} from "../../assets/icons/icon-help.svg";
import styles from "./Hint.module.scss";

interface HintProps {
    text: string;
}

const Hint: FC<HintProps> = ({text}) => (
    <span className={styles.wrapper}>
            <Tooltip
                placement="top"
                trigger={'click'}
                overlay={<div className={styles.overlay}>{text}</div>}
                overlayClassName={'tooltip-wrapper'}
            >
                <IconHelp className={styles.icon} />
            </Tooltip>
        </span>
);

export default Hint;
