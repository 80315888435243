import React, { FunctionComponent } from "react";
import { RootState } from "../../../reducers";
import { Dispatch } from "redux";
import { Action } from "../../../actions";
import { connect } from "react-redux";
import { Button, ButtonColors, ButtonTypes } from "icr-shared-components";
import { useHistory } from "react-router";
import { ButtonStyles } from "icr-shared-components/dist";
import { useTranslation } from "react-i18next";
import { DefaultRouteNames, getRouteByLanguage } from "../../../routes";

interface Props {
  //
}

const Finish: FunctionComponent<Props> = () => {
  const { t: translate } = useTranslation(["register", "app"]);
  const t = (key: string, defaultValue: string) =>
    translate(key, { ns: "register", defaultValue });
  const history = useHistory();

  const next = () => {
    history.push(getRouteByLanguage(DefaultRouteNames.LOGIN));
  };

  return (
    <div className="register-birthday-page send-sms-content">
      <div>
        <h1 className="mb-32">
          {t("registrationOrdered.title", "Registrierungscode bestellt")}
        </h1>

        <p className="mb-48">
          {t(
            "registrationOrdered.description",
            "Vielen Dank für die Bestellung. Sie erhalten den Registrierungscode in den nächsten Tagen per Post zugestellt. Nach Erhalt des Registrierungscodes können Sie die Registrierung abschliessen."
          )}
        </p>
      </div>

      <div className="modal-buttons">
        <Button
          id="registerNextButton"
          className="mr-0 mt-48"
          type={ButtonTypes.Standard}
          style={ButtonStyles.Primary}
          color={ButtonColors.Action}
          onClick={() => next()}
        >
          {translate("buttons.ok", { ns: "app", defaultValue: "OK" })}
        </Button>
      </div>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  //
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  //
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(Finish);
