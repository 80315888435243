import {
  LOG_OUT,
  SET_DOCUMENTS_SUMMARY,
  SET_DOCUMENT_DATA,
  SET_DOCUMENT_LIST,
  SET_OPEN_DOCUMENT,
} from "../constants/actions";
import { Action } from "../actions";
import {
  DocumentList,
  DocumentSummary,
  DocumentData,
} from "../actions/document";

export interface DocumentState {
  documentsSummary: DocumentSummary;
  documentList: DocumentList;
  documentData: DocumentData;
  openDocument: boolean;
}

const getInitialState = () => {
  return {
    documentsSummary: {
      unreadDocumentsAmount: 0,
      unreadDocumentsLabel: "",
      dateLabel: "",
      documentTypeLabel: "",
      documentStateLabel: "",
      orderDocumentButtonText: "",
      isDocumentOrderingEanabled: false,
    },
    documentList: [
      {
        id: "",
        date: "",
        documenttype: "",
        stateLabel: "",
        unread: false,
        pdfLink: "",
      },
    ],
    documentData: {
      isReady: false,
      fileCode: "",
      hasError: false,
    },
    openDocument: false,
  };
};

const document = (state: DocumentState = getInitialState(), action: Action) => {
  switch (action.type) {
    case SET_DOCUMENTS_SUMMARY: {
      return { ...state, documentsSummary: action.payload };
    }
    case SET_DOCUMENT_LIST: {
      return { ...state, documentList: action.payload };
    }
    case SET_DOCUMENT_DATA: {
      return { ...state, documentData: action.payload };
    }
    case SET_OPEN_DOCUMENT: {
      return { ...state, openDocument: action.payload };
    }

    case LOG_OUT: {
      console.log("log out");
      return { ...getInitialState() };
    }

    default: {
      return { ...state };
    }
  }
};

export default document;
