import { SET_URLAUB_DATA } from "../constants/actions";
import { api } from "../utils/api";

export type UrlaublData = {
  title: string;
  text: string;
  unpaidVacationBox: {
    amount: number;
    subTitle: string;
    isVisible: boolean;
  };
  actionsBox: {
    title: string;
    text: string;
    isVisible: boolean;
    actionList: {
      title: string;
      text: string;
    }[];
  };
  optionsBox: {
    isVisible: boolean;
    title: string;
    text: string;
  };
  contactBox: {
    title: string;
    text: string;
    link: string;
    linkText: string;
    isVisible: boolean;
  };
  unpaidVacationPageContentBox: {
    content: string;
  };
};

export const getUrlaubData = (payload) => {
  return async function (dispatch) {
    try {
      const response: { data: { data: UrlaublData } } = await api.get(
        "pages/unpaidvacation"
      );
      dispatch({
        type: SET_URLAUB_DATA,
        payload: response.data.data,
      });
      return response;
    } catch (err) {
      return err;
    }
  };
};
