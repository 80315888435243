import {CSSProperties, useCallback, useEffect, useState} from "react";
import {layout, svg, select, interpolate} from "d3";
import "./DonutChart.scss";
import styles from "../Chart.module.scss";
import {formatMoney} from "../../../utils/currency";
import useMousePosition from "../../../utils/useMousePosition";
import { useTranslation } from "react-i18next";

interface DonutChartProps {
    data: {
        ratio: number;
        total: number;
        items: {
            title: string;
            subtitle: string;
            label: string;
            value: number;
        }[];
    };
    size: number;
    wrapperStyles?: CSSProperties;
    backgroundStyles?: CSSProperties;
}
export default function DonutChart({
    data,
    size,
    wrapperStyles,
    backgroundStyles,
}: DonutChartProps) {
    const { t: translate } = useTranslation(["common"]);
    const t = (key: string, defaultValue: string) =>
      translate(key, { defaultValue });

    const [tooltip, setTooltip] = useState({
        title: "",
        subtitle: "",
        value: "",
        top: null,
        left: null,
    });

    const duration = 1500,
        //transition = 200,
        width = size,
        height = size;

    const drawChart = useCallback(() => {
        const percent = data.ratio * 100;
        const dataset = {
                lower: calcPercent(0),
                upper: calcPercent(percent),
            },
            radius = Math.min(width, height) / 3,
            pie = layout.pie().sort(null),
            pie2 = layout
                .pie()
                .value(function (d) {
                    return d._length;
                })
                .sort(null);
        //format = d3.format(".0%");

        const svg1 = select("#donut-main-svg")
            .attr("width", width)
            .attr("height", height)
            .select("#donut-main-svg-g")
            .attr(
                "transform",
                "translate(" + width / 2 + "," + height / 2 + ")"
            );
        const svg2 = select("#donut-main-svg")
            .attr("width", width)
            .attr("height", height)
            .select("#donut-main-svg-g2")
            .attr(
                "transform",
                "translate(" + width / 2 + "," + height / 2 + ")"
            );

        const arc = svg
            .arc()
            .cornerRadius(100)
            //.padAngle(0.05)
            .innerRadius(radius * 0.8)
            .outerRadius(radius);

        let path = svg1
            .selectAll("path")
            .data(pie(dataset.lower))
            .enter()
            .append("path")
            .attr("class", function (d, i) {
                return "color" + i;
            })
            .attr("d", arc)
            .each(function (this, d) {
                this._current = d;
            });
        let points = data.items.map((i, index) => {
            if (index === 0) {
                return {...i, _length: (i.value / data.total) * 100};
            } else {
                return {
                    ...i,
                    _length:
                        (i.value / data.total) * 100 -
                        (data.items[index - 1].value / data.total) * 100,
                };
            }
        });

        points = [
            ...points,
            {
                title: "",
                subtitle: "",
                label: "",
                value: 0,
                _length:
                    100 -
                    (data.items[data.items.length - 1].value / data.total) *
                        100,
            },
        ];

        svg2.selectAll("path")
            .data(pie2(points))
            .enter()
            .append("path")
            .attr("d", arc)
            .attr("style", "opacity:0;")
            .attr("id", function (d, i) {
                return "value" + i;
            })
            .each(function (this, d) {
                this._current = d;
            })
            .on("mouseenter", function (this, d, i) {
                d.active = true;
                if (i !== data.items.length) {
                    setTooltip((prev) => ({
                        ...prev,
                        title: d.data.title,
                        subtitle: d.data.subtitle,
                        value: d.data.label,
                    }));
                }
            })
            .on("mouseout", function (this, d) {
                setTooltip({
                    title: "",
                    subtitle: "",
                    value: "",
                    top: null,
                    left: null,
                });
            });

        const svgDefs = svg1.append("defs");
        const mainGradient = svgDefs
            .append("linearGradient")
            .attr("id", "donutGradient");

        mainGradient
            .append("stop")
            .attr("class", "stop-left-chart")
            .attr("offset", "0");

        mainGradient
            .append("stop")
            .attr("class", "stop-right")
            .attr("offset", "1");

        // const text = svg
        //     .append("text")
        //     .attr("text-anchor", "middle")
        //     .attr("dy", ".3em");

        //const progress = 0;

        const timeout = setTimeout(function () {
            clearTimeout(timeout);
            path = path.data(pie(dataset.upper));
            path.transition()
                .duration(duration)
                .attrTween("d", function (this, a) {
                    const i = interpolate(this._current, a);
                    //const i2 = interpolate(progress, percent);
                    this._current = i(0);
                    return function (t) {
                        //text.text(format(i2(t) / 100));
                        return arc(i(t));
                    };
                });
        }, 200);
    }, [data, calcPercent]);

    function calcPercent(percent) {
        return [percent, 100 - percent];
    }

    useEffect(() => {
        if (data.total > 0) {
            drawChart();
        }
    }, [data, drawChart]);

    const mousePosition = useMousePosition({recordPosition: !!tooltip.value});

    return (
        <div
            className="donut-chart-wrapper"
            style={{
                position: "relative",
                width: 300,
                height: 300,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
            }}
        >
            {tooltip.value && (
                <div
                    className={styles.tooltip}
                    style={{
                        top: mousePosition.y - 102,
                        left: mousePosition.x - 55,
                        zIndex: 3,
                        position: "fixed",
                    }}
                >
                    <div
                        className={styles.rect}
                        style={{marginLeft: "3.3rem", marginTop: "0.5rem"}}
                    />
                    <div className={styles.tooltipDate}>{tooltip.title}</div>
                    <div className={styles.tooltipDate}>{tooltip.subtitle}</div>
                    <div className={styles.tooltipAmount}>{tooltip.value}</div>
                </div>
            )}
            <div className="donut-main" style={wrapperStyles}>
                <svg id="donut-main-svg">
                    <g id="donut-main-svg-g"></g>
                    <g id="donut-main-svg-g2"></g>
                </svg>
            </div>
            <div className="background-donut-chart" style={backgroundStyles}>
                <div className="middle-text">
                    {t("donutChart.label", "Maximal mögliches Sparguthaben in CHF")}
                </div>
                <div className="middle-number">{formatMoney(data.total)}</div>
            </div>
        </div>
    );
}
