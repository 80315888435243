import React, { Fragment, useState } from "react";
import {
  Button,
  ButtonColors,
  ButtonStyles,
  ButtonTypes,
  Input,
} from "icr-shared-components/dist";
import { useHistory } from "react-router";
import { RootState } from "../../reducers";
import { bindActionCreators, Dispatch } from "redux";
import { Action } from "../../actions";
import {
  updateProfileLoginData,
  mobileFoneConfirmation,
} from "../../actions/profile";
import { connect } from "react-redux";
import { formConfig } from "./formConfig";
import { useValidateFrom } from "../../utils/useValidateFrom";
import { getRouteByLanguage, DefaultRouteNames } from "../../routes";
import { useTranslation } from "react-i18next";

const PhoneVerification = ({
  confirmationToken,
  mobileFoneConfirmation,
  newPhoneNumber,
}) => {
  const [smsCode, setSmsCode] = useState("");
  const [isBlurred, setIsBlurred] = useState(false);
  const { t: translate } = useTranslation(["profile", "common"]);
  const t = (key: string, defaultValue: string) =>
    translate(key, { ns: "profile", defaultValue });

  const history = useHistory();
  const validation = useValidateFrom("profile", formConfig, { smsCode });

  const handleSubmit = async (e) => {
    const payload = {
      token: confirmationToken,
      code: smsCode,
    };

    await mobileFoneConfirmation(payload);
    history.push(getRouteByLanguage(DefaultRouteNames.PROFILE));
  };

  return (
    <Fragment>
      <div className="profile-modal-text">
        {t(
          "phoneVerification.title",
          "Sie erhalten einen Bestätigungscode per SMS an die neue Mobiltelefonnumer"
        )}{" "}
        +{newPhoneNumber.substring(0, 7)}** ** **
        {newPhoneNumber.slice(-2)} <br />
        <br />
        {t(
          "phoneVerification.subTitle",
          "Um den Wechsel Ihrer Mobiltelefonnummer abzuschliessen, geben Sie bitte den Bestätigungscode hier ein."
        )}
      </div>
      <div className="row">
        <div className="col-md-5 col-xs-12">
          <Input
            type="text"
            name="email"
            value={smsCode}
            onChange={(name, value) => setSmsCode(value)}
            placeholder={t("phoneVerification.smsCode", "SMS Code")}
            inputWrapperClass="mb-48"
            onBlur={() => setIsBlurred(true)}
            validationMessageConfig={
              (isBlurred ||
                validation?.smsCode?.validation?.type === "success") && {
                config: validation.smsCode.validation,
                visible: true,
              }
            }
          >
            {t("phoneVerification.smsCode", "SMS Code")}
          </Input>
        </div>
      </div>

      <div className="modal-buttons">
        <Button
          type={ButtonTypes.Text}
          color={ButtonColors.Action}
          onClick={() =>
            history.push(getRouteByLanguage(DefaultRouteNames.PROFILE))
          }
        >
          {t("phoneVerification.goBackButton", "abbrechen")}
        </Button>

        <Button
          isDisabled={validation?.smsCode?.validation?.type !== "success"}
          type={ButtonTypes.Standard}
          style={ButtonStyles.Primary}
          color={ButtonColors.Action}
          onClick={handleSubmit}
        >
          {t("phoneVerification.confirmButton", "bestätigen")}
        </Button>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state: RootState) => ({
  confirmationToken: state.profile.confirmationToken,
  newPhoneNumber: state.profile.newPhoneNumber,
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  updateProfileLoginData: bindActionCreators(updateProfileLoginData, dispatch),
  mobileFoneConfirmation: bindActionCreators(mobileFoneConfirmation, dispatch),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(PhoneVerification);
