import { StyledCircle, StyledSVG, StyledSVGWrapper } from "./styles";

export const CircularLoader = () => {
  return (
    <StyledSVGWrapper>
      <StyledCircle />
      <StyledSVG xmlns="http://www.w3.org/2000/svg" version="1.1">
        <defs>
          <linearGradient id="GradientColor">
            <stop offset="0%" stop-color="#6BB5CE" />
            <stop offset="100%" stop-color="#B0D7E5" />
          </linearGradient>
        </defs>
        <circle cx="36" cy="36" r="32" stroke-linecap="round" />
      </StyledSVG>
    </StyledSVGWrapper>
  );
};
