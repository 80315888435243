import { i18n } from "./locale";
import { getTenantFromUrl } from "./utils";

export enum DefaultRouteNames {
  DASHBOARD = "DASHBOARD",
  FORBIDDEN = "FORBIDDEN",
  LOGIN = "LOGIN",
  LOGINLINK = "LOGINLINK",
  LOGINSMS = "LOGINSMS",
  LOGINREMINDER = "LOGINREMINDER",
  REGISTER = "REGISTER",
  REGISTERSTART = "REGISTERSTART",
  REGISTERPHONE = "REGISTERPHONE",
  REGISTERCODE = "REGISTERCODE",
  REGISTERAHV = "REGISTERAHV",
  REGISTERBIRTHDAY = "REGISTERBIRTHDAY",
  REGISTERNAME = "REGISTERNAME",
  REGISTERSMS = "REGISTERSMS",
  REGISTERMESSAGE = "REGISTERMESSAGE",
  REGISTERSEND = "REGISTERSEND",
  REGISTERFINISH = "REGISTERFINISH",
  PROFILE = "PROFILE",
  PROFILECIVILSTATUS = "PROFILECIVILSTATUS",
  PROFILEEMAIL = "PROFILEEMAIL",
  PROFILEPHONE = "PROFILEPHONE",
  PROFILEADDRESS = "PROFILEADDRESS",
  PROFILEPHONEVERIFICATION = "PROFILEPHONEVERIFICATION",
  PROFILEEMAILVERIFICATION = "PROFILEEMAILVERIFICATION",
  PROFILERECOVERY = "PROFILERECOVERY",
  TERMS = "TERMS",
  INSURANCE = "INSURANCE",
  RETIREMENT = "RETIREMENT",
  HOMEOWNERSHIP = "HOMEOWNERSHIP",
  ADDITIONALSAVINGS = "ADDITIONALSAVINGS",
  ADDITIONALSAVINGSAPPLY = "ADDITIONALSAVINGSAPPLY",
  ADDITIONALSAVINGSCUSTOMIZE = "ADDITIONALSAVINGSCUSTOMIZE",
  ADDITIONALSAVINGSCONFIRMATION = "ADDITIONALSAVINGSCONFIRMATION",
  BENEFITS = "BENEFITS",
  CIVILSTATUS = "CIVILSTATUS",
  DEATH = "DEATH",
  EXIT = "EXIT",
  DOCUMENTS = "DOCUMENTS",
  CONTACT = "CONTACT",
  VACATION = "VACATION",
  QUESTIONS = "QUESTIONS",
}

export enum RouteURLsByLanguage {
  // English
  "DASHBOARD-en-US" = "/dashboard",
  "FORBIDDEN-en-US" = "/forbidden",
  "LOGIN-en-US" = "/login",
  "LOGINLINK-en-US" = "/login/link",
  "LOGINSMS-en-US" = "/login/sms",
  "LOGINREMINDER-en-US" = "/login/reminder",
  "REGISTER-en-US" = "/register",
  "REGISTERSTART-en-US" = "/register/start",
  "REGISTERPHONE-en-US" = "/register/phone",
  "REGISTERCODE-en-US" = "/register/code",
  "REGISTERAHV-en-US" = "/register/ahv",
  "REGISTERBIRTHDAY-en-US" = "/register/birthday",
  "REGISTERNAME-en-US" = "/register/name",
  "REGISTERSMS-en-US" = "/register/sms",
  "REGISTERMESSAGE-en-US" = "/register/message",
  "REGISTERSEND-en-US" = "/register/send",
  "REGISTERFINISH-en-US" = "/register/finish",
  "PROFILE-en-US" = "/profile",
  "PROFILECIVILSTATUS-en-US" = "/profile/civil-status",
  "PROFILEEMAIL-en-US" = "/profile/email",
  "PROFILEPHONE-en-US" = "/profile/phone",
  "PROFILEADDRESS-en-US" = "/profile/address",
  "PROFILEPHONEVERIFICATION-en-US" = "/profile/phone-verification",
  "PROFILEEMAILVERIFICATION-en-US" = "/profile/email-verification",
  "PROFILERECOVERY-en-US" = "/profile/recovery",
  "TERMS-en-US" = "/terms",
  "INSURANCE-en-US" = "/insurance", // versicherungen
  "RETIREMENT-en-US" = "/retirement", // pensionierung
  "HOMEOWNERSHIP-en-US" = "/homeownership", // wohneigentum
  "ADDITIONALSAVINGS-en-US" = "/additionalsavings", // zusatzsparen
  "ADDITIONALSAVINGSAPPLY-en-US" = "/additionalsavings/apply",
  "ADDITIONALSAVINGSCUSTOMIZE-en-US" = "/additionalsavings/customize",
  "ADDITIONALSAVINGSCONFIRMATION-en-US" = "/additionalsavings/confirmation",
  "BENEFITS-en-US" = "/benefits", // altersleistung
  "CIVILSTATUS-en-US" = "/civilstatus", // zivilstand
  "DEATH-en-US" = "/death", // todesfall
  "EXIT-en-US" = "/exit", // austritt
  "DOCUMENTS-en-US" = "/documents", // dokumente
  "CONTACT-en-US" = "/contact", // kontakt
  "VACATION-en-US" = "/vacation", // urlaub
  "QUESTIONS-en-US" = "/questions", // fragen
  // German
  "DASHBOARD-de-CH" = "/dashboard",
  "FORBIDDEN-de-CH" = "/forbidden",
  "LOGIN-de-CH" = "/login",
  "LOGINLINK-de-CH" = "/login/link",
  "LOGINSMS-de-CH" = "/login/sms",
  "LOGINREMINDER-de-CH" = "/login/reminder",
  "REGISTER-de-CH" = "/anmelden",
  "REGISTERSTART-de-CH" = "/anmelden/start",
  "REGISTERPHONE-de-CH" = "/anmelden/telefon",
  "REGISTERCODE-de-CH" = "/anmelden/code",
  "REGISTERAHV-de-CH" = "/anmelden/ahv",
  "REGISTERBIRTHDAY-de-CH" = "/anmelden/geburtstag",
  "REGISTERNAME-de-CH" = "/anmelden/name",
  "REGISTERSMS-de-CH" = "/anmelden/sms",
  "REGISTERMESSAGE-de-CH" = "/anmelden/nachricht",
  "REGISTERSEND-de-CH" = "/anmelden/send",
  "REGISTERFINISH-de-CH" = "/anmelden/fertig",
  "PROFILE-de-CH" = "/profil",
  "PROFILECIVILSTATUS-de-CH" = "/profil/zivilstand",
  "PROFILEEMAIL-de-CH" = "/profil/email",
  "PROFILEPHONE-de-CH" = "/profil/telefon",
  "PROFILEADDRESS-de-CH" = "/profil/adresse",
  "PROFILEPHONEVERIFICATION-de-CH" = "/profil/telefon-verification",
  "PROFILEEMAILVERIFICATION-de-CH" = "/profil/email-verification",
  "PROFILERECOVERY-de-CH" = "/profil/wiederherstellung",
  "TERMS-de-CH" = "/terms",
  "INSURANCE-de-CH" = "/versicherungen",
  "RETIREMENT-de-CH" = "/pensionierung",
  "HOMEOWNERSHIP-de-CH" = "/wohneigentum",
  "ADDITIONALSAVINGS-de-CH" = "/planwahl",
  "ADDITIONALSAVINGSAPPLY-de-CH" = "/planwahl/bewerben",
  "ADDITIONALSAVINGSCUSTOMIZE-de-CH" = "/planwahl/anpassen",
  "ADDITIONALSAVINGSCONFIRMATION-de-CH" = "/planwahl/bestaetigung",
  "BENEFITS-de-CH" = "/altersleistung",
  "CIVILSTATUS-de-CH" = "/zivilstand",
  "DEATH-de-CH" = "/todesfall",
  "EXIT-de-CH" = "/austritt",
  "DOCUMENTS-de-CH" = "/dokumente",
  "CONTACT-de-CH" = "/kontakt",
  "VACATION-de-CH" = "/urlaub",
  "QUESTIONS-de-CH" = "/fragen",
  // French
  "DASHBOARD-fr-FR" = "/tableaudebord",
  "FORBIDDEN-fr-FR" = "/forbidden",
  "LOGIN-fr-FR" = "/login",
  "LOGINLINK-fr-FR" = "/login/link",
  "LOGINSMS-fr-FR" = "/login/sms",
  "LOGINREMINDER-fr-FR" = "/login/reminder",
  "REGISTER-fr-FR" = "/inscription",
  "REGISTERSTART-fr-FR" = "/inscription/debut",
  "REGISTERPHONE-fr-FR" = "/inscription/telephone",
  "REGISTERCODE-fr-FR" = "/inscription/code",
  "REGISTERAHV-fr-FR" = "/inscription/ahv",
  "REGISTERBIRTHDAY-fr-FR" = "/inscription/anniversaire",
  "REGISTERNAME-fr-FR" = "/inscription/nom",
  "REGISTERSMS-fr-FR" = "/inscription/sms",
  "REGISTERMESSAGE-fr-FR" = "/inscription/message",
  "REGISTERSEND-fr-FR" = "/inscription/envoyer",
  "REGISTERFINISH-fr-FR" = "/inscription/terminer",
  "PROFILE-fr-FR" = "/profil",
  "PROFILECIVILSTATUS-fr-FR" = "/profil/statut-civil",
  "PROFILEEMAIL-fr-FR" = "/profil/email",
  "PROFILEPHONE-fr-FR" = "/profil/telephone",
  "PROFILEADDRESS-fr-FR" = "/profil/adresse",
  "PROFILEPHONEVERIFICATION-fr-FR" = "/profil/verification-telephone",
  "PROFILEEMAILVERIFICATION-fr-FR" = "/profil/verification-email",
  "PROFILERECOVERY-fr-FR" = "/profil/recuperation",
  "TERMS-fr-FR" = "/termes",
  "INSURANCE-fr-FR" = "/assurance",
  "RETIREMENT-fr-FR" = "/retraite",
  "HOMEOWNERSHIP-fr-FR" = "/financerpropriete",
  "ADDITIONALSAVINGS-fr-FR" = "/selectionplan",
  "ADDITIONALSAVINGSAPPLY-fr-FR" = "/selectionplan/postuler",
  "ADDITIONALSAVINGSCUSTOMIZE-fr-FR" = "/selectionplan/personnaliser",
  "ADDITIONALSAVINGSCONFIRMATION-fr-FR" = "/selectionplan/confirmation",
  "BENEFITS-fr-FR" = "/prestationretraite",
  "CIVILSTATUS-fr-FR" = "/etatcivil",
  "DEATH-fr-FR" = "/deces",
  "EXIT-fr-FR" = "/sortie",
  "DOCUMENTS-fr-FR" = "/documents",
  "CONTACT-fr-FR" = "/contact",
  "VACATION-fr-FR" = "/congenonpaye",
  "QUESTIONS-fr-FR" = "/questions",
  // portuguese
  "DASHBOARD-pt-BR" = "/painel",
  "FORBIDDEN-pt-BR" = "/forbidden",
  "LOGIN-pt-BR" = "/login",
  "LOGINLINK-pt-BR" = "/login/link",
  "LOGINSMS-pt-BR" = "/login/sms",
  "LOGINREMINDER-pt-BR" = "/login/lembrete",
  "REGISTER-pt-BR" = "/registro",
  "REGISTERSTART-pt-BR" = "/registro/inicio",
  "REGISTERPHONE-pt-BR" = "/registro/telefone",
  "REGISTERCODE-pt-BR" = "/registro/codigo",
  "REGISTERAHV-pt-BR" = "/registro/ahv",
  "REGISTERBIRTHDAY-pt-BR" = "/registro/aniversario",
  "REGISTERNAME-pt-BR" = "/registro/nome",
  "REGISTERSMS-pt-BR" = "/registro/sms",
  "REGISTERMESSAGE-pt-BR" = "/registro/mensagem",
  "REGISTERSEND-pt-BR" = "/registro/enviar",
  "REGISTERFINISH-pt-BR" = "/registro/finalizar",
  "PROFILE-pt-BR" = "/perfil",
  "PROFILECIVILSTATUS-pt-BR" = "/perfil/estado-civil",
  "PROFILEEMAIL-pt-BR" = "/perfil/email",
  "PROFILEPHONE-pt-BR" = "/perfil/telefone",
  "PROFILEADDRESS-pt-BR" = "/perfil/endereco",
  "PROFILEPHONEVERIFICATION-pt-BR" = "/perfil/verificacao-telefone",
  "PROFILEEMAILVERIFICATION-pt-BR" = "/perfil/verificacao-email",
  "PROFILERECOVERY-pt-BR" = "/perfil/recuperacao",
  "TERMS-pt-BR" = "/termos",
  "INSURANCE-pt-BR" = "/seguro",
  "RETIREMENT-pt-BR" = "/aposentadoria",
  "HOMEOWNERSHIP-pt-BR" = "/propriedade",
  "ADDITIONALSAVINGS-pt-BR" = "/economias-adicionais",
  "ADDITIONALSAVINGSAPPLY-pt-BR" = "/economias-adicionais/aplicar",
  "ADDITIONALSAVINGSCUSTOMIZE-pt-BR" = "/economias-adicionais/personalizar",
  "ADDITIONALSAVINGSCONFIRMATION-pt-BR" = "/economias-adicionais/confirmacao",
  "BENEFITS-pt-BR" = "/beneficios",
  "CIVILSTATUS-pt-BR" = "/estado-civil",
  "DEATH-pt-BR" = "/obito",
  "EXIT-pt-BR" = "/saida",
  "DOCUMENTS-pt-BR" = "/documentos",
  "CONTACT-pt-BR" = "/contato",
  "VACATION-pt-BR" = "/feriasnaopagas",
  "QUESTIONS-pt-BR" = "/perguntas",
  // italian
  "DASHBOARD-it-IT" = "/dashboard",
  "FORBIDDEN-it-IT" = "/forbidden",
  "LOGIN-it-IT" = "/login",
  "LOGINLINK-it-IT" = "/login/link",
  "LOGINSMS-it-IT" = "/login/sms",
  "LOGINREMINDER-it-IT" = "/login/promemoria",
  "REGISTER-it-IT" = "/registrazione",
  "REGISTERSTART-it-IT" = "/registrazione/inizio",
  "REGISTERPHONE-it-IT" = "/registrazione/telefono",
  "REGISTERCODE-it-IT" = "/registrazione/codice",
  "REGISTERAHV-it-IT" = "/registrazione/ahv",
  "REGISTERBIRTHDAY-it-IT" = "/registrazione/data-di-nascita",
  "REGISTERNAME-it-IT" = "/registrazione/nome",
  "REGISTERSMS-it-IT" = "/registrazione/sms",
  "REGISTERMESSAGE-it-IT" = "/registrazione/messaggio",
  "REGISTERSEND-it-IT" = "/registrazione/invia",
  "REGISTERFINISH-it-IT" = "/registrazione/fine",
  "PROFILE-it-IT" = "/profilo",
  "PROFILECIVILSTATUS-it-IT" = "/profilo/stato-civile",
  "PROFILEEMAIL-it-IT" = "/profilo/email",
  "PROFILEPHONE-it-IT" = "/profilo/telefono",
  "PROFILEADDRESS-it-IT" = "/profilo/indirizzo",
  "PROFILEPHONEVERIFICATION-it-IT" = "/profilo/verifica-telefono",
  "PROFILEEMAILVERIFICATION-it-IT" = "/profilo/verifica-email",
  "PROFILERECOVERY-it-IT" = "/profilo/recupero",
  "TERMS-it-IT" = "/termini",
  "INSURANCE-it-IT" = "/assicurazione",
  "RETIREMENT-it-IT" = "/pensione",
  "HOMEOWNERSHIP-it-IT" = "/possedere-casa",
  "ADDITIONALSAVINGS-it-IT" = "/risparmi-addizionali",
  "ADDITIONALSAVINGSAPPLY-it-IT" = "/risparmi-addizionali/applica",
  "ADDITIONALSAVINGSCUSTOMIZE-it-IT" = "/risparmi-addizionali/personalizza",
  "ADDITIONALSAVINGSCONFIRMATION-it-IT" = "/risparmi-addizionali/conferma",
  "BENEFITS-it-IT" = "/benefici-pensione",
  "CIVILSTATUS-it-IT" = "/stato-civile",
  "DEATH-it-IT" = "/morte",
  "EXIT-it-IT" = "/uscita",
  "DOCUMENTS-it-IT" = "/documenti",
  "CONTACT-it-IT" = "/contatto",
  "VACATION-it-IT" = "/ferie-non-retribuite",
  "QUESTIONS-it-IT" = "/domande",
}

export const getRouteByLanguage = (routeName: string): string => {
  return `${getTenantFromUrl()}/${i18n.language}${
    RouteURLsByLanguage[`${routeName}-${i18n.language}`]
  }`;
};

export const getRouteByValue = (value: string): string => {
  return Object.keys(RouteURLsByLanguage)
    .find((key) => RouteURLsByLanguage[key] === value)
    .split("-")[0];
};
