import React, { FC } from "react";
import classNames from "classnames";

import styles from "./Content.module.scss";

interface Props {
  title: any;
  subtitle: any;
  text: any;
  icon: any;
  onClick: any;
  doubleText?: { doubleTextTitle: string | number; doubleTextSubtitle: string | number };
  className?: any;
  children?: any;
  isBlurred?: boolean;
}

export const Dashboard: FC<Props> = ({
  title,
  subtitle,
  text,
  icon,
  onClick,
  doubleText,
  className,
  children,
  isBlurred,
}) => (
  <div
    className={classNames(styles.dashboard, className, {
      [styles.blurred]: isBlurred,
    })}
    onClick={onClick}
  >
    <div className={styles.top}>
      <div className={styles.subtitle}>{subtitle}</div>
      <div className={styles.icon}>{icon}</div>
    </div>

    {doubleText ? (
      <div className={styles.flexWrapper}>
        <div className={styles.flexColumn}>
          <div className={styles.title}>{title}</div>
          <div className={styles.text}>{text}</div>
        </div>
        <div className={classNames(styles.flexColumn, "ml-5")}>
          <div className={styles.doubleTitle}>{doubleText.doubleTextTitle}</div>
          <div className={styles.text}>{doubleText.doubleTextSubtitle}</div>
        </div>
      </div>
    ) : (
      <>
        <div className={styles.title}>{title}</div>
        <div className={styles.text}>{text}</div>
      </>
    )}
    <div className={styles.content}>{children}</div>
  </div>
);
