import { useCallback, useEffect, useRef, useState } from "react";
import { scale, svg, select, max } from "d3";
import "./BarChart.scss";
import styles from "../Chart.module.scss";
import { formatMoney } from "../../../utils/currency";
import { parseDate } from "../../../utils/react";

interface BarChartProps {
  data: { age: number; date: string; value: number; year: number }[];
  hideTicks?: boolean;
  defaultRetirementAge?: number;
}

export default function BarChart({
  data,
  hideTicks,
  defaultRetirementAge,
}: BarChartProps) {
  const [wrapperWidth, setWrapperWidth] = useState(0);
  const [tooltip, setTooltip] = useState({
    date: "",
    value: "",
    top: null,
    left: null,
  });

  const ref: any = useRef();

  useEffect(() => {
    const handleResize = () => {
      if (ref.current.offsetWidth !== wrapperWidth) {
        setWrapperWidth(ref.current.offsetWidth);
      }
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [setWrapperWidth, wrapperWidth]);

  useEffect(() => {
    if (ref.current) {
      setWrapperWidth(ref.current.offsetWidth);
    }
  }, [setWrapperWidth]);

  const drawChart = useCallback(() => {
    const margin = { top: 20, right: 10, bottom: 30, left: 10 },
      width = wrapperWidth - margin.left - margin.right,
      height = 200 - margin.top - margin.bottom;

    const x0 = scale.ordinal().rangeRoundBands([0, width], 0.1);

    const x1 = scale.ordinal();

    const y = scale.linear().range([height, 0]);

    const xAxis = svg.axis().scale(x0).tickSize(0).orient("bottom");

    const barSvg = select("#bar-chart-main")
      .append("svg")
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom)
      .append("g")
      .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

    const agesNames = data.map(function (d) {
      return d.age;
    });
    const rateNames = ["", ""];

    x0.domain(agesNames);
    x1.domain(rateNames).rangeRoundBands([0, x0.rangeBand()]);
    y.domain([
      0,
      max(data, function (age) {
        return age.value;
      }),
    ]);

    barSvg
      .append("g")
      .attr("class", "x-axis-bar-chart")
      .attr("transform", "translate(0," + (height + 10) + ")")
      .call(xAxis);

    const slice = barSvg
      .append("svg")
      .attr("id", "bars")
      .attr("height", height + margin.top + margin.bottom - 55)
      .selectAll(".slice")
      .data(data)
      .enter()
      .append("g")
      .attr("class", "g")
      .attr("transform", function (d) {
        return "translate(" + x0(d.age) + ",0)";
      })
      .append("rect")
      .attr("width", x1.rangeBand())
      .attr("class", "color-bar-0")
      .attr("id", function (d) {
        return "bar-" + d.age;
      })
      .attr("rx", "5")
      .attr("ry", "5")
      .attr("x", function (d, i) {
        if (hideTicks) {
          if (i === 0 || i === data.length - 1) {
            selectAxisLabel(d).attr(
              "style",
              "font-weight: 400;text-anchor: middle;"
            );
          } else {
            selectAxisLabel(d).attr("style", "opacity:0;");
          }
        }
        return x1(d.rate);
      })
      .attr("y", function (d) {
        return y(0);
      })
      .attr("height", function (d) {
        return height - y(0);
      })
      .on("mouseover", function (this, d) {
        const lastHighlitedBar =
          document.getElementsByClassName("color-bar-1")[0];

        if (lastHighlitedBar) {
          lastHighlitedBar.classList.add("color-bar-0");
          lastHighlitedBar.classList.remove("color-bar-1");
        }

        select(this).attr("class", "color-bar-1");
        setTooltip({
          top: y(d.value) / 2 + 60,
          left: x0(d.age) - 84,
          date: parseDate(d.date),
          value: `CHF ${formatMoney(d.value)}`,
        });
        selectAxisLabel(d).attr(
          "style",
          `${
            hideTicks ? "opacity:1;transition:opacity 0.3s;" : ""
          }font-weight: 600;text-anchor: middle;fill:#242F38 !important`
        );
      })
      .on("mouseout", function (this, d, i) {
        select(this).attr("class", "color-bar-0");
        setTooltip({
          top: 0,
          left: 0,
          date: "",
          value: "",
        });
        selectAxisLabel(d).attr(
          "style",
          `${
            i === 0 || i === data.length - 1 || !hideTicks
              ? ""
              : "opacity:0;transition:opacity 0.3s;"
          }font-weight: 400;text-anchor: middle;`
        );
      });

    slice
      .transition()
      .delay(function (d) {
        return Math.random() * 1000;
      })
      .duration(1000)
      .attr("y", function (d) {
        return y(d.value);
      })
      .attr("height", function (d) {
        return height - y(d.value);
      });

    const svgDefs = barSvg.append("defs");

    const mainGradient = svgDefs
      .append("linearGradient")
      .attr("id", "barGradientActive")
      .attr("x2", "0%")
      .attr("y2", "100%");
    mainGradient
      .append("stop")
      .attr("class", "stop-left-bar-chart")
      .attr("offset", "0");
    mainGradient
      .append("stop")
      .attr("class", "stop-right-bar-chart")
      .attr("offset", "1");

    const deActiveGradient = svgDefs
      .append("linearGradient")
      .attr("id", "barGradientDeActive")
      .attr("x2", "0%")
      .attr("y2", "100%");
    deActiveGradient
      .append("stop")
      .attr("class", "stop-left-bar-chart-gray")
      .attr("offset", "0");
    deActiveGradient
      .append("stop")
      .attr("class", "stop-right-bar-chart-gray")
      .attr("offset", "1");

    function selectAxisLabel(datum) {
      return select(".x-axis-bar-chart")
        .selectAll("text")
        .filter(function (x) {
          return x === datum.age;
        });
    }

    activateDefaultRetirementAgeTooltip(y, x0, selectAxisLabel);
    const parentSVG = document.getElementById("bar-chart-main");

    if (parentSVG) {
      // enable auto show last circle
      parentSVG.onmouseleave = function () {
        activateDefaultRetirementAgeTooltip(y, x0, selectAxisLabel);
      };
    }
  }, [wrapperWidth, data, setTooltip, hideTicks]);

  function activateDefaultRetirementAgeTooltip(y, x0, selectAxisLabel) {
    select(
      document.getElementById(
        `bar-${defaultRetirementAge > 0 ? defaultRetirementAge : 65}`
      )
    ).attr("class", "color-bar-1");

    const d = data.find(
      (d) => d.age === (defaultRetirementAge > 0 ? defaultRetirementAge : 65)
    );

    if (d) {
      setTooltip({
        top: y(d.value) / 2 + 60,
        left: x0(d.age) - 84,
        date: parseDate(d.date),
        value: `CHF ${formatMoney(d.value)}`,
      });
      selectAxisLabel(d).attr(
        "style",
        `${
          hideTicks ? "opacity:1;transition:opacity 0.3s;" : ""
        }font-weight: 600;text-anchor: middle;fill:#242F38 !important`
      );
    }
  }

  useEffect(() => {
    drawChart();

    return () => {
      select("#bar-chart-main").selectAll("svg").remove();
    };
  }, [drawChart]);

  return (
    <div ref={ref} id="bar-chart-main" style={{ position: "relative" }}>
      <div
        className={styles.tooltip}
        style={{
          opacity: tooltip.top ? 1 : 0,
          transition: tooltip.top ? "opacity 0.5s" : "none",
          top: tooltip.top,
          left: tooltip.left,
          zIndex: 3,
          position: "absolute",
        }}
      >
        <div
          className={styles.rect}
          style={{ marginLeft: "6.5rem", marginTop: "1.2rem" }}
        />
        <div className={styles.tooltipDate}>{tooltip.date}</div>
        <div className={styles.tooltipAmount}>{tooltip.value}</div>
      </div>
    </div>
  );
}
