import { useSelector } from "react-redux";

import { RootState } from "../../reducers";

const useImpersonationData = () => {
  return useSelector(
    (state: RootState) => state?.app?.impersonation?.impersonationBox
  ) || null;
};

export default useImpersonationData;
