import React, {Component} from 'react'
import Card from '../../components/Card';
import './Terms.scss'

interface State {

}

interface Props {

}

export default class Terms extends Component<Props,State> {
    constructor(props: Props) {
        super(props);
        this.state = {

        }
    }
    render(){
        return (
            <div className="terms">
                <div className="row">
                    <div className="col-12">
                        <button className="zuruck">
                            <p className="zuruck-text">Zurück</p>
                        </button>
                    </div>
                </div>
                <div className="row">
                    <div className="col-8 mx-auto">
                        <h1 className="mb-48 font-weight-bold">Nutzungsbedingungen</h1>
                        <Card>
                            <div className="p-48">
                                <h4 className="mb-4">Lorem ipsum</h4>
                                <p className="mb-5">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum laoreet.
                                    Proin gravida dolor sit amet lacus accumsan et viverra justo commodo. Proin sodales pulvinar sic tempor.
                                    Sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Nam fermentum, nulla
                                    luctus pharetra vulputate, felis tellus mollis orci, sed rhoncus pronin sapien nunc accuan eget.
                                </p>
                                <p className="mb-48">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum laoreet.
                                    Proin gravida dolor sit amet lacus accumsan et viverra justo commodo. Proin sodales pulvinar sic tempor.
                                    Sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Nam fermentum,
                                    nulla luctus pharetra vulputate, felis tellus mollis orci, sed rhoncus pronin sapien nunc accuan eget.
                                </p>
                                <h4 className="mb-4">Lorem ipsum</h4>
                                <p className="mb-48">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum laoreet.
                                    Proin gravida dolor sit amet lacus accumsan et viverra justo commodo. Proin sodales pulvinar sic tempor.
                                    Sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Nam fermentum, nulla
                                    luctus pharetra vulputate, felis tellus mollis orci, sed rhoncus pronin sapien nunc accuan eget.
                                </p>
                                <p className="font-weight-bold mb-4">Lorem ipsum</p>
                                <p className="mb-5">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum laoreet.
                                    Proin gravida dolor sit amet lacus accumsan et viverra justo commodo. Proin sodales pulvinar sic tempor.
                                    Sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Nam fermentum, nulla
                                    luctus pharetra vulputate, felis tellus mollis orci, sed rhoncus pronin sapien nunc accuan eget.
                                </p>
                                <p className="mb-5">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum laoreet.
                                    Proin gravida dolor sit amet lacus accumsan et viverra justo commodo. Proin sodales pulvinar sic tempor.
                                    Sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Nam fermentum, nulla
                                    luctus pharetra vulputate, felis tellus mollis orci, sed rhoncus pronin sapien nunc accuan eget.
                                </p>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum laoreet.
                                    Proin gravida dolor sit amet lacus accumsan et viverra justo commodo. Proin sodales pulvinar
                                    sic tempor. Sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.
                                    Nam fermentum, nulla luctus pharetra vulputate, felis tellus mollis orci,
                                    sed rhoncus pronin sapien nunc accuan eget.
                                </p>
                            </div>
                        </Card>
                    </div>
                </div>
            </div>
        )
    }
}
