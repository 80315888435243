import { ReactComponent as Logo } from "../../../assets/icons/logo.svg";
import LanguageSelector from "../../LanguageSelector/LanguageSelector";
import styles from "./style.module.scss";

interface Props {
  logoUrl?: string;
  removeLogo?: boolean;
  removeLanguageSupport?: boolean;
}

export default function Header(props: Props) {
  const getPageLogo = () => {
    if (props.removeLogo) {
      return null;
    }
    
    if (props.logoUrl) {
      return <img className={styles.headerLogo} src={props.logoUrl} alt="" />;
    }

    return <Logo />;
  };

  return (
    <div className="d-flex justify-content-between">
      {getPageLogo()}
      {!props.removeLanguageSupport && <LanguageSelector />}
    </div>
  );
}
