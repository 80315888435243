import { Action } from "./index";
import {
  SET_AHV_NUMBER,
  SET_BIRTHDAY,
  SET_LASTNAME,
  SET_NAME,
  SET_PHONE_NUMBER,
  SET_REGISTER_SMS_CODE,
  SET_REGISTRATION_CODE,
  SET_REGISTRATION_ID,
  SET_SESSION,
} from "../constants/actions";
import { api } from "../utils/api";

export const registrationCode = (payload) => {
  return async function (dispatch) {
    try {
      const response = await api.post(`registrations/`, { data: payload });
      return response.data.data;
    } catch (e) {
      throw e;
    }
  };
};

export const register = (payload, registrationId) => {
  return async function (dispatch) {
    try {
      const response = await api.put(`registrations/${registrationId}`, {
        data: payload,
      });
      return response.data.data;
    } catch (e) {
      throw e;
    }
  };
};

export const validEmail = (payload, registrationId) => {
  return async function (dispatch) {
    try {
      const response = await api.patch(
        `registrations/validemail/${registrationId}`,
        { data: payload }
      );
      return response.data.data;
    } catch (e) {
      throw e;
    }
  };
};

export const sendSms = (payload, registrationId) => {
  return async function (dispatch) {
    try {
      const response = await api.patch(
        `registrations/sendsms/${registrationId}`,
        { data: payload }
      );
      dispatch({ type: SET_SESSION, payload: registrationId });
      return response.data.data;
    } catch (e) {
      throw e;
    }
  };
};

export const resendSms = (registrationId) => {
  return async function (dispatch) {
    try {
      const response = await api.patch(
        `registrations/resendsms/${registrationId}`,
        {}
      );
      return response.data.data;
    } catch (e) {
      throw e;
    }
  };
};

export const validateSms = (payload, registrationId) => {
  return async function (dispatch) {
    try {
      const response = await api.patch(
        `registrations/validatesms/${registrationId}`,
        { data: payload }
      );
      return response.data.data;
    } catch (e) {
      throw e;
    }
  };
};

export const resendLetter = (registrationId) => {
  return async function (dispatch) {
    try {
      const response = await api.patch(
        `registrations/resendletter/${registrationId}`,
        {}
      );
      return response.data.data;
    } catch (e) {
      throw e;
    }
  };
};

export const validateLetter = (payload, registrationId) => {
  return async function (dispatch) {
    try {
      const response = await api.patch(
        `registrations/validateletter/${registrationId}`,
        { data: payload }
      );
      return response.data.data;
    } catch (e) {
      throw e;
    }
  };
};

export const setName = (value): Action => ({
  type: SET_NAME,
  payload: value,
});

export const setLastname = (value): Action => ({
  type: SET_LASTNAME,
  payload: value,
});

export const setBirthday = (value): Action => ({
  type: SET_BIRTHDAY,
  payload: value,
});

export const setAhvNumber = (value): Action => ({
  type: SET_AHV_NUMBER,
  payload: value,
});

export const setPhoneNumber = (value): Action => ({
  type: SET_PHONE_NUMBER,
  payload: value,
});

export const setRegisterSmsCode = (value): Action => ({
  type: SET_REGISTER_SMS_CODE,
  payload: value,
});

export const setRegistrationCode = (value): Action => ({
  type: SET_REGISTRATION_CODE,
  payload: value,
});

export const setRegistrationId = (value): Action => ({
  type: SET_REGISTRATION_ID,
  payload: value,
});
