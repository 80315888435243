import styled, { css } from "styled-components";
import { palette, shadows } from "@icr-ui/theme";

interface Props {
  $isOpen: boolean;
}

const SIZES = {
  actionButton: {
    height: 56,
    closed_width: 56,
    expanded_width: 310,
    icon_offset: 36,
  },
};

export const StyledFixedWrapper = styled.div`
  z-index: 5;
  position: fixed;
  bottom: 12px;
  right: 22px;
`;

export const StyledWrapper = styled.div`
  position: relative;
`;

export const StyledButton = styled.button<Props>`
  @keyframes fadeIn {
    from {
      opacity: 0;
      scale: 0.5;
    }
    to {
      opacity: 1;
      scale: 1;
    }
  }

  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 12px;
  width: ${SIZES.actionButton.closed_width}px;
  height: ${SIZES.actionButton.height}px;
  padding: 0;
  margin: 0;
  border: none;
  border-radius: 28px;
  background: ${palette.primary.main};
  box-shadow: ${shadows.sticky};
  transition: all 0.3s ease;
  animation: fadeIn 0.3s;

  &:hover {
    background: ${palette.primary.dark};
  }

  span {
    position: absolute;
    top: 0;
    right: ${SIZES.actionButton.icon_offset - 6}px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: ${SIZES.actionButton.expanded_width -
    SIZES.actionButton.icon_offset}px;
    height: ${SIZES.actionButton.height}px;
    padding: 0 16px;
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    color: ${palette.text.inverted};
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s ease;
    text-align: center;
  }

  ${(props) =>
    !props.$isOpen &&
    css`
      &:hover {
        width: ${SIZES.actionButton.expanded_width}px;

        span {
          opacity: 1;
          visibility: visible;
        }
      }
    `}
`;

export const StyledButtonOverflow = styled.div`
  overflow: hidden;
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  height: ${SIZES.actionButton.height}px;
  margin: 0;
`;

export const StyledIconWrapper = styled.div`
  top: 35px;
  right: 0;
  display: flex;
  flex-basis: 18px;
  flex-grow: 0;
  flex-shrink: 0;
  justify-content: center;
  margin-right: 19px;
`;

export const StyledCard = styled.div`
  position: absolute;
  bottom: 64px;
  right: 0;
  width: 314px;
  padding: 24px;
  background: ${palette.text.inverted};
  border-radius: 8px;
  box-shadow: ${shadows.sticky};
`;
