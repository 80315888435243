import {
  Button,
  ButtonColors,
  ButtonTypes,
  ExpandableRow,
} from "icr-shared-components";
import { useTranslation } from "react-i18next";
import { InvalidityAndDeathBenefitBox as DataType } from "../../actions/versicherungen";
import { ReactComponent as Arrow } from "../../assets/icons/arrow-right.svg";
import { formatMoney } from "../../utils/currency";
import Hint from "../Hint";

interface InvalidityAndDeathBenefitBoxProps {
  data: DataType;
}
export default function InvalidityAndDeathBenefitBox({
  data,
}: InvalidityAndDeathBenefitBoxProps) {
  const { t: translate } = useTranslation(["creditBalancePage"]);
  const t = (key: string, defaultValue: string) =>
    translate(key, { defaultValue });
  return data.isVisible ? (
    <ExpandableRow
      expandable
      className="mt-24"
      content={
        <div className="expandable-row-content">
          <p>
            <b>
              {t(
                "invalidityAndDeathBenefitBox.title",
                "Leisungen bei Invalidität oder im Todesfall"
              )}
            </b>
          </p>
          <p>
            <b>31’214.20</b>
          </p>
        </div>
      }
      more={
        <div>
          <div className="expended-more-box">
            <p>
              <b>
                {t(
                  "invalidityAndDeathBenefitBox.invalidityBenefitsList.01.label",
                  "Invalidenrente"
                )}
              </b>
            </p>
            {data.invalidityBenefitsList.map((item, index) => (
              <div key={index} className="expended-more-box-row">
                <div>
                  {item.label}
                  {item.helpText && <Hint text={item.helpText} />}
                </div>
                <div>{formatMoney(item.value)}</div>
              </div>
            ))}
          </div>
          <hr className="divider" />
          <div className="expended-more-box">
            <p>
              <b>{t("invalidityAndDeathBenefitBox.title", "Todesfall")}</b>
            </p>
            {data.fatalityBenefitsList.map((item, index) => (
              <div key={index} className="expended-more-box-row">
                <div>
                  {item.label}
                  {item.helpText && <Hint text={item.helpText} />}
                </div>
                <div>{formatMoney(item.value)}</div>
              </div>
            ))}
          </div>
          <div className="expended-more-buttons-wrapper">
            <div className={"mb-16"}>
              {t(
                "invalidityAndDeathBenefitBox.moreInformationLabel",
                "Weitereführende Informationen für Invalititätes- und Todesfallleistungen"
              )}
            </div>

            <Button
              type={ButtonTypes.Text}
              color={ButtonColors.Action}
              onClick={() => console.log("EDIT")}
              className={"mb-16"}
            >
              {t(
                "invalidityAndDeathBenefitBox.benficiaryLinkText",
                "BEGÜNSTIGE IM TODESFALL"
              )}
              <Arrow className="ml-16" />
            </Button>

            <Button
              type={ButtonTypes.Text}
              color={ButtonColors.Action}
              onClick={() => console.log("EDIT")}
            >
              {t(
                "invalidityAndDeathBenefitBox.civilStatusLinkText",
                "ZIVILSTAND / KONKUBINAT"
              )}
              <Arrow className="ml-16" />
            </Button>
          </div>
        </div>
      }
    />
  ) : (
    <></>
  );
}
