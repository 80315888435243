import { Button, ButtonColors, ButtonTypes } from "icr-shared-components";
import { ButtonStyles } from "icr-shared-components/dist";
import { FunctionComponent, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import { bindActionCreators, Dispatch } from "redux";
import { Action, setLoading } from "../../../actions";
import { resendLetter } from "../../../actions/register";
import { RootState } from "../../../reducers";
import { useTranslation } from "react-i18next";
import { getRouteByLanguage, DefaultRouteNames } from "../../../routes";

interface Props {
  sessionId: string;
  resendLetter: any;
  setLoading: any;
}

const Send: FunctionComponent<Props> = ({
  sessionId,
  resendLetter,
  setLoading,
}: Props) => {
  const { t: translate } = useTranslation(["register", "app"]);
  const t = (key: string, defaultValue: string) =>
    translate(key, { ns: "register", defaultValue });
  const history = useHistory();
  const [disableButton, setDisableButton] = useState(false);

  const next = async () => {
    setLoading(true);
    try {
      const response = await resendLetter(sessionId);
      setLoading(false);
      if (response.isSuccessfull) {
        history.push(getRouteByLanguage(DefaultRouteNames.REGISTERFINISH));
      }
      setDisableButton(false);
    } catch (error) {
      setDisableButton(false);
      console.error("error in registering", error);
    }
  };

  const back = () => {
    history.push(getRouteByLanguage(DefaultRouteNames.REGISTERCODE));
  };

  return (
    <div className="register-birthday-page send-sms-content">
      <div>
        <h1 className="mb-32">
          {t("orderRegistration.title", "Registrierungscode bestellen")}
        </h1>

        <p className="mb-48">
          {t(
            "orderRegistration.description",
            "Um die Registrierung abzuschliessen, benötigen Sie einen Registrierungscode. Diesen stellen wir Ihnen aus Sicherheitsgründen per Post zu. Sobald Sie ihn erhalten haben, können Sie sich mit Ihrer E-Mail-Adresse anmelden und werden dann nur noch nach dem Registrierungscode gefragt. Sobald Sie diesen eingegeben haben, erhalten Sie Zugriff auf das Versichertenportal."
          )}
        </p>
      </div>

      <div className="modal-buttons">
        <Button
          id="registerBackInput"
          onClick={() => back()}
          type={ButtonTypes.Text}
          color={ButtonColors.Action}
        >
          {translate("buttons.goBack", { ns: "app", defaultValue: "Zurück" })}
        </Button>

        <Button
          id="registerNextInput"
          type={ButtonTypes.Standard}
          isDisabled={disableButton}
          style={ButtonStyles.Primary}
          color={ButtonColors.Action}
          onClick={() => {
            setDisableButton(true);
            next();
          }}
        >
          {t("orderRegistration.button.sendOrder", "Bestellung senden")}
        </Button>
      </div>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  sessionId: state.app.sessionId,
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  setLoading: (value) => dispatch(setLoading(value)),
  resendLetter: bindActionCreators(resendLetter, dispatch),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(Send);
