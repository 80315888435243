import {
  LOG_OUT,
  SET_ZUSATZSPAREN_DATA,
  SET_ZUSATZSPAREN_CUSTOMIZE_DATA,
  SET_ZUSATZSPAREN_CONFIRMATION_DATA,
  SET_ZUSATZSPAREN_PLAN,
  SET_ZUSATZSPAREN_APPLY_DATA,
} from "../constants/actions";
import { Action } from "../actions";
import {
  ZusatzsparenApplyData,
  ZusatzsparenConfirmationData,
  ZusatzsparenCustomizeData,
  ZusatzsparenData,
} from "../actions/zusatzsparen";
import { financeExpertBox } from "../constants/mockups";

export interface ZusatzsparenState {
  selectedPlan: string;
  zusatzsparenData: ZusatzsparenData;
  zusatzsparenCustomizeData: ZusatzsparenCustomizeData;
  zusatzsparenConfirmationData: ZusatzsparenConfirmationData;
  zusatzsparenApplyData: ZusatzsparenApplyData;
}

const getInitialState = () => {
  return {
    selectedPlan: "",
    zusatzsparenData: {
      title: "Altersleistungen erhöhen und Steuern sparen",
      text: "Sie haben verschiedene Möglichkeiten, Ihre Altersleistung selber zu erhöhen. Erfahren Sie mehr dazu und simulieren Sie den Einfluss auf Ihre Altersleistung",
      financeExpertBox,
      additionalSavingsBox: {
        balanceMaxRatio: 0,
        currentCreditBalance: 0,
        graphData: [],
        graphMaxValue: 0,
        isActive: false,
        isVisible: false,
        maxBalanceAmount: 0,
        maxPurchaseAmount: 0,
        planName: "Basis",
        planSubtile: "Zusatzparen Option aktuell",
        subTitle: "aktuell maximal möglicher Einkauf",
        title: "Altersleistungen erhöhen und Steuern sparen",
      },
      simulationBox: {
        title: "Simulation",
        toolTip: "Erklärung zur Simulation als Tooltip",
        text: "Simulieren Sie verschiedene Vorsorgeszenarien und fin den Sie heraus, wie siche dies auf Ihre Finazielle Situation auswirken",
        link: "",
        linkText: "ZUSATZSPAREN STARTEN",
        link2: "",
        link2Text: "KAPITALEINKAUF STARTEN",
        isVisible: false,
      },
      additionalSavingsActionsShortBox: {
        title: "Jetzt aktiv werden",
        text: "Werden Sie jetzt aktiv und erhöhen Sie einfach Ihre Altersleistungen",
        isVisible: false,
        actionsList: [],
      },
      contactBox: {
        title: "Kontakt",
        text: "Gerne beraten wir Sie persönlich.",
        link: "",
        linkText: "KONTAKT AUFNEHMEN",
        isVisible: false,
      },
      additionalSavingsPageContentBox: {
        content: "",
      },
    },
    zusatzsparenCustomizeData: {
      title: "Zusatzsparen anpassen",
      subTitle: "Ihre Auswahl per 01.01.2023",
      text: '[{"type":"p","children":[{"text":"Die Einstellungen für das Zusatzsparen können Sie jederzeit jedoch spätestens bis jeweils bis 31. Oktober für das nächste Jahr anpassen. Änderungen nach dem 31. Oktober finden erst Anwendung im übernächsten Jahr."}],"id":1658947910536}]',
      additionalSavingsOptions: [
        {
          id: "00",
          title: "Basissparen +0%",
          subTitle: "Arbeitnehmerbeitrag pro Monat",
          status: "AKTUELL",
          startDate: null,
          value: 0,
          disable: false,
        },
        {
          id: "01",
          title: "Basissparen +1%",
          subTitle: "Arbeitnehmerbeitrag pro Monat",
          status: "",
          startDate: "2023-01-01T00:00:00",
          value: 0,
          disable: false,
        },
        {
          id: "02",
          title: "Basissparen +2%",
          subTitle: "Arbeitnehmerbeitrag pro Monat",
          status: "AB 01.01.2024",
          startDate: "2024-01-01T00:00:00",
          value: 0,
          disable: true,
        },
      ],
    },
    zusatzsparenConfirmationData: {
      title: "Zusatzsparen anpassen",
      subTitle: "Sie haben folgende Anpassung gewählt",
      text: '[{"type":"p","children":[{"text":"Die Beiträge des Arbeitgebers ändern sich nicht"}],"id":1658948050494},{"type":"p","id":1658948061873,"children":[{"text":"Nach Abschluss erhalten Sie eine Bestätigung. Die gewählte Option wird automatisch verlängert. Sie können diese bis jeweils den 31.10. des laufenden Jahres auf das Folgejahr anpassen."}]}]',
      additionalSavingsOption: {
        id: "01",
        title: "Basissparen +1%",
        subTitle: "Arbeitnehmerbeitrag pro Monat",
        status: "",
        startDate: "2023-01-01T00:00:00",
        value: 0,
        disable: false,
      },
    },
    zusatzsparenApplyData: {
      pdfDownloadLink: "",
      title: "Zusatzsparen anpassen",
      subTitle: "Ihre Änderung wurde erfolgreich übermittelt",
      text: '[{"type":"p","children":[{"text":"Vielen Dank für Ihre Anpassung. Sie erhalten in kürze ein Bestätigungs E-Mail. Zudem finden Sie die Änderungsbestätigung im Versichertenportal unter «Dokumente»."}],"id":1658948255841},{"type":"p","id":1658948286796,"children":[{"text":"Den neuen Versicherungsausweis angepasst auf «"},{"text":"#PlanChosen","bold":true},{"text":"» erhalten Sie nach dem "},{"text":"#StartDate","bold":true},{"text":"."}]}]',
    },
  };
};

const app = (state: ZusatzsparenState = getInitialState(), action: Action) => {
  switch (action.type) {
    case SET_ZUSATZSPAREN_DATA: {
      return { ...state, zusatzsparenData: action.payload };
    }

    case SET_ZUSATZSPAREN_CUSTOMIZE_DATA: {
      return { ...state, zusatzsparenCustomizeData: action.payload };
    }

    case SET_ZUSATZSPAREN_CONFIRMATION_DATA: {
      return { ...state, zusatzsparenConfirmationData: action.payload };
    }

    case SET_ZUSATZSPAREN_APPLY_DATA: {
      return { ...state, zusatzsparenApplyData: action.payload };
    }

    case SET_ZUSATZSPAREN_PLAN: {
      return { ...state, selectedPlan: action.payload };
    }

    case LOG_OUT: {
      console.log("log out");
      return { ...getInitialState() };
    }

    default: {
      return { ...state };
    }
  }
};

export default app;
