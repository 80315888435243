import {EValidationTestType} from "../../../utils/validationConfigs";

export const formConfig = {
    login: {
        email: {
            model: "login",
            name: "email",
            standardMessage: undefined,
            validationTests: [
                EValidationTestType.hasValue,
                EValidationTestType.isEmail,
            ],
            visible: true,
            showSuccess: true,
        },
    },
};
