import { useTranslation } from "react-i18next";
import { ReactComponent as BlockIcon } from "../../assets/icons/ic_baseline-block.svg";
import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

interface AccessRevokedProps {
  configurations: any;
}

const AccessRevoked = (props: AccessRevokedProps) => {
  const { i18n, t: translate } = useTranslation(["auth"]);
  const t = (key: string, defaultValue: string) =>
    translate(key, { defaultValue });

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const mode = queryParams.get("mode");

  return (
    <React.Fragment>
      <div style={{ display: "flex" }}>
        <BlockIcon style={{ marginTop: "0.3vh" }} />
        <h1 className="mb-24" style={{ marginLeft: "0.8vh" }}>
          {mode
            ? t("accessRevoked.fromExit.title", "Zugriff nicht möglich")
            : t("accessRevoked.locked.title", "Zugriff nicht möglich")}
        </h1>
      </div>

      <p className="mb-24">
        {mode
          ? t(
              "accessRevoked.fromExit.body",
              "Sie sind bereits seit Längerem aus der Pensionskasse ausgetreten und haben deshalb keinen Zugriff mehr auf das Versichertenportal."
            )
          : t(
              "accessRevoked.locked.body",
              "Sie sind bereits seit Längerem aus der Pensionskasse ausgetreten und haben deshalb keinen Zugriff mehr auf das Versichertenportal."
            )}
      </p>
      <p className="mb-32">
        {mode
          ? t(
              "accessRevoked.fromExit.footer",
              "Bitte melden Sie sich direkt bei Ihrer Pensionskasse."
            )
          : t(
              "accessRevoked.locked.footer",
              "Bitte melden Sie sich direkt bei Ihrer Pensionskasse."
            )}
      </p>
    </React.Fragment>
  );
};

export default AccessRevoked;
