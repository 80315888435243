import styled from "styled-components";

import { theme } from "icr-shared-components";

export const StyledInputWrapper = styled.div<{
  $hasError?: boolean;
}>`
  display: flex;
  margin-bottom: ${(props) => (props.$hasError ? 24 : 0)}px;

  div:first-child {
    margin-top: 12px;
    margin-bottom: 18px;
  }

  input {
    height: 44px;
  }

  label {
    color: ${(props) => theme.colors.content}
`;

export const StyledTitle = styled.h6`
  font-size: 16px;
  font-weight: 600;
  color: #000;
`;
