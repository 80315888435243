import { toastr } from "react-redux-toastr";

import { ReactComponent as DoneIcon } from "../../assets/icons/done.svg";
import { ReactComponent as CloseIcon } from "../../assets/icons/close-rounded.svg";

import { StyledContent, StyledContentWrapper } from "./styles";

type ToasterTpe = "success" | "error" | "warning" | "info";

interface ToasterProps {
  message: string;
  timeOut?: number;
  type: ToasterTpe;
}

const CUSTOM_TOASTER_CLASS = "custom-toastr";

const icon = {
  success: <DoneIcon />,
};

export const toaster = ({ message, timeOut, type = "info" }: ToasterProps) => {
  const component = (
    <StyledContentWrapper>
      {icon[type] && <DoneIcon />}
      <StyledContent>{message}</StyledContent>
      <CloseIcon />
    </StyledContentWrapper>
  );

  return toastr[type]("", {
    className: CUSTOM_TOASTER_CLASS,
    component,
    showCloseButton: false,
    ...(timeOut && { timeOut }),
    ...(type && { type }),
  });
};
