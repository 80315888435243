import React from "react";
import styles from "./Pill.module.scss";
import classNames from "classnames";

interface Props {
    text: string;
    type: string;
    className?: any;
}

export enum PillTypes {
    Error = "Error",
    Warning = "Warning",
}

const Pill = ({ text, type, className }: Props) => {

    const classes = classNames(styles.pill, className,
        { [styles.error]: type === PillTypes.Error },
        { [styles.warning]: type === PillTypes.Warning });

    return (
        <div className={classes}>
            {text}
        </div>
    )
}

export default Pill;

