import {
  legacy_createStore as createStore,
  applyMiddleware,
  Store,
} from "redux";
import thunk from "redux-thunk";
import rootReducer, { RootState } from "./reducers/index";
import { composeWithDevTools } from "redux-devtools-extension";

export const store = createStore(
  rootReducer(),
  composeWithDevTools(applyMiddleware(thunk))
) as Store<RootState>;

export default store;
