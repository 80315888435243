import {
  SET_ZUSATZSPAREN_DATA,
  SET_ZUSATZSPAREN_CUSTOMIZE_DATA,
  SET_ZUSATZSPAREN_CONFIRMATION_DATA,
  SET_ZUSATZSPAREN_APPLY_DATA,
} from "../constants/actions";
import { FinanceBoxProps } from "../types";
import { api } from "../utils/api";

export type ZusatzsparenData = {
  title: string;
  text: string;
  financeExpertBox: FinanceBoxProps;
  additionalSavingsBox: {
    balanceMaxRatio: number;
    currentCreditBalance: number;
    graphData: {
      title: string;
      subTitle: string;
      value: number;
      graphMaxValue: number;
      historicalGraph: [];
    }[];
    graphMaxValue: number;
    isActive: boolean;
    isVisible: boolean;
    maxBalanceAmount: number;
    maxPurchaseAmount: number;
    planName: string;
    planSubtile: string;
    subTitle: string;
    title: string;
  };
  contactBox: {
    title: string;
    text: string;
    link: string;
    linkText: string;
    isVisible: boolean;
  };
  additionalSavingsActionsShortBox: {
    title: string;
    text: string;
    isVisible: boolean;
    actionsList: {
      link: string;
      linkText: string;
    }[];
  };
  simulationBox: {
    title: string;
    toolTip: string;
    text: string;
    link: string;
    link2: string;
    linkText: string;
    link2Text: string;
    isVisible: boolean;
  };
  additionalSavingsPageContentBox: {
    content: string;
  };
};

export type ZusatzsparenCustomizeData = {
  title: string;
  subTitle: string;
  text: string;
  additionalSavingsOptions: {
    id: string;
    title: string;
    subTitle: string;
    status: string;
    startDate: string;
    value: number;
    disable: boolean;
  }[];
};

export type ZusatzsparenConfirmationData = {
  title: string;
  subTitle: string;
  text: string;
  additionalSavingsOption: {
    id: string;
    title: string;
    status: string;
    startDate: string;
    value: number;
    disable: boolean;
  };
};

export type ZusatzsparenApplyData = {
  title: string;
  subTitle: string;
  text: string;
  pdfDownloadLink: string;
};

export const getZusatzsparenCustomizeData = () => {
  return async function (dispatch) {
    try {
      const response: { data: { data: ZusatzsparenCustomizeData } } =
        await api.get("additionalsavings/customize");
      dispatch({
        type: SET_ZUSATZSPAREN_CUSTOMIZE_DATA,
        payload: response.data.data,
      });
      return response.data.data;
    } catch (err) {
      return false;
    }
  };
};

export const getZusatzsparenConfirmationData = (planId: string) => {
  return async function (dispatch) {
    try {
      const response: { data: { data: ZusatzsparenConfirmationData } } =
        await api.get(`additionalsavings/confirmation/${planId}`);
      dispatch({
        type: SET_ZUSATZSPAREN_CONFIRMATION_DATA,
        payload: response.data.data,
      });
      return response;
    } catch (err) {
      return err;
    }
  };
};

export const setZusatzsparenApplyData = (planId: string) => {
  return async function (dispatch) {
    try {
      const response: { data: { data: ZusatzsparenApplyData } } =
        await api.post(`additionalsavings/apply/${planId}`);
      dispatch({
        type: SET_ZUSATZSPAREN_APPLY_DATA,
        payload: response.data.data,
      });
      return response;
    } catch (err) {
      return err;
    }
  };
};

export const getZusatzsparenData = () => {
  return async function (dispatch) {
    try {
      const response: { data: { data: ZusatzsparenData } } = await api.get(
        "pages/additionalsavings"
      );
      dispatch({
        type: SET_ZUSATZSPAREN_DATA,
        payload: response.data.data,
      });
      return response;
    } catch (err) {
      return err;
    }
  };
};
