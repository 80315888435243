import { localStorageKeys } from "../../constants";
import ImpersonatedLoader from "../../components/ImpersonatedLoader";
import styles from "./Loader.module.scss";

const Loader = () => {
    const showimpersonatedMessage = localStorage.getItem(
        localStorageKeys.showImpersonatedMessage
      );

    if (showimpersonatedMessage) {
        return <ImpersonatedLoader />;
    }

    return (
        <div className={`${styles.loaderWrapper} loader-wrapper`}>
            <div className={`${styles.loaderOverlay} loader-overlay`}/>
            <div className={`${styles.loader} loader-loader`}/>
        </div>
    )
}

export default Loader;
