import { useState } from "react";
import { Dispatch, bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  Button,
  ButtonColors,
  ButtonTypes,
  Input,
  ValidationMessageType,
} from "icr-shared-components";
import { ButtonStyles } from "icr-shared-components/dist";
import { useTranslation } from "react-i18next";

import { Action, setLoading } from "../../actions";
import { postImpersonated } from "../../actions/impersonation";
import { useValidateFrom } from "../../utils/useValidateFrom";
import ActionButton from "../ActionButton";
import { EValidationTestType } from "../../utils/validationConfigs";
import { formatSsn } from "../../utils/formatSsn";
import useActiveImpersonatedSsn from "../../hooks/impersonation/useActiveImpersonatedSsn";
import { StyledInputWrapper, StyledTitle } from "./styles";
import { localStorageKeys } from "../../constants";
import { toaster } from "../../utils/toaster";

interface Props {
  setLoading: (value: boolean) => Action;
  postImpersonated: (ssn: string) => any;
}

const ImpersonatedActionButton = ({ setLoading, postImpersonated }: Props) => {
  const { t: translate } = useTranslation(["app", "impersonation"]);
  const t = (key: string, defaultValue: string) =>
    translate(key, { ns: "impersonation", defaultValue });

  const [ssn, setSsn] = useState<string>("");
  const [ssnResponseError, setSsnResponseError] = useState<string>();
  const activeImpersonatedSsn = useActiveImpersonatedSsn();

  const formConfig = {
    impersonation: {
      ssn: {
        model: "impersonation",
        name: "ssn",
        standardMessage: undefined,
        validationTests: [EValidationTestType.isSsn],
        visible: true,
        showSuccess: true,
      },
    },
  };

  const validation = useValidateFrom("impersonation", formConfig, { ssn });

  const hasSsnError =
    !!ssnResponseError ||
    (ssn.length === 16 && validation?.ssn?.validation?.type === "error");

  const handleImpersonatedSearch = async () => {
    if (activeImpersonatedSsn === ssn) {
      toaster({
        message: t("validation.error.currentProfile", "Aktuelles Profil"),
        type: "error",
      });

      return;
    }

    try {
      setLoading(true);

      localStorage.setItem(
        localStorageKeys.showImpersonatedMessage,
        JSON.stringify(true)
      );

      await postImpersonated(ssn);

      window.location.reload();
    } catch (e: any) {
      if (e?.response?.status === 400) {
        setSsnResponseError(
          t("validation.error.ssnNotChanged", "AHV-Nummer nicht gefunden")
        );
      }

      localStorage.removeItem(localStorageKeys.showImpersonatedMessage);

      setLoading(false);
    }
  };

  const handleChange = (name: string, value: string) => {
    setSsnResponseError(null);
    setSsn(formatSsn(value));
  };

  return (
    <ActionButton
      buttonLabel={t("loadNewUserData", "Neue Benutzerdaten laden")}
    >
      <StyledTitle>
        {t("loadNewUserData", "Neue Benutzerdaten laden")}
      </StyledTitle>

      <StyledInputWrapper $hasError={hasSsnError}>
        <Input
          id="ssnInput"
          isFocused
          type="text"
          name="ssn"
          value={ssn}
          onChange={(name, value) => handleChange(name, value)}
          placeholder="000.0000.0000.00"
          {...(hasSsnError
            ? {
                validationMessageConfig: {
                  config: {
                    message: ssnResponseError
                      ? t(
                          "validation.error.ssnNotChanged",
                          "AHV-Nummer nicht gefunden"
                        )
                      : t(
                          "validation.error.invalidSsn",
                          "Ungültige AHV-Nummer"
                        ),
                    type: ValidationMessageType.Error,
                  },
                  visible: true,
                },
              }
            : null)}
        >
          {t("ssn", "AHV-Nummer")}
        </Input>
      </StyledInputWrapper>

      <Button
        id="searchSsnButton"
        isDisabled={validation?.ssn?.validation?.type !== "success"}
        className="mx-auto bottom-button"
        style={ButtonStyles.Primary}
        type={ButtonTypes.Standard}
        color={ButtonColors.Action}
        submit
        onClick={handleImpersonatedSearch}
      >
        {t("showUserData", "Benutzerdaten anzeigen")}
      </Button>
    </ActionButton>
  );
};

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  setLoading: (value: boolean) => dispatch(setLoading(value)),
  postImpersonated: bindActionCreators(postImpersonated, dispatch),
});

const connector = connect(null, mapDispatchToProps);
export default withRouter(connector(ImpersonatedActionButton));
