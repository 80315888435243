import styled from "styled-components";

export const StyledWrapper = styled.div`
  z-index: 10;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: rgba(238, 239, 240, 0.5);
  backdrop-filter: blur(2px);
`;

export const StyledLoaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 487px;
  width: calc(100% - 20px);
  padding: 0 10px;
  height: 256px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0px 18px 60px -30px rgba(36, 47, 56, 0.18);
  text-align: center;
`;

export const StyledTitle = styled.h6`
  margin-bottom: 14px;
  font-size: 24px;
  font-weight: 600;
`;

export const StyledDescription = styled.p`
  margin-bottom: 24px;
  font-size: 16px;
  font-weight: 300;
`;
