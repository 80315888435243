import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useQuery } from "urql";
import { RootState } from "../../reducers";
import {
  HYGRAPH_RETRY_LIMIT,
  SET_HYGRAPH_RETRY_CONTENT_LIST,
} from "../../constants";
import { i18n } from "../../locale";

interface Props {
  schema: string;
}

const defaultLanguage = "de_CH";

const useGetCMSContent = ({ schema }: Props) => {
  const dispatch = useDispatch();
  const schemaRetryContentList = useSelector(
    (state: RootState) => state?.app?.hygraphSchemaRetryContentList
  );

  const nextRetryCount = schemaRetryContentList.hasOwnProperty(schema)
    ? schemaRetryContentList[schema]
    : 0;

  const tenant = window.localStorage.getItem("tenantName").toLowerCase();
  const parsedLocale = i18n.language.replace("-", "_");

  const availableTenant = nextRetryCount < 1 ? tenant : "default";
  const availableLanguage = nextRetryCount < 2 ? parsedLocale : defaultLanguage;

  const [result] = useQuery({
    query: `
      query {
        ${schema}(locales: ${availableLanguage}, stage: PUBLISHED) {
          ${availableTenant} {
            main {
              content {
                raw
              }
            }
          }
        }
      }
    `,
  });

  const { data, fetching, error } = result;

  const content = {
    main: data?.[schema][0]?.[availableTenant]?.main?.content?.raw || null,
  };

  useEffect(() => {
    if (nextRetryCount >= HYGRAPH_RETRY_LIMIT) return;

    if (
      (!fetching && error && error.name === "CombinedError") ||
      (!fetching && data && content.main === null)
    ) {
      dispatch({
        type: SET_HYGRAPH_RETRY_CONTENT_LIST,
        payload: {
          schema,
          initialCount: availableLanguage === defaultLanguage ? 2 : 1,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetching]);

  return {
    content,
    fetching,
    error,
  };
};

export default useGetCMSContent;
