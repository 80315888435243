import React, { useEffect, useState } from "react";
import {
  Button,
  ButtonColors,
  ButtonStyles,
  ButtonTypes,
  Input,
} from "icr-shared-components/dist";
import { useHistory } from "react-router";
import { bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";
import { toastr } from "react-redux-toastr";

import { RootState } from "../../reducers";
import { Action } from "../../actions";
import { updateCivilStatus } from "../../actions/profile";
import { formConfig } from "./formConfig";
import { useValidateFrom } from "../../utils/useValidateFrom";
import { parseDate, prepareDropdownOptions } from "../../utils/react";
import {
  civilStatusOptionsKeys,
  CivilStatusNames,
  civilStatusDefaultOptions,
} from "../../constants";
import { useTranslation } from "react-i18next";
import { toaster } from "../../utils/toaster";
import { DefaultRouteNames, getRouteByLanguage } from "../../routes";

interface Props {
  profilePersonalInformation: any;
  updateCivilStatus: any;
}

type CivilStatusType = {
  civilStatusStatus: number;
  spouseBirthDate?: string;
  validStatusChangedDate?: string;
  spouseFirstName?: string;
  spouseName?: string;
  nameAfterChange?: string;
};

const CivilStatus = ({
  profilePersonalInformation,
  updateCivilStatus,
}: Props) => {
  const { t: translate } = useTranslation(["profile", "common"]);
  const t = (key: string, defaultValue: string) =>
    translate(key, { ns: "profile", defaultValue });

  const translatedCivilStatusOptions = Object.keys(civilStatusOptionsKeys).map(
    (key) =>
      translate(`civilStatus.${civilStatusOptionsKeys[key]}`, {
        ns: "common",
        defaultValue: civilStatusDefaultOptions[key],
      })
  );

  const [civilStatus, setCivilStatus] = useState<CivilStatusType>({
    civilStatusStatus: 0,
  });

  const [isBlurred, setIsBlurred] = useState({
    civilStatusStatus: false,
    validStatusChangedDate: false,
    spouseFirstName: false,
    spouseName: false,
    spouseBirthDate: false,
    nameAfterChange: false,
  });

  const validation = useValidateFrom("profile", formConfig, civilStatus);

  const history = useHistory();

  const isMarried =
    Number(civilStatus.civilStatusStatus) === CivilStatusNames.MARRIED;
  const isDivorced =
    Number(civilStatus.civilStatusStatus) === CivilStatusNames.DIVORCED;
  const isConfirmationBtnDisabled = (): boolean => {
    if (isMarried) {
      if (civilStatus?.spouseBirthDate && civilStatus?.validStatusChangedDate) {
        return (
          validation?.spouseBirthDate?.validation?.type !== "success" ||
          validation?.validStatusChangedDate?.validation?.type !== "success"
        );
      } else if (civilStatus?.spouseBirthDate) {
        return validation?.spouseBirthDate?.validation?.type !== "success";
      } else if (civilStatus?.validStatusChangedDate) {
        return (
          validation?.validStatusChangedDate?.validation?.type !== "success"
        );
      }
    }

    if (isDivorced) {
      if (civilStatus.validStatusChangedDate) {
        return (
          validation?.validStatusChangedDate?.validation?.type !== "success"
        );
      }
    }

    return (
      !civilStatus ||
      validation?.civilStatusStatus?.validation?.type !== "success"
    );
  };

  useEffect(() => {
    setCivilStatus({
      civilStatusStatus: profilePersonalInformation.civilStatusCode,
      ...{
        validStatusChangedDate: "",
        spouseFirstName: "",
        spouseName: "",
        spouseBirthDate: "",
        nameAfterChange: "",
      },
    });
  }, [profilePersonalInformation]);

  const handleSubmit = async () => {
    const payload = {
      status: civilStatus.civilStatusStatus,
      validStatusChangedDate: civilStatus?.validStatusChangedDate || "",
      spouseFirstName: civilStatus?.spouseFirstName || "",
      spouseName: civilStatus?.spouseName || "",
      spouseBirthDate: civilStatus?.spouseBirthDate || "",
      nameAfterChange: civilStatus?.nameAfterChange || "",
    };
    const response = await updateCivilStatus(payload);
    response.status === 201 &&
      toaster({
        message: t(
          "civilStatus.updateToast",
          "Ihre neue Zivilstand wird übermittelt. Es kann ein paar Tage dauern bis die Änderung wirksam wird."
        ),
        type: "success",
      });
    history.push(getRouteByLanguage(DefaultRouteNames.PROFILE));
  };

  return (
    <>
      <div className="row">
        <div className="col-12 col-sm-6">
          <Input
            type="text"
            id="civilStatusStatus"
            name="civilStatusStatus"
            inputWrapperClass="dropdown-input"
            placeholder={t("civilStatus.status", "Zivilstand")}
            label="label"
            value={translatedCivilStatusOptions[civilStatus.civilStatusStatus]}
            selectOptions={prepareDropdownOptions(translatedCivilStatusOptions)}
            onSelected={(name: string, valueInput: number) => {
              setCivilStatus({ ...civilStatus, [name]: valueInput });
            }}
            onBlur={() =>
              setIsBlurred({ ...isBlurred, civilStatusStatus: true })
            }
            validationMessageConfig={
              (isBlurred.civilStatusStatus ||
                validation?.civilStatusStatus?.validation?.type ===
                  "success") && {
                config: validation?.civilStatusStatus?.validation,
                visible: true,
              }
            }
            sort
          >
            {t("civilStatus.status", "Zivilstand") + " *"}
          </Input>
        </div>
        {(isMarried || isDivorced) && (
          <div className="col-12 col-sm-6">
            <Input
              type="date"
              id="validStatusChangedDate"
              name="validStatusChangedDate"
              placeholder="DD.MM.JJJJ"
              label="label"
              maxLength={10}
              inputWrapperClass="text-input"
              value={parseDate(civilStatus?.validStatusChangedDate || "")}
              onChange={(name, valueInput) => {
                setCivilStatus({ ...civilStatus, [name]: valueInput });
              }}
              onBlur={() =>
                setIsBlurred({ ...isBlurred, validStatusChangedDate: true })
              }
              validationMessageConfig={
                ((isBlurred.validStatusChangedDate &&
                  civilStatus?.validStatusChangedDate) ||
                  validation?.validStatusChangedDate?.validation?.type ===
                    "success") && {
                  config: validation?.validStatusChangedDate?.validation,
                  visible: true,
                }
              }
            >
              {isMarried
                ? t("civilStatus.marriageDate", "Heiratsdatum")
                : t("civilStatus.divorceDate", "Scheidungsdatum")}
            </Input>
          </div>
        )}
        {isMarried && (
          <div className="col-12 col-sm-6">
            <Input
              type="text"
              id="spouseFirstName"
              name="spouseFirstName"
              placeholder={t(
                "civilStatus.spouseFirstName",
                "Name Ehepartner/in"
              )}
              label="label"
              inputWrapperClass="text-input"
              value={civilStatus?.spouseFirstName || ""}
              onChange={(name, valueInput) => {
                setCivilStatus({ ...civilStatus, [name]: valueInput });
              }}
              onBlur={() =>
                setIsBlurred({ ...isBlurred, spouseFirstName: true })
              }
            >
              {t("civilStatus.spouseFirstName", "Name Ehepartner/in")}
            </Input>
          </div>
        )}
        {isMarried && (
          <div className="col-12 col-sm-6">
            <Input
              type="text"
              id="spouseName"
              name="spouseName"
              placeholder={t("civilStatus.spouseName", "Vorname Ehepartner/in")}
              label="label"
              inputWrapperClass="text-input"
              value={civilStatus?.spouseName || ""}
              onChange={(name, valueInput) => {
                setCivilStatus({ ...civilStatus, [name]: valueInput });
              }}
              onBlur={() => setIsBlurred({ ...isBlurred, spouseName: true })}
            >
              {t("civilStatus.spouseName", "Vorname Ehepartner/in")}
            </Input>
          </div>
        )}
        {isMarried && (
          <div className="col-12 col-sm-6">
            <Input
              type="date"
              id="spouseBirthDate"
              name="spouseBirthDate"
              placeholder="DD.MM.JJJJ"
              label="label"
              maxLength={10}
              inputWrapperClass="text-input"
              value={parseDate(civilStatus?.spouseBirthDate || "")}
              onChange={(name, valueInput) => {
                setCivilStatus({ ...civilStatus, [name]: valueInput });
              }}
              onBlur={() =>
                setIsBlurred({ ...isBlurred, spouseBirthDate: true })
              }
              validationMessageConfig={
                ((isBlurred.spouseBirthDate && civilStatus?.spouseBirthDate) ||
                  validation?.spouseBirthDate?.validation?.type ===
                    "success") && {
                  config: validation?.spouseBirthDate?.validation,
                  visible: true,
                }
              }
            >
              {t("civilStatus.spouseBirthDate", "Geburtsdatum Ehepartner/in")}
            </Input>
          </div>
        )}
        {(isMarried || isDivorced) && (
          <div className="col-12 col-sm-6">
            <Input
              type="text"
              id="nameAfterChange"
              name="nameAfterChange"
              placeholder={t(
                "civilStatus.nameAfterChange",
                "Name nach Heirat / Scheidung"
              )}
              label="label"
              inputWrapperClass="text-input"
              value={civilStatus?.nameAfterChange || ""}
              onChange={(name, valueInput) => {
                setCivilStatus({ ...civilStatus, [name]: valueInput });
              }}
              onBlur={() =>
                setIsBlurred({ ...isBlurred, nameAfterChange: true })
              }
            >
              {t("civilStatus.nameAfterChange", "Name nach Heirat / Scheidung")}
            </Input>
          </div>
        )}
      </div>

      <div className="modal-buttons">
        <Button
          type={ButtonTypes.Text}
          color={ButtonColors.Action}
          onClick={() =>
            history.push(getRouteByLanguage(DefaultRouteNames.PROFILE))
          }
        >
          {t("civilStatus.goBackButton", "abbrechen")}
        </Button>

        <Button
          isDisabled={isConfirmationBtnDisabled()}
          type={ButtonTypes.Standard}
          style={ButtonStyles.Primary}
          color={ButtonColors.Action}
          onClick={handleSubmit}
        >
          {t("civilStatus.confirmButton", "speichern")}
        </Button>
      </div>
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  profilePersonalInformation: state.profile.profilePersonalInformation,
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  updateCivilStatus: bindActionCreators(updateCivilStatus, dispatch),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(CivilStatus);
