import { api } from "../utils/api";
import { SET_FAQ } from "../constants/actions";

export const getFAQ = (payload) => {
  return async function (dispatch) {
    try {
      const response = await api.get("faq", {
        params: payload,
      });
      dispatch({ type: SET_FAQ, payload: response.data.data });
      return response;
    } catch (err) {
      return err;
    }
  };
};
