import React from "react";
import styles from "./Card.module.scss";
import classNames from "classnames";

interface Props {
  type?: string;
  width?: string;
  position?: string;
  children: any;
  className?: any;
  isBlurred?: boolean;
}

export enum Width {
  Login = "Login",
  AccessRevoked = "AccessRevoked",
  Expired = "Expired",
  Register = "Register",
  Full = "Full",
  Auto = "Auto",
}

export enum Position {
  Center = "Center",
}

export enum Type {
  Standard = "Standard",
  Modal = "Modal",
}

export default function Card({
  type,
  width,
  position,
  className,
  children,
  isBlurred,
}: Props) {
  const classes = classNames(
    styles.card,
    className,
    { [styles.register]: width === Width.Register },
    { [styles.expired]: width === Width.Expired },
    { [styles.login]: width === Width.Login },
    { [styles.accessRevoked]: width === Width.AccessRevoked },
    { [styles.full]: width === Width.Full },
    { [styles.auto]: width === Width.Auto },
    { [styles.center]: position === Position.Center },
    { [styles.standard]: type === Type.Standard },
    { [styles.modal]: type === Type.Modal },
    { [styles.blurred]: isBlurred }
  );

  return <div className={classes}>{children}</div>;
}
