export const civilStatusOptionsKeys = {
  0: "unknow",
  1: "single",
  2: "married",
  3: "divorced",
  4: "widowed",
  5: "separate",
  // 6: "registeredPartnership",
  // 7: "concubinage",
  8: "lossedPartnership",
};

export const civilStatusDefaultOptions = {
  0: "Unbekannt",
  1: "Ledig",
  2: "Verheiratet",
  3: "Geschieden",
  4: "Verwitwet",
  5: "Getrennt",
  6: "Registrierte Partnerschaft",
  7: "Konkubinat",
  8: "Aufgelöste Partnerschaft",
};

export const localStorageKeys = {
  showImpersonatedMessage: "show-impersonated-message",
};

export const simulatorCMSKeys = {
  additionalSavings: "##simulator_additional_savings##",
  capitalPurchase: "##simulator_capital_purchase##",
};

export const hashtagLinksCMSKeys = {
  documentOrdering: "##document_ordering##",
  insuranceCertificate: "##insurance_certificate##",
};

export const cmsSchemas = {
  leavingBenefit: {
    singular: "leavingBenefit",
    plural: "leavingBenefits",
  },
  contact: {
    singular: "contact",
    plural: "contacts",
  },
  civilStatus: {
    singular: "civilStatus",
    plural: "civilStatuses",
  },
  documents: {
    singular: "document",
    plural: "documents",
  },
  death: {
    singular: "death",
    plural: "deaths",
  },
  questions: {
    singular: "question",
    plural: "questions",
  },
  homeOwnershipFinancing: {
    singular: "homeOwnershipFinancing",
    plural: "homeOwnershipFinancings",
  },
  increasePension: {
    singular: "increasePension",
    plural: "increasePensions",
  },
  insurance: {
    singular: "insurance",
    plural: "insurances",
  },
  profile: {
    singular: "profile",
    plural: "profiles",
  },
  retirement: {
    singular: "retirement",
    plural: "retirements",
  },
  retirementBenefit: {
    singular: "retirementBenefit",
    plural: "retirementBenefits",
  },
  vacation: {
    singular: "vacation",
    plural: "vacations",
  },
};

export const HYGRAPH_RETRY_LIMIT = 2;
