import * as constants from "../constants/actions";
import { Action } from "../actions";

export interface ProfileState {
  profilePersonalInformation: object;
  profileLoginData: object;
  profileAddress: object;
  newEmailAddress: string;
  newRestoreEmailAddress: string;
  newPhoneNumber: string;
  confirmationToken: string;
  disableAddressChange: boolean;
  disableCivilStatusChange: boolean;
  disableEmailChange: boolean;
  disableMobileNumberChange: boolean;
  isUserProfileReadOnly: boolean;
  isUserAbleToImpersonate: boolean;
}

const getInitialState = () => {
  return {
    isUserAbleToImpersonate: false,
    isUserProfileReadOnly: false,
    newEmailAddress: "",
    newRestoreEmailAddress: "",
    newPhoneNumber: "",
    confirmationToken: "",
    disableAddressChange: false,
    disableCivilStatusChange: false,
    disableEmailChange: false,
    disableMobileNumberChange: false,
    profilePersonalInformation: {
      firstname: "",
      lastname: "",
      birthdate: "",
      socialSecurityNumber: "",
      civilStatusCode: "",
      civilStatusName: "",
    },
    profileLoginData: {
      emailAddress: "",
      restoreEmailAddress: "",
      mobileNumber: "",
    },
    profileAddress: {
      addressRow1: "",
      addressRow2: "",
      zipCode: 0,
      city: "",
      country: "",
    },
  };
};

const profile = (state: ProfileState = getInitialState(), action: Action) => {
  switch (action.type) {
    case constants.SET_NEW_EMAIL_ADDRESS: {
      return { ...state, newEmailAddress: action.payload };
    }

    case constants.SET_NEW_RESTORE_EMAIL_ADDRESS: {
      return { ...state, newRestoreEmailAddress: action.payload };
    }

    case constants.SET_NEW_PHONE_NUMBER: {
      return { ...state, newPhoneNumber: action.payload };
    }

    case constants.SET_PROFILE_PERSONAL_INFORMATION: {
      return { ...state, profilePersonalInformation: action.payload };
    }

    case constants.SET_PROFILE_LOGIN_DATA: {
      return { ...state, profileLoginData: action.payload };
    }

    case constants.SET_PROFILE_ADDRESS: {
      return { ...state, profileAddress: action.payload };
    }

    case constants.SET_CONFIRMATION_TOKEN: {
      return { ...state, confirmationToken: action.payload };
    }

    case constants.SET_PROFILE: {
      console.log(
        "%c TEST action.payload",
        "background: #0f0;",
        action.payload
      );

      const personalInformation = action.payload.personalInformation;
      const loginData = action.payload.loginData;
      const address = action.payload.address;
      const isUserAbleToImpersonate = action.payload.ableToImpersonate;
      const isUserProfileReadOnly = action.payload.isUserProfileReadOnly;
      const disableAddressChange = action.payload.disableAddressChange;
      const disableCivilStatusChange = action.payload.disableCivilStatusChange;
      const disableEmailChange = action.payload.disableEmailChange;
      const disableMobileNumberChange =
        action.payload.disableMobileNumberChange;

      return {
        ...state,
        profilePersonalInformation: personalInformation,
        profileLoginData: loginData,
        profileAddress: address,
        isUserAbleToImpersonate,
        isUserProfileReadOnly,
        disableAddressChange,
        disableCivilStatusChange,
        disableEmailChange,
        disableMobileNumberChange,
      };
    }

    case constants.LOG_OUT: {
      console.log("log out");
      return { ...getInitialState() };
    }

    default: {
      return { ...state };
    }
  }
};

export default profile;
