import { SET_TODESFALL_DATA } from "../constants/actions";
import { api } from "../utils/api";

export type TodesfallData = {
  title: string;
  text: string;
  fatalityBox: {
    amount: number;
    subTitle: string;
    isVisible: boolean;
  };
  beneficariesList: {
    fullName: string;
    birthDate: string;
    ratio: string;
    relationLabel: string;
  }[];
  actionsBox: {
    title: string;
    text: string;
    isVisible: boolean;
    actionList: {
      title: string;
      text: string;
      link: string;
      linkText: string;
    }[];
  };
  simulationBox: {
    title: string;
    toolTip: string;
    text: string;
    link: string;
    linkText: string;
    isVisible: boolean;
  };
  civilStatusReportBox: {
    title: string;
    text: string;
    link: string;
    linkText: string;
    isVisible: boolean;
  };
  reportBeneficiaryOrderBox: {
    title: string;
    text: string;
    link: string;
    linkText: string;
    isVisible: boolean;
  };
  contactBox: {
    title: string;
    text: string;
    link: string;
    linkText: string;
    isVisible: boolean;
  };
  fatalityPageContentBox: {
    content: string;
  };
};

export const getTodesfallData = (payload) => {
  return async function (dispatch) {
    try {
      const response: { data: { data: TodesfallData } } = await api.get(
        "pages/fatality"
      );
      dispatch({
        type: SET_TODESFALL_DATA,
        payload: response.data.data,
      });
      return response;
    } catch (err) {
      return err;
    }
  };
};
