import React, { FunctionComponent } from "react";
import { RootState } from "../../../reducers";
import { Dispatch } from "redux";
import { Action } from "../../../actions";
import { connect } from "react-redux";
import Vegistrierungscode from "../../../assets/images/beispiel-vegistrierungscode.png";
import { useTranslation } from "react-i18next";

interface Props {}

const Message: FunctionComponent<Props> = ({}) => {
  const { t: translate } = useTranslation(["register"]);
  const t = (key: string, defaultValue: string) =>
    translate(key, { defaultValue });
  return (
    <div className="register-registration-code-message-page">
      <div className="pr-48">
        <p className="mb-32">
          {t(
            "registrationMessage.confirmation.thanks",
            "Vielen Dank. Sie erhalten Ihren persönlichen Registrierungscode in den nächsten Tagen per Post zugestellt."
          )}
        </p>

        <p className="mb-32">
          {t(
            "registrationMessage.confirmation.instructions",
            "Nach Erhalt des Registrierungscodes können Sie die Registrierung abschliessen. Dafür melden Sie sich einfach erneut mit Ihrer E-Mail-Adresse an."
          )}
        </p>
      </div>

      <img
        className="registration-code-image"
        src={Vegistrierungscode}
        alt="Vegistrierungscode"
      />
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  //
});
const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  //
});
const connector = connect(mapStateToProps, mapDispatchToProps);
export default connector(Message);
