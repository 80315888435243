import { useSelector } from "react-redux";

import { RootState } from "../../reducers";

const useIsUserAbleToImpersonate = () => {
  return useSelector(
    (state: RootState) => state?.app?.currentUser?.ableToImpersonate
  ) || false;  
};

export default useIsUserAbleToImpersonate;
