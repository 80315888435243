import { Action } from "./index";
import {
  SET_NEW_EMAIL_ADDRESS,
  SET_NEW_PHONE_NUMBER,
  SET_NEW_RESTORE_EMAIL_ADDRESS,
  SET_PROFILE,
  SET_CONFIRMATION_TOKEN,
} from "../constants/actions";
import { api } from "../utils/api";
import { preparePatchPayload } from "../utils";

export const setNewEmailAddress = (value): Action => ({
  type: SET_NEW_EMAIL_ADDRESS,
  payload: value,
});

export const setNewRestoreEmailAddress = (value): Action => ({
  type: SET_NEW_RESTORE_EMAIL_ADDRESS,
  payload: value,
});

export const setNewPhoneNumber = (value): Action => ({
  type: SET_NEW_PHONE_NUMBER,
  payload: value,
});

export const getProfile = () => {
  return async function (dispatch) {
    try {
      const response = await api.get("users/current/profile", {});
      dispatch({ type: SET_PROFILE, payload: response.data.data });
      return response;
    } catch (err) {
      return err;
    }
  };
};

export const mobileFoneConfirmation = (payload) => {
  return async function (dispatch) {
    try {
      const response = await api.put(
        "users/current/profile/mobilefoneconfirmation",
        { data: payload }
      );
      return response;
    } catch (err) {
      throw err;
    }
  };
};

export const emailConfirmation = (payload) => {
  return async function (dispatch) {
    try {
      const response = await api.put(
        "users/current/profile/emailconfirmation",
        { data: payload }
      );
      return response;
    } catch (err) {
      throw err;
    }
  };
};

export const updateProfileLoginData = (payload) => {
  return async function (dispatch) {
    try {
      const response = await api.put("users/current/profile/logindata", {
        data: payload,
      });
      dispatch({ type: SET_CONFIRMATION_TOKEN, payload: response.data.data });
      return response;
    } catch (err) {
      throw err;
    }
  };
};

export const updateProfileAddress = (payload) => {
  return async function (dispatch) {
    try {
      const response = await api.patch(
        "users/current/profile/address",
        preparePatchPayload(payload)
      );
      return response;
    } catch (err) {
      throw err;
    }
  };
};

export const updateCivilStatus = (payload: any) => {
  return async function (_dispatch) {
    try {
      const response = await api.patch(
        "users/current/profile/civilstatus",
        preparePatchPayload(payload)
      );
      return response;
    } catch (err) {
      throw err;
    }
  };
};
