import {
  createElement,
  Dispatch,
  Fragment,
  ReactElement,
  SetStateAction,
} from "react";

import { Button, ButtonColors, ButtonTypes } from "icr-shared-components";
import { Text } from "slate";
import { ReactComponent as Arrow } from "../assets/icons/arrow-right.svg";
import { ReactComponent as ExternalLink } from "../assets/icons/external-link.svg";
import { TElement } from "../types/slate";

type copyDocumentToClipboardProps = {
  dataToCopy: string;
  setShowSuccess: Dispatch<SetStateAction<boolean>>;
};

export const copyDocumentToClipboard = async ({
  dataToCopy,
  setShowSuccess,
}: copyDocumentToClipboardProps) => {
  await navigator.clipboard.writeText(dataToCopy);
  setShowSuccess(true);
  setTimeout(() => {
    setShowSuccess(false);
  }, 3000);
};

enum HTMLAttrsToTags {
  bold = "strong",
  italic = "em",
  strikethrough = "del",
  underline = "u",
  code = "code",
  superscript = "sup",
  subscript = "sub",
  color = "span",
  highlight = "span",
  backgroundColor = "span",
  lic = "span",
}

const htmlAttrs = Object.keys(
  HTMLAttrsToTags
) as (keyof typeof HTMLAttrsToTags)[];
const htmlStyles = [htmlAttrs[7], htmlAttrs[8], htmlAttrs[9], htmlAttrs[10]];

export const serializeElements = (node: TElement) => {
  if (Text.isText(node)) {
    let string: any = node.text;
    for (const attr of htmlAttrs) {
      if (node[attr]) {
        if (!htmlStyles.includes(node[attr])) {
          string = [createElement(HTMLAttrsToTags[attr], {}, string)];
        }

        if (node.color) {
          string = [
            createElement(
              HTMLAttrsToTags[attr],
              { style: { color: node.color } },
              string
            ),
          ];
        }

        if (node.highlight) {
          string = [
            createElement(
              HTMLAttrsToTags[attr],
              { style: { backgroundColor: "#FEF3B7" } },
              string
            ),
          ];
        }

        if (node.backgroundColor) {
          string = [
            createElement(
              HTMLAttrsToTags[attr],
              { style: { backgroundColor: node.backgroundColor } },
              string
            ),
          ];
        }
      }
    }
    return string;
  } else if (node.children) {
    return serializeChildren(node);
  }
};

const getChildren = (node: any[]) => {
  return node.map((n) => {
    if (typeof n === "string") {
      return n.replace(/&#39;/, "'");
    } else {
      return n;
    }
  });
};

export const serializeChildren = (node: TElement) => {
  const children = getChildren(
    node.children.map((n, i) => (
      <Fragment key={i}>{serializeElements(n as TElement)}</Fragment>
    ))
  );
  const isTargetBlank =
    node.url &&
    node.url.startsWith("https://") &&
    !node.url.includes("pensiondynamics");

  const isInternalIcon =
    (node.url && node.url.includes("pensiondynamics")) ||
    (node.url && node.url.startsWith("/"));
  let html: ReactElement[] = [];
  switch (node.type) {
    case "h1":
      html = [
        createElement("h1", { className: "mb-16", key: node.id }, children),
      ];
      break;
    case "h2":
      html = [
        createElement("h2", { className: "mb-16", key: node.id }, children),
      ];
      break;
    case "h3":
      html = [
        createElement("h3", { className: "mb-16", key: node.id }, children),
      ];
      break;
    case "h4":
      html = [
        createElement("h4", { className: "mb-16", key: node.id }, children),
      ];
      break;
    case "h5":
      html = [
        createElement("h5", { className: "mb-16", key: node.id }, children),
      ];
      break;
    case "h6":
      html = [
        createElement("h6", { className: "mb-16", key: node.id }, children),
      ];
      break;
    case "p":
      if (node.children.length === 1 && Text.isText(node.children[0])) {
        html = [
          createElement("p", { className: "mb-24", key: node.id }, children),
        ];
      } else {
        getChildren(node.children).map((n) => serializeElements(n as TElement));
      }
      html = [
        createElement("p", { className: "mb-24", key: node.id }, children),
      ];
      break;
    case "ul":
      if (node.children.length === 1 && Text.isText(node.children[0])) {
        html = [
          createElement("ul", { className: "mb-16", key: node.id }, children),
        ];
      } else {
        getChildren(node.children).map((n) => serializeElements(n as TElement));
      }
      html = [
        createElement("ul", { className: "mb-48", key: node.id }, children),
      ];
      break;
    case "li":
      if (node.children.length === 1 && Text.isText(node.children[0])) {
        html = [
          createElement("li", { className: "mb-16", key: node.id }, children),
        ];
      } else {
        getChildren(node.children).map((n) => serializeElements(n as TElement));
      }
      html = [
        createElement("li", { className: "mb-16", key: node.id }, children),
      ];
      break;
    case "lic":
      html = [createElement("span", { key: node.id }, children)];
      break;
    case "a":
      html = [
        <Button
          key={node.id}
          type={ButtonTypes.Text}
          color={ButtonColors.Action}
          onClick={() =>
            window.open(
              node.url,
              isTargetBlank ? "_blank" : "_self",
              "noopener noreferrer"
            )
          }
          className="m-0 p-0 inline-block"
        >
          {children}
          {isInternalIcon ? (
            <Arrow className="ml-16" />
          ) : (
            <ExternalLink className="ml-16" />
          )}
        </Button>,
      ];
      break;
    default:
      html = [createElement(node.type, { id: node.id }, children)];
  }

  return html;
};
