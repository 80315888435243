import { FinanceBoxProps } from "../types";

export const financeExpertBox: FinanceBoxProps = {
  title: "Bei Fragen zur Finanzplanung",
  subTitle: "Unabhängige Berater in Ihrer Nähe:",
  text: null,
  content:
    '[{"type":"p","children":[{"text":"Haftungsausschluss:","bold":true},{"text":" Die Pensionskasse Kanton Solothurn ist nicht Vertragspartei für Finanz- und Pensionierungsplanungen, welche zwischen Ihnen und den unabhängigen Finanzplanern zustandekommen. Sie übernimmt daher keinerlei Gewährleistung oder Haftung."}],"id":1673888924129}]',
  actionsList: [
    {
      title: null,
      text: null,
      link: "https://www.mk-finanzplanung.ch/",
      linkText: "MK-finanzplanung.ch",
      externalLink: true,
    },
    {
      title: null,
      text: null,
      link: "https://finanzplanung-gehriger.ch/",
      linkText: "finanzplanung-gehriger.ch",
      externalLink: true,
    },
  ],
  isVisible: false,
};
