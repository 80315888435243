import classNames from "classnames";
import { ReactComponent as Logo } from "../../../assets/icons/logo.svg";

import LanguageSelector from "../../LanguageSelector/LanguageSelector";
import Steps from "../../Steps";
import styles from "./Header.module.scss";
import { useTranslation } from "react-i18next";

interface Props {
  isVisible: boolean;
  steps: Array<any>;
  currentStep: number;
  logoUrl?: string;
}

export default function Header({
  isVisible,
  steps,
  currentStep,
  logoUrl,
}: Props) {
  const { t: translate } = useTranslation(["register"]);
  const t = (key: string, defaultValue: string) =>
    translate(key, { defaultValue });

  return (
    isVisible && (
      <div className={styles.headerContainer}>
        <div className={styles.headerTop}>
          {logoUrl == null && <Logo />}
          {logoUrl && (
            <img className={styles.headerLogo} src={logoUrl} alt="" />
          )}
          <LanguageSelector />
        </div>
        <div className={styles.headerBottom}>
          <div className={classNames(styles.headerTitle)}>
            <h1>{t("page.title", "Registrierung")}</h1>
          </div>
          <Steps steps={steps} current={currentStep} />
        </div>
      </div>
    )
  );
}
