import React, { FunctionComponent, useState } from "react";
import { RootState } from "../../../reducers";
import { bindActionCreators, Dispatch } from "redux";
import { Action, setLoading } from "../../../actions";
import { connect } from "react-redux";
import {
  Button,
  ButtonColors,
  ButtonTypes,
  Input,
} from "icr-shared-components";
import { useHistory } from "react-router";
import { sendSms, setPhoneNumber } from "../../../actions/register";
import { ButtonStyles } from "icr-shared-components/dist";
import { formConfig } from "./formConfig";
import { useValidateFrom } from "../../../utils/useValidateFrom";
import { useTranslation } from "react-i18next";
import { getRouteByLanguage, DefaultRouteNames } from "../../../routes";

interface Props {
  sendSms: any;
  phoneNumber: string;
  setPhoneNumber: (value: string) => void;
  sessionId: string;
  setLoading: any;
}

const Phone: FunctionComponent<Props> = ({
  phoneNumber,
  setPhoneNumber,
  sendSms,
  sessionId,
  setLoading,
}: Props) => {
  const { t: translate } = useTranslation(["register", "app"]);
  const t = (key: string, defaultValue: string) =>
    translate(key, { ns: "register", defaultValue });
  const [isBlurred, setIsBlurred] = useState(false);

  const history = useHistory();

  const validation = useValidateFrom("register", formConfig, { phoneNumber });

  const next = async () => {
    setLoading(true);
    try {
      const data = {
        mobileNumber: phoneNumber,
      };
      const response = await sendSms(data, sessionId);
      setLoading(false);
      if (response.isSuccessfull) {
        history.push(getRouteByLanguage(DefaultRouteNames.REGISTERSMS));
      }
    } catch (error) {
      console.error("error in registering", error);
    }
  };

  const back = () => {
    history.push(getRouteByLanguage(DefaultRouteNames.REGISTERAHV));
  };

  return (
    <div className="register-birthday-page">
      <div>
        <p className="mb-32">
          {t(
            "authentication.phone.page.title",
            "Für die Anmeldung ist eine zweite Authentifizierungsmethode notwendig. Dazu senden wir Ihnen ein SMS an Ihre Mobiltelefonnummer."
          )}
        </p>

        <div className="row">
          <div className="col-sm-12 col-md-6">
            <Input
              id="registerPhoneInput"
              type="text"
              name="registerPhoneInput"
              value={phoneNumber}
              onChange={(name, value) => setPhoneNumber(value)}
              onBlur={() => setIsBlurred(true)}
              placeholder={t(
                "authentication.phone.placeholder",
                "+41 7x xxx xx xx"
              )}
              inputWrapperClass="mb-48"
              validationMessageConfig={
                (isBlurred ||
                  validation?.phoneNumber?.validation?.type === "success") && {
                  config: validation.phoneNumber.validation,
                  visible: true,
                }
              }
            >
              {t("authentication.phone.label", "Mobiltelefonnummer")}
            </Input>
          </div>
        </div>
      </div>

      <div className="modal-buttons">
        <Button
          id="registerBackButton"
          onClick={() => back()}
          type={ButtonTypes.Text}
          color={ButtonColors.Action}
        >
          {translate("buttons.goBack", { ns: "app", defaultValue: "Zurück" })}
        </Button>

        <Button
          id="registerNextButton"
          isDisabled={validation?.phoneNumber?.validation?.type !== "success"}
          type={ButtonTypes.Standard}
          style={ButtonStyles.Primary}
          color={ButtonColors.Action}
          onClick={() => next()}
        >
          {translate("buttons.next", { ns: "app", defaultValue: "Weiter" })}
        </Button>
      </div>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  phoneNumber: state.app.phoneNumber,
  sessionId: state.app.sessionId,
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  setPhoneNumber: (value) => dispatch(setPhoneNumber(value)),
  sendSms: bindActionCreators(sendSms, dispatch),
  setLoading: (value) => dispatch(setLoading(value)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(Phone);
