import React, { useEffect, useState } from "react";
import {
  Button,
  ButtonColors,
  ButtonTypes,
  Input,
} from "icr-shared-components";
import { ExpandableRow } from "icr-shared-components/dist";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";

import Card from "../../components/Card";
import "../Versicherungen/Versicherungen.scss";
import "./Fragen.scss";
import { ReactComponent as Arrow } from "../../assets/icons/arrow-right.svg";
import { RootState } from "../../reducers";
import { Action } from "../../actions";
import { getFAQ } from "../../actions/faq";
import { withRouter } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { getRouteByLanguage, DefaultRouteNames } from "../../routes";
import { cmsSchemas } from "../../constants";
import { useGetSidebarCMSContent } from "../../hooks/cms";
import { HygraphSidebarComponents } from "../../components/sidebar/HygraphSidebarComponents";

interface Props {
  history: any;
  getFAQ: any;
  faq: any;
}

interface State {
  search: string;
}

const Fragen: React.FC<Props> = (props) => {
  const schema = cmsSchemas.questions.plural;
  const { content: cmsSidebarContent } = useGetSidebarCMSContent({ schema });
  const sidebarBoxes = cmsSidebarContent?.sidebar;

  const { t: translate } = useTranslation(["faq", "app"]);
  let debounce = null;

  const [state, setState] = useState<State>({
    search: "",
  });

  useEffect(() => {
    props.getFAQ();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSearch = (value) => {
    clearTimeout(debounce);
    setState({ search: value });
    debounce = setTimeout(() => {
      props.getFAQ({ searchTerm: state.search });
    }, 500);
  };

  const { search } = state;

  return (
    <div className="fragen-page">
      <div className="page-top">
        <Button
          type={ButtonTypes.Text}
          color={ButtonColors.Action}
          onClick={() =>
            props.history.push(getRouteByLanguage(DefaultRouteNames.DASHBOARD))
          }
        >
          <Arrow className="rotate-180" />{" "}
          <span className="ml-16">
            {translate("buttons.backToDashboard", {
              ns: "app",
              defaultValue: "Zurück zum dashboard",
            })}
          </span>
        </Button>
      </div>

      <div className="two-column-layout">
        <div className="left">
          <h1 className="mb-16">Häufige Fragen</h1>

          <p className="text-large mb-48">
            Hier finden Sie Antworten zu häufig gestellten Fragen. Öffnen Sie
            eine Frage oder geben Sie einen Begriff ins Suchfeld ein.
          </p>

          <Input
            type="text"
            name="search"
            value={search}
            onChange={(name, value) => handleSearch(value)}
            placeholder="Suchbegriff eingeben"
            inputWrapperClass="mb-24"
            filtering
          >
            Suche
          </Input>
        </div>

        <div className="right" />
      </div>

      <div className="pensionierung-page-content two-column-layout">
        <div className="left">
          {props.faq &&
            props.faq.map((faq) => (
              <ExpandableRow
                expandable
                content={
                  <p className="pl-32">
                    <b>{faq.question}</b>
                  </p>
                }
                more={<p className="p-32">{faq.answer}</p>}
              />
            ))}
        </div>

        <div className="right">
          <Card>
            {sidebarBoxes && (
              <HygraphSidebarComponents
                avoidBoxValidation
                boxes={sidebarBoxes}
              />
            )}
          </Card>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  faq: state.app.faq,
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  getFAQ: bindActionCreators(getFAQ, dispatch),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default withRouter(connector(Fragen));
