import React, { FunctionComponent, useState } from "react";
import { RootState } from "../../../reducers";
import { Dispatch } from "redux";
import { Action } from "../../../actions";
import { connect } from "react-redux";
import {
  Button,
  ButtonColors,
  ButtonTypes,
  Input,
} from "icr-shared-components";
import { useHistory } from "react-router";
import { setBirthday } from "../../../actions/register";
import { ButtonStyles } from "icr-shared-components/dist";
import { formConfig } from "./formConfig";
import { useValidateFrom } from "../../../utils/useValidateFrom";
import { useTranslation } from "react-i18next";
import { getRouteByLanguage, DefaultRouteNames } from "../../../routes";

interface Props {
  birthday: string;
  setBirthday: (value: string) => void;
}

const Birthday: FunctionComponent<Props> = ({ birthday, setBirthday }) => {
  const { t: translate } = useTranslation(["register", "app"]);
  const t = (key: string, defaultValue: string) =>
    translate(key, { ns: "register", defaultValue });
  const [isBlurred, setIsBlurred] = useState(false);

  const history = useHistory();

  const next = () => {
    history.push(getRouteByLanguage(DefaultRouteNames.REGISTERAHV));
  };

  const back = () => {
    history.push(getRouteByLanguage(DefaultRouteNames.REGISTERNAME));
  };

  const validation = useValidateFrom("register", formConfig, { birthday });

  return (
    <div className="register-birthday-page">
      <div className="row">
        <div className="col-sm-12 col-md-6">
          <Input
            type="date"
            id="registerBirthdayInput"
            name="registerBirthdayInput"
            value={birthday}
            onChange={(name, value) => setBirthday(value)}
            onBlur={() => setIsBlurred(true)}
            placeholder={t("personalInfo.birthdate.placeholder", "TT.MM.JJJJ")}
            inputWrapperClass="mb-48"
            validationMessageConfig={
              (isBlurred ||
                validation?.birthday?.validation?.type === "success") && {
                config: validation.birthday.validation,
                visible: true,
              }
            }
          >
            {t("personalInfo.birthdate.label", "Geburtsdatum")}
          </Input>
        </div>
      </div>

      <div className="modal-buttons">
        <Button
          id="registerBackButton"
          onClick={() => back()}
          type={ButtonTypes.Text}
          color={ButtonColors.Action}
        >
          {translate("buttons.goBack", { ns: "app", defaultValue: "Zurück" })}
        </Button>

        <Button
          id="registerNextButton"
          isDisabled={validation?.birthday?.validation?.type !== "success"}
          type={ButtonTypes.Standard}
          style={ButtonStyles.Primary}
          color={ButtonColors.Action}
          onClick={() => next()}
        >
          {translate("buttons.next", { ns: "app", defaultValue: "Weiter" })}
        </Button>
      </div>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  birthday: state.app.birthday,
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  setBirthday: (value) => dispatch(setBirthday(value)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(Birthday);
