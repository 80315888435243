import styled, { keyframes } from "styled-components";

const rotateAnimation = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

export const StyledSVGWrapper = styled.div`
  position: relative;
`;

export const StyledSVG = styled.svg`
  position: absolute;
  top: 0;
  left: 0;
  height: 72px;
  width: 72px;
  animation: ${rotateAnimation} 1s linear infinite;
  transform: rotate(-90deg);

  circle {
    fill: none;
    stroke: url(#GradientColor);
    stroke-width: 8px;
    stroke-dasharray: 200.96;
    stroke-dashoffset: 148;
  }
`;

export const StyledCircle = styled.div`
  displat: block;
  height: 72px;
  width: 72px;
  border: 8px solid #eeeff0;
  border-radius: 50%;
`;
