import React from "react";
import classNames from "classnames";
import styles from "./Columns.module.scss";

interface Props {
  titles: (string | JSX.Element)[];
  data: (string | JSX.Element)[];
  width?: number | string;
  className?: string;
}

export default function Columns({ titles, data, className, width }: Props) {
  return (
    <div
      className={classNames(styles.columns, className, {
        [styles.width]: width,
      })}
    >
      {titles &&
        titles.map((column, index) => {
          const styling = width ? { width: width } : {};
          return (
            <div
              key={index}
              className={classNames(styles.column)}
              style={styling}
            >
              <div className={styles.header}>{column}</div>
              <div className={styles.text}>{data[index] ?? '-'}</div>
            </div>
          );
        })}
    </div>
  );
}
