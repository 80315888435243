import styled, { createGlobalStyle, css } from "styled-components";
import { shadows } from "@icr-ui/theme";

const CustomToasterStyleOverride = createGlobalStyle<{
  $hasActiveImpersonation: boolean;
}>`
  .redux-toastr {  
    .top-right {
      top: ${(props) => (props.$hasActiveImpersonation ? 136 : 104)}px;
      right: 12px;
      width: auto;
      max-width: 380px;
    }

    .toastr {
      box-shadow: ${shadows.sticky};
      
      &:hover:not(.rrt-message) {
        box-shadow: ${shadows.sticky};
      }
    }
  
    .custom-toastr {
      display: flex;
      min-height: 56px;
      margin: 0;      
      padding: 12px 0;
      border-radius: 8px;
      background-color: #242F38;

      & > div {
        display: flex;
        align-items: center;
        width: 100%;

        .rrt-left-container,
        .rrt-right-container {
          display: none;
        }

        .rrt-middle-container {
          display: flex;
          justify-content: space-between;
          width: 100%;
          margin-left: 0;
          padding: 0 18px;

          & > div {
            display: flex;
            align-items: center;
          }

          svg {
            min-width: 20px;
          }
        }        
      }

      &.rrt-error {
        background: #DD1F4A;
      }

      &.rrt-success {
        background: #138057;
      }

      &.rrt-warning {
        background: #E67417;
      }
    }
  }
`;

const StyledContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  z-index: 9999999;
`;

const StyledContent = styled.div`
  margin: 0 26px 0 8px;
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
`;

export { CustomToasterStyleOverride, StyledContentWrapper, StyledContent };
