import { api } from "../utils/api";
import { SET_CONTACT } from "../constants/actions";

export const getContact = () => {
  return async function (dispatch) {
    try {
      const response = await api.get("contact/detail");
      dispatch({ type: SET_CONTACT, payload: response.data.data });
      return response;
    } catch (err) {
      return err;
    }
  };
};

export const submitContactRequest = (payload) => {
  return async function (dispatch) {
    try {
      const response = await api.post("contact/request", { data: payload });
      return response;
    } catch (e) {
      throw e;
    }
  };
};
