import classNames from "classnames";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Route, RouteComponentProps } from "react-router";
import { Dispatch, bindActionCreators } from "redux";
import { Action, globalSettings, setLoading } from "../../actions";
import { setSession } from "../../actions/login";
import { validEmail } from "../../actions/register";
import Card, { Position, Width } from "../../components/Card";
import Header from "../../components/Register/Header";
import { RootState } from "../../reducers";
import { getQueryParameter } from "../../utils";
import Ahv from "./Ahv";
import Birthday from "./Birthday";
import Code from "./Code";
import Finish from "./Finish";
import Message from "./Message";
import Name from "./Name";
import Phone from "./Phone";
import "./Register.scss";
import Send from "./Send";
import Sms from "./Sms";
import Start from "./Start";
import { getRouteByLanguage, DefaultRouteNames } from "../../routes";
import { i18n } from "../../locale";

interface RegisterProps extends RouteComponentProps {
  validEmail: any;
  setSession: any;
  setLoading: any;
  globalSettings: any;
  logoUrl: string | null;
}

interface RegisterState {
  loading: boolean;
}

const Register = (props: RegisterProps) => {
  const { t: translate } = useTranslation(["register"]);
  const t = (key: string, defaultValue: string) =>
    translate(key, { defaultValue });
  const [state, setState] = useState<RegisterState>({
    loading: true,
  });

  const steps = [
    { number: 1, text: t("steps.first", "Personalien") },
    { number: 2, text: t("steps.second", "Authentifizierung") },
    { number: 3, text: t("steps.third", "Registrierungscode") },
  ];

  const currentStep = () => {
    const path = window.location.pathname;
    if (
      path == getRouteByLanguage(DefaultRouteNames.DASHBOARD) ||
      path == getRouteByLanguage(DefaultRouteNames.REGISTERBIRTHDAY) ||
      path == getRouteByLanguage(DefaultRouteNames.REGISTERAHV)
    ) {
      return 1;
    } else if (
      path == getRouteByLanguage(DefaultRouteNames.REGISTERPHONE) ||
      path == getRouteByLanguage(DefaultRouteNames.REGISTERSMS)
    ) {
      return 2;
    } else if (
      path == getRouteByLanguage(DefaultRouteNames.REGISTERCODE) ||
      path == getRouteByLanguage(DefaultRouteNames.REGISTERMESSAGE)
    ) {
      return 3;
    }
    return 0;
  };

  const location = window.location.search;
  const urlParams = new URLSearchParams(location);
  const language = urlParams.get("language");

  useEffect(() => {
    language && i18n.changeLanguage(language);
  }, [language]);

  const onLoadPage = async () => {
    const { validEmail, setSession, history, setLoading } = props;
    const session_id = getQueryParameter("sessionid");
    const email_code = getQueryParameter("emailcode");
    if (session_id && email_code) {
      setLoading(true);
      await validEmail({ emailCode: email_code }, session_id)
        .then((res) => {
          setLoading(false);
          setState({ loading: false });
          if (!res.userDetailsConfirmed) {
            history.push(getRouteByLanguage(DefaultRouteNames.REGISTERSTART));
          } else if (!res.mobilePhoneConfirmed) {
            history.push(getRouteByLanguage(DefaultRouteNames.REGISTERPHONE));
          } else if (!res.isUserDataVerified) {
            history.push(getRouteByLanguage(DefaultRouteNames.REGISTERCODE));
          } else if (!res.letterConfirmed) {
            history.push(getRouteByLanguage(DefaultRouteNames.REGISTERCODE));
          } else if (res.bearerToken) {
            history.push(getRouteByLanguage(DefaultRouteNames.DASHBOARD));
          }
          setSession(session_id);
        })
        .catch(() => {
          history.push(getRouteByLanguage(DefaultRouteNames.LOGIN));
        });
    } else {
      history.push(getRouteByLanguage(DefaultRouteNames.LOGIN));
    }
  };

  useEffect(() => {
    onLoadPage();
  }, []);

  const { loading } = state;
  const { logoUrl } = props;
  const headerVisible =
    window.location.pathname !=
      getRouteByLanguage(DefaultRouteNames.REGISTERSEND) &&
    window.location.pathname !=
      getRouteByLanguage(DefaultRouteNames.REGISTERFINISH);

  return (
    <div className="register-page">
      {!loading && (
        <Card
          position={!headerVisible ? Position.Center : null}
          width={Width.Register}
        >
          <Header
            logoUrl={logoUrl}
            steps={steps}
            currentStep={currentStep()}
            isVisible={headerVisible}
          />

          <div
            className={classNames("register-content", {
              "full-height":
                window.location.pathname ==
                getRouteByLanguage(DefaultRouteNames.REGISTERMESSAGE),
            })}
          >
            <Route
              path={getRouteByLanguage(DefaultRouteNames.REGISTERSTART)}
              component={Start}
            />
            <Route
              path={getRouteByLanguage(DefaultRouteNames.REGISTERNAME)}
              component={Name}
            />
            <Route
              path={getRouteByLanguage(DefaultRouteNames.REGISTERBIRTHDAY)}
              component={Birthday}
            />
            <Route
              path={getRouteByLanguage(DefaultRouteNames.REGISTERAHV)}
              component={Ahv}
            />
            <Route
              path={getRouteByLanguage(DefaultRouteNames.REGISTERPHONE)}
              component={Phone}
            />
            <Route
              path={getRouteByLanguage(DefaultRouteNames.REGISTERSMS)}
              component={Sms}
            />
            <Route
              path={getRouteByLanguage(DefaultRouteNames.REGISTERMESSAGE)}
              component={Message}
            />
            <Route
              path={getRouteByLanguage(DefaultRouteNames.REGISTERCODE)}
              component={Code}
            />
            <Route
              path={getRouteByLanguage(DefaultRouteNames.REGISTERSEND)}
              component={Send}
            />
            <Route
              path={getRouteByLanguage(DefaultRouteNames.REGISTERFINISH)}
              component={Finish}
            />
          </div>
        </Card>
      )}
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  logoUrl: state.app.logoUrl,
  backgroundUrl: state.app.backgroundUrl,
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  setSession: (value) => dispatch(setSession(value)),
  setLoading: (value) => dispatch(setLoading(value)),
  validEmail: bindActionCreators(validEmail, dispatch),
  globalSettings: bindActionCreators(globalSettings, dispatch),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(Register);
