import {EValidationTestType} from "../../../utils/validationConfigs";

export const formConfig = {
    register: {
        lastname: {
            model: "register",
            name: "lastname",
            standardMessage: undefined,
            validationTests: [
                EValidationTestType.hasValue,
            ],
            visible: true,
            showSuccess: true,
        },
        name: {
            model: "register",
            name: "name",
            standardMessage: undefined,
            validationTests: [
                EValidationTestType.hasValue,
            ],
            visible: true,
            showSuccess: true,
        },
    },
};
