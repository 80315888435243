import React from "react";
import { Button, ButtonColors, ButtonTypes } from "icr-shared-components";
import { useHistory } from "react-router";
import { Dispatch } from "redux";
import { Action } from "../../../actions";
import { connect } from "react-redux";
import { ButtonStyles } from "icr-shared-components/dist";
import { DefaultRouteNames, getRouteByLanguage } from "../../../routes";

interface Props {}

const Reminder = () => {
  const history = useHistory();

  const next = () => {
    history.push(getRouteByLanguage(DefaultRouteNames.REGISTER));
  };

  return (
    <React.Fragment>
      <h2 className="mb-40">
        Wiederherstellungs- <br />
        E-Mail-Adresse registrieren
      </h2>

      <p className="mb-24">
        Durch die Registrierung einer zweiten{" "}
        <br className="mobile-line-break" /> E-Mail-{" "}
        <br className="mobile-remove-line-break" />
        Adresse können Sie Ihre Anmeldedaten in folgenden Fällen wieder einfach
        herstellen:
      </p>

      <p className="mb-16">
        – Sie haben keinen Zugriff auf Ihre für die <br />
        Anmeldung angegebene E-Mail-Adresse
      </p>

      <p className="mb-24">– Sie haben eine neue Mobiltelefonnummer</p>

      <p className="mb-48">
        Ohne diese Angabe ist in den obigen Fällen eine{" "}
        <br className="mobile-remove-line-break" />
        neue Registrierung mit per Post versendetem Registrierungscode
        notwendig.
      </p>

      <div className="mt-auto">
        <Button
          className="mx-auto mb-32 bottom-button"
          type={ButtonTypes.Standard}
          style={ButtonStyles.Primary}
          color={ButtonColors.Action}
          onClick={() => next()}
        >
          Jetzt registrieren
        </Button>

        <Button
          className="align-self-baseline mx-auto"
          type={ButtonTypes.Text}
          color={ButtonColors.Action}
          onClick={() => next()}
        >
          später registrieren
        </Button>
      </div>
    </React.Fragment>
  );
};

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  //
});

const connector = connect(null, mapDispatchToProps);

export default connector(Reminder);
