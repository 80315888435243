import React, { useEffect, useRef, useState } from "react";
import { time, select, svg, scale, max, interpolateArray } from "d3";
import "./index.css";
import styles from "./Chart.module.scss";
import { formatMoney } from "../../utils/currency";

interface Props {
  name: string;
  historicalGraph:
    | {
        date: string;
        value: number;
      }[]
    | any[];
  chartHeight?: number;
  chartWidth?: number;
  disableResize?: any;
  xAxisFormat: (arg, xIndex) => void;
  yTicksDivision?: number;
  maxYaxis?: number;
}
export default function Chart({
  name,
  historicalGraph,
  chartHeight,
  chartWidth,
  disableResize,
  xAxisFormat,
  maxYaxis,
  yTicksDivision,
}: Props) {
  const [wrapperWidth, setWrapperWidth] = useState(0);
  const [data, setData] = useState<{ date: string; value: number }[]>([
    { date: "2015-01-01", value: 0 },
  ]);
  const [tooltip, setTooltip] = useState({
    date: "",
    value: "",
    top: null,
    left: null,
  });

  const ref: any = { [`${name}`]: useRef() };

  var DURATION = 0;

  useEffect(() => {
    if (chartWidth) {
      setWrapperWidth(chartWidth);
    }
  }, [chartWidth]);

  useEffect(() => {
    if (
      historicalGraph.length &&
      historicalGraph[0].historicalGraph === undefined
    ) {
      if (historicalGraph && historicalGraph.length > 0) {
        if (historicalGraph[0].date.length > 10) {
          setData(
            historicalGraph.map((d) => ({
              ...d,
              date: d.date.slice(0, 10),
            }))
          );
        } else {
          setData(historicalGraph);
        }
      }
    }
  }, [historicalGraph]);

  useEffect(() => {
    const handleResize = () => {
      if (
        ref[name].current.offsetWidth !== wrapperWidth &&
        !disableResize &&
        !chartWidth
      ) {
        setWrapperWidth(ref[name].current.offsetWidth);
      }
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [setWrapperWidth]);

  useEffect(() => {
    if (ref[name].current) {
      setWrapperWidth(ref[name].current.offsetWidth + 0);
    }
  }, [ref[name].current, setWrapperWidth]);

  function drawLineChart(elementId, data, w, removeAxis?, inactiveArea?) {
    try {
      // parse helper functions on top
      var parse = time.format("%Y-%m-%d").parse; // data manipulation first

      data = data.map(function (datum) {
        datum.date = parse(
          datum.date.length > 9 ? datum.date.slice(0, 10) : datum.date
        );
        return datum;
      }); // TODO code duplication check how you can avoid that
    } catch {
      data = data;
    }

    var containerEl = document.getElementById(elementId),
      width = w,
      height = chartHeight || 200,
      margin = {
        top: 50,
        right: 15,
        left: 0,
        //left: 80,
        bottom: 30,
      },
      //detailWidth = 98,
      detailWidth = 48,
      detailHeight = 55,
      detailMargin = 10,
      container = select(containerEl),
      CSvg = container
        .select("svg")
        .attr("width", width)
        .attr("height", height + margin.top),
      x = time.scale().range([0, width - detailWidth]),
      xAxis = svg
        .axis()
        .scale(x)
        .ticks(data?.length)
        .tickSize(-height)
        .tickFormat(xAxisFormat),
      y = scale.linear().range([height, 0]),
      yAxis = svg
        .axis()
        .scale(y)
        .ticks(4)
        .orient("left")
        .tickFormat(function (d) {
          if (d == 0) {
            return 0;
          } else if (yTicksDivision) {
            return d / yTicksDivision;
          } else {
            return d;
          }
        }),
      yAxisTicks = svg
        .axis()
        .scale(y)
        .ticks(4)
        .tickSize(width - 27)
        .tickFormat("")
        .orient("right"),
      area = svg
        .area()
        .interpolate("linear")
        .x(function (d) {
          return x(d.date) + detailWidth / 2;
        })
        .y0(height)
        .y1(function (d) {
          return y(d.value);
        }),
      line = svg
        .line()
        .interpolate("linear")
        .x(function (d) {
          return x(d.date) + detailWidth / 2;
        })
        .y(function (d) {
          return y(d.value);
        }),
      startData = data.map(function (datum) {
        return {
          date: datum.date,
          value: 0,
        };
      }),
      circleContainer; // Compute the minimum and maximum date, and the maximum value.

    x.domain([data[0].date, data[data.length - 1].date]);

    y.domain([
      0,
      maxYaxis
        ? maxYaxis
        : max(data, function (d) {
            return d.value === 0 ? d.value : d.value + d.value / 2;
          }) + 30,
    ]);

    if (!removeAxis) {
      CSvg.append("g")
        .attr("class", "lineChart--xAxis")
        .attr(
          "transform",
          "translate(" + (detailWidth / 2 - 10) + "," + (height + 5) + ")"
        )
        .call(xAxis);
      CSvg.append("g")
        .attr("class", "lineChart--xAxis lineChart--yAxis")
        .attr("transform", "translate(" + -30 + ",-15)")
        .call(yAxis);
      CSvg.append("g")
        .attr("class", "lineChart--yAxisTicks")
        .attr("transform", "translate(" + 3 + ",0)")
        .call(yAxisTicks); // Add the line path.
    }

    // if (!inactiveArea) {
    CSvg.select(`.${name}char-container`)
      .append("path")
      .datum(startData)
      .attr("class", "lineChart--areaLine")
      .attr("d", line)
      .transition()
      .duration(0)
      .delay(0)
      .attrTween("d", tween(data, line))
      .each("end", function () {
        drawCircles(data);
      }); // Add the area path.

    CSvg.selectAll(".tick line").attr("x1", "25");

    CSvg.selectAll(".tick text")
      .attr("style", "font-size:14px; fill:#969899;font-weight: 500;")
      .attr("x", "1.32em")
      .attr("dy", "1.32em");
    // }

    CSvg.select(`.${name}char-container`)
      .append("path")
      .datum(startData)
      .attr(
        "class",
        inactiveArea ? "lineChart--area--disabled" : "lineChart--area"
      )
      .attr("d", area)
      .transition()
      .duration(0)
      .attrTween("d", tween(data, area));

    function enableDefaultLastCircle() {
      const datum = data[0];

      if (data.length > 1) {
        // Trigger mouseenter event on the last circle
        const lastCircle = circleContainer.select(
          ".lineChart--circle:first-child"
        );

        lastCircle
          .attr("class", "lineChart--circle lineChart--circle__highlighted")
          .attr("style", "opacity:1;")
          .attr("r", 7);

        select(`.${name}indicator-line`)
          .attr("y2", `${height}px`)
          .attr("y1", () => y(datum.value))
          .attr("x1", () => x(datum.date) + detailWidth / 2)
          .attr("x2", () => x(datum.date) + detailWidth / 2)
          .attr("stroke-width", "1px")
          .attr("stroke", "#242F38");

        datum.active = true;
        showCircleDetail(datum);
      }
    }

    function drawCircle(datum, index) {
      circleContainer
        .datum(datum)
        .append("circle")
        .attr("class", "lineChart--circle")
        .attr("style", "opacity:0;")
        .attr("r", 0)
        .attr("cx", function (d) {
          return x(d.date) + detailWidth / 2;
        })
        .attr("cy", function (d) {
          return y(d.value);
        })
        .on("mouseenter", function (this, d) {
          const lastHighlightedCircle = circleContainer.select(
            ".lineChart--circle.lineChart--circle__highlighted"
          );

          lastHighlightedCircle
            .attr("class", "lineChart--circle")
            .attr("r", 6)
            .attr("style", "opacity:0;");

          select(`.${name}indicator-line`).attr("stroke-width", "0px");

          hideCircleDetails();
          select(this)
            .attr(
              "class",
              "lineChart--circle lineChart--circle__highlighted:last-child"
            )
            .attr("style", "opacity:1;")
            .attr("r", 7);

          select(`.${name}indicator-line`)
            .attr("y2", `${height}px`)
            .attr("y1", () => y(d.value))
            .attr("x1", () => x(d.date) + detailWidth / 2)
            .attr("x2", () => x(d.date) + detailWidth / 2)
            .attr("stroke-width", "1px")
            .attr("stroke", "#242F38");

          d.active = true;
          showCircleDetail(d);
        })
        .on("mouseout", function (this, d) {
          select(this)
            .attr("class", "lineChart--circle")
            .attr("r", 6)
            .attr("style", "opacity:0;");

          select(`.${name}indicator-line`).attr("stroke-width", "0px");

          if (d.active) {
            hideCircleDetails();
            d.active = false;
          }
        })
        .on("click touch", function (d) {
          if (d.active) {
            showCircleDetail(d);
          } else {
            hideCircleDetails();
          }
        })
        .transition()
        .delay((DURATION / 10) * index)
        .attr("r", 6);
    }

    function drawCircles(data) {
      circleContainer = CSvg.append("g");
      data.forEach(function (datum, index) {
        drawCircle(datum, index);
        if (index === data.length - 1) {
          enableDefaultLastCircle();
        }
      });

      const parentSVG = document.getElementById(name + "lineChart");
      if (parentSVG) {
        // enable auto show last circle
        parentSVG.onmouseleave = function () {
          enableDefaultLastCircle();
        };
      }
    }

    function hideCircleDetails() {
      setTooltip({
        date: "",
        value: "",
        top: null,
        left: null,
      });
    }

    function showCircleDetail(data) {
      const options = {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      };
      setTooltip({
        date: data.date
          .toLocaleDateString("de-DE", options)
          .replace(/\//g, "."),
        value: `CHF ${formatMoney(data.value)}`,
        top: y(data.value) - detailHeight - detailMargin + 44,
        left: x(data.date) + 55,
      });
    }

    function tween(b, callback) {
      return function (a) {
        var i = interpolateArray(a, b);
        return function (t) {
          return callback(i(t));
        };
      };
    }
  }

  useEffect(() => {
    if (historicalGraph[0]?.historicalGraph) {
      drawLineChart(
        name + "lineChart",
        historicalGraph[0].historicalGraph,
        wrapperWidth,
        true,
        true
      );
      drawLineChart(
        name + "lineChart",
        historicalGraph[2].historicalGraph,
        wrapperWidth,
        true,
        true
      );
      drawLineChart(
        name + "lineChart",
        historicalGraph[1].historicalGraph,
        wrapperWidth
      );
    } else {
      drawLineChart(name + "lineChart", data, wrapperWidth);
    }
    return () => {
      select(document.getElementById(name + "lineChart"))
        .selectAll("g")
        .remove();
      select(document.getElementById(name + "lineChart"))
        .selectAll("path")
        .remove();
    };
  }, [wrapperWidth, chartWidth, data]);

  return (
    <div className={styles.chartsContainer} ref={ref[name]} id={name}>
      <div
        className={styles.tooltip}
        style={{
          opacity: tooltip.top ? 1 : 0,
          transition: tooltip.top ? "opacity 0.5s" : "none",
          top: tooltip.top,
          left: tooltip.left,
        }}
      >
        <div className={styles.rect} />
        <div className={styles.tooltipDate}>{tooltip.date}</div>
        <div className={styles.tooltipAmount}>{tooltip.value}</div>
      </div>
      <ul className={styles.ul}>
        <li className="chart">
          <div id={name + "lineChart"}>
            <svg id="lineChartSVG" className="lineChart--svg">
              <defs>
                <linearGradient
                  id="lineChart--gradientBackgroundArea"
                  x1="0"
                  x2="0"
                  y1="0"
                  y2="1"
                >
                  <stop
                    className="lineChart--gradientBackgroundArea--top"
                    offset="0%"
                  />
                  <stop
                    className="lineChart--gradientBackgroundArea--bottom"
                    offset="100%"
                  />
                </linearGradient>
                <linearGradient
                  id="lineChart--gradientBackgroundAreaDisabled"
                  x1="0"
                  x2="0"
                  y1="0"
                  y2="1"
                >
                  <stop
                    className="lineChart--gradientBackgroundAreaDisabled--top"
                    offset="0%"
                  />
                  <stop
                    className="lineChart--gradientBackgroundAreaDisabled--bottom"
                    offset="100%"
                  />
                </linearGradient>
              </defs>
              <svg
                className={name + "char-container"}
                id={name + "lineChart--areaLine"}
                width="0px"
              >
                <animate
                  xlinkHref={`#${name}lineChart--areaLine`}
                  attributeName="width"
                  from="0%"
                  to="100%"
                  dur="2s"
                  begin="0s"
                  fill="freeze"
                />
              </svg>
              <line className={name + "indicator-line"} />
            </svg>
          </div>
        </li>
      </ul>
    </div>
  );
}
