import { Dispatch } from "redux";
import {
  GLOBAL_SETTINGS,
  LOG_OUT,
  SET_CURRENT_USER,
  SET_DRAWER_LOADING,
  SET_I18N_LOADING,
  SET_IMPERSONATION_DATA,
  SET_LANGUAGES_LIST,
  SET_LOADING,
} from "../constants/actions";
import { api, clearCSRFToken } from "../utils/api";
import { CountriesEntities } from "../utils/react";
import { DefaultRouteNames, RouteURLsByLanguage } from "../routes";
import { getLanguageFromUrl, getTenantFromUrl } from "../utils";

export interface Action {
  type: string;
  payload?: any;
}

export const setLanguageItemsList = (value): Action => ({
  type: SET_LANGUAGES_LIST,
  payload: value,
});

export const setI18NLoading = (value): Action => ({
  type: SET_I18N_LOADING,
  payload: value,
});
export const setLoading = (value): Action => ({
  type: SET_LOADING,
  payload: value,
});

export const setDrawerLoading = (value): Action => ({
  type: SET_DRAWER_LOADING,
  payload: value,
});

export const logOutLocally = () => {
  return async function (dispatch: Dispatch) {
    try {
      await api.post("sessions/logout");
    } catch (e) {}
    clearCSRFToken();
    dispatch({ type: LOG_OUT });
    setTimeout(function () {
      window.open(
        `${
          window.location.origin
        }${getTenantFromUrl()}/${getLanguageFromUrl()}${
          RouteURLsByLanguage[
            `${DefaultRouteNames.LOGIN}-${getLanguageFromUrl()}`
          ]
        }`,
        "_self"
      );
    }, 2000);
  };
};

export const globalSettingsFetch = async (payload) => {
  const response = await api.get(`globalsettings/`, {
    params: {
      tenantName: payload,
    },
  });

  return response;
};

export const checkIfTenantIsValid = async (payload) => {
  try {
    const response = await api.get(`isvalidtenantcode/`, {
      params: {
        tenantName: payload,
      },
    });
    return response;
  } catch (e) {
    throw e;
  }
};

export const globalSettings = (payload) => {
  return async function (dispatch: Dispatch) {
    try {
      const response = await globalSettingsFetch(payload);

      dispatch({ type: GLOBAL_SETTINGS, payload: response.data.data });
      return response.data.data;
    } catch (e) {
      throw e;
    }
  };
};

export const current = () => {
  return async function (dispatch: Dispatch) {
    try {
      const response = await api.get("users/current/profile/");
      dispatch({ type: SET_CURRENT_USER, payload: response.data.data });
      dispatch({
        type: SET_IMPERSONATION_DATA,
        payload: response.data.meta.impersonationBox,
      });
      return response.data.data;
    } catch (e) {
      throw e;
    }
  };
};

export const getSimulatorUrl = (currentUrl: string) => {
  return async function () {
    try {
      const response = await api.get(`pages/${currentUrl}`);
      return response.data.data;
    } catch (e) {
      throw e;
    }
  };
};

export const changeCurrentUserLanguage = async (newLanguage: string) => {
  try {
    const response = await api.put(
      `users/current/changemylanguage?newLanguage=${newLanguage}`
    );
    return response;
  } catch (e) {
    throw e;
  }
};

export const fetchCountriesList = (
  language: number
): ((_dispatch: any) => Promise<CountriesEntities[]>) => {
  return async (_dispatch: any) => {
    try {
      const response = await api.get(
        `enumerator/countries?language=${language}`
      );
      return response.data.data;
    } catch (e) {
      throw e;
    }
  };
};
