import { EValidationTestType } from "../../utils/validationConfigs";

export const formConfig = {
    profile: {
        email: {
            model: "profile",
            name: "email",
            standardMessage: undefined,
            validationTests: [
                EValidationTestType.hasValue,
                EValidationTestType.isEmail,
            ],
            visible: true,
            showSuccess: true,
        },
        restoreEmail: {
            model: "profile",
            name: "restoreEmail",
            standardMessage: undefined,
            validationTests: [
                EValidationTestType.hasValue,
                EValidationTestType.isEmail,
            ],
            visible: true,
            showSuccess: true,
        },
        phone: {
            model: "profile",
            name: "phone",
            standardMessage: undefined,
            validationTests: [
                EValidationTestType.hasValue,
                EValidationTestType.isPhoneNumber,
            ],
            visible: true,
            showSuccess: true,
        },
        smsCode: {
            model: "profile",
            name: "smsCode",
            standardMessage: undefined,
            validationTests: [
                EValidationTestType.hasValue,
                EValidationTestType.isPin,
            ],
            visible: true,
            showSuccess: true,
        },
        emailCode: {
            model: "profile",
            name: "emailCode",
            standardMessage: undefined,
            validationTests: [
                EValidationTestType.hasValue
            ],
            visible: true,
            showSuccess: true,
        },
        addressRow1: {
            model: "profile",
            name: "addressRow1",
            standardMessage: undefined,
            validationTests: [EValidationTestType.hasValue],
            visible: true,
            showSuccess: true,
        },
        addressRow2: {
            model: "profile",
            name: "addressRow2",
            standardMessage: undefined,
            validationTests: [],
            visible: true,
            showSuccess: true,
        },
        zipCode: {
            model: "profile",
            name: "zipCode",
            standardMessage: undefined,
            validationTests: [
                EValidationTestType.hasValue,
                EValidationTestType.isNumber,
            ],
            visible: true,
            showSuccess: true,
        },
        city: {
            model: "profile",
            name: "city",
            standardMessage: undefined,
            validationTests: [EValidationTestType.hasValue],
            visible: true,
            showSuccess: true,
        },
        civilStatusStatus: {
            model: "profile",
            name: "civilStatusStatus",
            standardMessage: undefined,
            validationTests: [EValidationTestType.hasValue],
            visible: true,
            showSuccess: true,
        },
        validStatusChangedDate: {
            model: "profile",
            name: "validStatusChangedDate",
            standardMessage: undefined,
            validationTests: [EValidationTestType.isDate, EValidationTestType.isDateBeforeCurrentDate],
            visible: true,
            showSuccess: true,
        },
        spouseFirstName: {
            model: "profile",
            name: "spouseFirstName",
            standardMessage: undefined,
            validationTests: [],
            visible: true,
            showSuccess: true,
        },
        spouseName: {
            model: "profile",
            name: "spouseName",
            standardMessage: undefined,
            validationTests: [],
            visible: true,
            showSuccess: true,
        },
        spouseBirthDate: {
            model: "profile",
            name: "spouseBirthDate",
            standardMessage: undefined,
            validationTests: [EValidationTestType.isDate, EValidationTestType.isDateBeforeCurrentDate],
            visible: true,
            showSuccess: true,
        },
        nameAfterChange: {
            model: "profile",
            name: "nameAfterChange",
            standardMessage: undefined,
            validationTests: [],
            visible: true,
            showSuccess: true,
        },
        country: {
            model: "profile",
            name: "country",
            standardMessage: undefined,
            validationTests: [],
            visible: true,
            showSuccess: true,
        },
        countryCode: {
            model: "profile",
            name: "countryCode",
            standardMessage: undefined,
            validationTests: [EValidationTestType.hasValue],
            visible: true,
            showSuccess: true,
        }
    },
};
