import classNames from "classnames";
import { useTranslation } from "react-i18next";
import styles from "./ComingSoonStatus.module.scss";

interface Props {
  title: string;
}

export default function ComingSoonStatus({ title }: Props) {
  const { t: translate } = useTranslation(["app"]);
  const t = (key: string, defaultValue: string) =>
    translate(key, { defaultValue });
  return (
    <div className={classNames(styles.comingSoon)}>
      <p className={styles.comingSoonSubtitle}>
        {t("comingSoon.title", "Demnächst")}
      </p>
      <p className={styles.comingSoonTitle}>{title}</p>
    </div>
  );
}
