import { useCallback, useEffect, useState } from "react";
import { validationMessageConf } from "./ValidationFunctions";
import { usePrevious } from "./usePrevious";
import i18n from "../locale/i18n";

export const useValidateFrom = (
  formName: string,
  formConfig: any,
  formObj: any
) => {
  const [validations, setValidations] = useState<any>();

  const createValidationObj = useCallback((newData) => {
    let validationObj;
    Object.keys(newData).map((key) => {
      validationObj = {
        ...validationObj,
        [key]: {
          value: newData[key],
          validation: validationMessageConf({
            formName,
            modelName: formConfig[formName][key].model,
            inputName: formConfig[formName][key].name,
            standardMessage: formConfig[formName][key].standardMessage,
            validationTests: formConfig[formName][key].validationTests,
            formObj: { [formName]: { [key]: newData[key] } },
            showSuccess: formConfig[formName][key].showSuccess,
          }),
        },
      };
      return key;
    });
    setValidations(validationObj);
  }, []);

  const preFormObj = usePrevious(formObj);

  useEffect(() => {
    if (JSON.stringify(formObj) !== JSON.stringify(preFormObj)) {
      createValidationObj(formObj);
    }
  }, [formObj]);

  useEffect(() => {
    createValidationObj(formObj);
  }, [i18n.language]);

  return validations;
};
