import { useEffect } from "react";
import { Button, ButtonColors, ButtonTypes } from "icr-shared-components";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";

import Card, { Type } from "../../components/Card";
import "../Versicherungen/Versicherungen.scss";
import { ReactComponent as Arrow } from "../../assets/icons/arrow-right.svg";
import { Top } from "../../components/Card/Content/Top";
import { ReactComponent as UrlaubIcon } from "../../assets/icons/Desktop/urlaub-icon.svg";
import { RootState } from "../../reducers";
import { getUrlaubData, UrlaublData } from "../../actions/urlaub";
import { Action, setLoading } from "../../actions";
import { useTranslation } from "react-i18next";
import { cmsSchemas } from "../../constants";
import CustomRichText from "../../components/RichText";
import { getRouteByLanguage, DefaultRouteNames } from "../../routes";
import { useGetCMSContent, useGetSidebarCMSContent } from "../../hooks/cms";
import { HygraphSidebarComponents } from "../../components/sidebar/HygraphSidebarComponents";

interface Props extends RouteComponentProps {
  getUrlaubData: any;
  setLoading: any;
  urlaubData: UrlaublData;
}

const Urlaub = (props: Props) => {
  const schema = cmsSchemas.vacation.plural;
  const { content: cmsContent } = useGetCMSContent({ schema });
  const { content: cmsSidebarContent } = useGetSidebarCMSContent({ schema });
  const sidebarBoxes = cmsSidebarContent?.sidebar;

  const getInitialData = async () => {
    try {
      props.setLoading(true);
      await props.getUrlaubData();
      props.setLoading(false);
    } catch (error) {
      console.error("error in getting Urlaub page initial data", error);
    }
  };

  const { t: translate } = useTranslation(["unpaidVacationPage", "app"]);
  const t = (key: string, defaultValue: string) =>
    translate(key, { ns: "unpaidVacationPage", defaultValue });

  useEffect(() => {
    getInitialData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const data = props.urlaubData;

  return (
    <div className="altersleitung-page">
      <div className="page-top">
        <Button
          type={ButtonTypes.Text}
          color={ButtonColors.Action}
          onClick={() =>
            props.history.push(getRouteByLanguage(DefaultRouteNames.DASHBOARD))
          }
        >
          <Arrow className="rotate-180" />{" "}
          <span className="ml-16">
            {translate("buttons.backToDashboard", {
              ns: "app",
              defaultValue: "Zurück zum dashboard",
            })}
          </span>
        </Button>
      </div>

      <h1 className="mb-16">{t("title", "Unbezahlter Urlaub")}</h1>

      <p className="text-large mb-48 max-w-lg-100 max-w-xl-75 max-w-50">
        {t(
          "text",
          "Planen Sie einen unbezahlten Urlaub? Hier erfahren Sie mehr über Ihre Möglichkeiten, die Pensionskasse in dieser Zeit weiterzuführen."
        )}
      </p>

      <div className="versicherungen-page-content two-column-layout">
        <div className="left">
          <Card className="mb-48" type={Type.Standard}>
            <Top
              title={data.unpaidVacationBox.amount}
              icon={<UrlaubIcon />}
              text={t(
                "unpaidVacationBox.subTitle",
                "mögliche Optionen für Beiträge"
              )}
            />
          </Card>

          {cmsContent?.main && (
            <Card type={Type.Standard}>
              <CustomRichText content={cmsContent?.main} />
            </Card>
          )}
        </div>

        <div className="right">
          <Card>
            {sidebarBoxes && (
              <HygraphSidebarComponents boxes={sidebarBoxes} data={data} />
            )}
          </Card>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  urlaubData: state.urlaub.urlaubData,
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  getUrlaubData: bindActionCreators(getUrlaubData, dispatch),
  setLoading: (val) => dispatch(setLoading(val)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default withRouter(connector(Urlaub));
