import { useTranslation } from "react-i18next";
import React from "react";
import { ReactComponent as ArrowLeft } from "../../../assets/icons/arrow-left.svg";
import { ReactComponent as CloseRedIcon } from "../../../assets/icons/close_red.svg";
import { ReactComponent as AttentionIcon } from "../../../assets/icons/attention.svg";
import { Button, ButtonColors, ButtonTypes } from "icr-shared-components";
import { ButtonStyles } from "icr-shared-components/dist";
import { useHistory } from "react-router-dom";
import { getTenantFromUrl } from "../../../utils";

const Expired = () => {
  const { i18n, t: translate } = useTranslation(["auth"]);
  const t = (key: string, defaultValue: string) =>
    translate(key, { defaultValue });

  const history = useHistory();

  return (
    <React.Fragment>
      <div style={{ display: "flex" }}>
        <CloseRedIcon style={{ marginTop: "0.3vh" }} />
        <h2 className="mb-24" style={{ marginLeft: "0.8vh" }}>
          {t("expired.title", "Login-Link ist abgelaufen")}
        </h2>
      </div>

      <p className="mb-24">
        {t(
          "expired.paragraph1",
          "Der angeklickte Login-Link ist nicht mehr gültig, da er aus Sicherheitsgründen nur für einen begrenzten Zeitraum aktiv ist. Dies dient dazu, unbefugten Zugriff zu verhindern und die Sicherheit Ihrer Daten zu gewährleisten."
        )}
      </p>

      <h3 className="mb-12">{t("expired.subtitle1", "Mögliche Ursachen:")}</h3>

      <p className="mb-24" style={{ fontSize: "1.5rem" }}>
        {t(
          "expired.item1",
          "1. Der Link wurde nach dem Erhalt zu spät verwendet und ist abgelaufen."
        )}
        <br />
        {t(
          "expired.item2",
          "2. Der Link wurde bereits verwendet und kann aus Sicherheitsgründen nicht erneut genutzt werden."
        )}
        <br />
        {t(
          "expired.item3",
          "3. Es wurde ein neuer Login-Link angefordert, wodurch ältere Links automatisch ungültig wurden."
        )}
      </p>

      <h3 className="mb-12">{t("expired.subtitle2", "Was Sie tun können:")}</h3>

      <p className="mb-24" style={{ fontSize: "1.5rem" }}>
        <div style={{ display: "flex", marginBottom: "1.2vh" }}>
          <AttentionIcon style={{ marginRight: "0.8vh" }} />
          {t(
            "expired.item4",
            "Fordern Sie einen neuen Login-Link an, indem Sie die entsprechende Funktion auf der Login-Seite nutzen."
          )}
        </div>
        <div style={{ display: "flex" }}>
          <AttentionIcon style={{ marginRight: "0.8vh" }} />
          {t(
            "expired.item5",
            "Stellen Sie sicher, dass Sie den neuen Link innerhalb des angegebenen Zeitraums verwenden."
          )}
        </div>
      </p>

      <p className="mb-24">
        {t(
          "expired.paragraph2",
          "Falls das Problem weiterhin besteht, kontaktieren Sie bitte unseren Support."
        )}
      </p>

      <Button
        id="loginNextButton"
        className="mx-auto bottom-button mb-24"
        style={ButtonStyles.Primary}
        type={ButtonTypes.Standard}
        submit
        color={ButtonColors.Action}
        onClick={() => {
          history.push(`${getTenantFromUrl()}/${i18n.language}/login/email`);
        }}
      >
        <ArrowLeft className="mr-16" />
        {translate("buttons.goToLogin", {
          ns: "app",
          defaultValue: "ZURÜCK ZUM LOGIN",
        })}
      </Button>
    </React.Fragment>
  );
};

export default Expired;
