import { ExpandableRow } from "icr-shared-components";
import { PayrollAndContributionBox as DataType } from "../../actions/versicherungen";
import { formatMoney } from "../../utils/currency";
import Hint from "../Hint";
import { useTranslation } from "react-i18next";

interface PayrollAndContributionBoxProps {
  data: DataType;
}
export default function PayrollAndContributionBox({
  data,
}: PayrollAndContributionBoxProps) {
  const { t: translate } = useTranslation(["creditBalancePage", "common"]);
  const t = (key: string, defaultValue: string) =>
    translate(key, { ns: "creditBalancePage", defaultValue });
  return data.isVisible ? (
    <ExpandableRow
      expandable
      className="mt-24"
      content={
        <div className="expandable-row-content">
          <p>
            <b>
              {t(
                "payrollAndContributionBox.title",
                "Aktueller Lohn und Beiträge"
              )}
            </b>
          </p>
          <p>
            <b>{formatMoney(data.payrollDetailsBox.value)}</b>
          </p>
        </div>
      }
      more={
        <div>
          {data.payrollDetailsBox.isVisible && (
            <div className="expended-more-box">
              <p>
                <b>
                  {t(
                    "payrollAndContributionBox.payrollDetailsBox.title",
                    "Lohn"
                  )}
                </b>
              </p>
              {/* TODO: apply approach to translations */}
              {data.payrollDetailsBox.payrollDetailsList.map((item, index) => (
                <div key={index} className="expended-more-box-row">
                  <div>
                    {item.text}
                    {item.helpText && <Hint text={item.helpText} />}
                  </div>
                  <div className="mt-16-tablet expended-more-box-amount-col">
                    {formatMoney(item.valuePerMonth)}
                  </div>
                </div>
              ))}
            </div>
          )}
          <hr className="divider" />
          {data.contributionDetailsBox.isVisible && (
            <div className="expended-more-box">
              <p>
                <b>
                  {t(
                    "payrollAndContributionBox.contributionDetailsBox.title",
                    "Beiträge"
                  )}
                </b>
                <span className="ml-12-tablet" />
                <span className="expended-more-box-subtitle">
                  {t(
                    "payrollAndContributionBox.contributionDetailsBox.subTitle",
                    "Spar und Risikobeiträg"
                  )}
                </span>
              </p>
              <div className="expended-more-box-row tablet-hidden">
                <div />
                <div>
                  <div className="expended-more-table-header">
                    {translate("byMonth", {
                      ns: "common",
                      defaultValue: "pro Monat",
                    })}
                  </div>
                  <div className="expended-more-table-header">
                    {translate("byYear", {
                      ns: "common",
                      defaultValue: "pro Jahr",
                    })}
                  </div>
                </div>
              </div>
              {/* TODO: apply approach to translations */}
              {data.contributionDetailsBox.contributionDetailsList.map(
                (item, index) => (
                  <div key={index} className="expended-more-box-row">
                    <div>{item.text}</div>
                    <div>
                      <div>
                        <div className="expended-more-table-header tablet-visible">
                          {data.contributionDetailsBox.perMonthLabel}
                        </div>
                        {formatMoney(item.valuePerMonth)}
                      </div>
                      <div className="flex-column">
                        <div className="expended-more-table-header tablet-visible">
                          {data.contributionDetailsBox.perYearLabel}
                        </div>
                        {formatMoney(item.valuePerYear)}
                      </div>
                    </div>
                  </div>
                )
              )}
            </div>
          )}
        </div>
      }
    />
  ) : (
    <></>
  );
}
