export enum Languages {
  de = 1,
  fr = 2,
  it = 3,
  en = 4,
  pt = 5,
}

export enum CivilStatusNames {
    UNKNOWN = 0,
    SINGLE = 1,
    MARRIED = 2,
    DIVORCED = 3,
    WIDOWED = 4,
    SEPARATED = 5,
    REGISTERED_PARTNERSHIP = 6,
    CONCUBINAGE = 7,
    DISSOLVED_PARTNERSHIP = 8
}