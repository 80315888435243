import {
  Button,
  ButtonColors,
  ButtonSizes,
  ButtonTypes,
} from "icr-shared-components";
import { ButtonStyles } from "icr-shared-components/dist";
import { useEffect } from "react";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { bindActionCreators, Dispatch } from "redux";

import { Action, setLoading } from "../../actions";
import {
  AltersleistungData,
  getAltersleistungData,
} from "../../actions/altersleistung";
import { ReactComponent as Arrow } from "../../assets/icons/arrow-right.svg";
import { ReactComponent as ChartIcon } from "../../assets/icons/Desktop/chart-icon.svg";
import Card, { Type } from "../../components/Card";
import { Top } from "../../components/Card/Content/Top";
import DonutChart from "../../components/Chart/DonutChart";
import { FinanceBox } from "../../components/sidebar";
import { RootState } from "../../reducers";
import { navigateToSimulator } from "../../utils";
import { formatMoney } from "../../utils/currency";
import "../Versicherungen/Versicherungen.scss";
import "./Altersleistung.scss";
import { useTranslation } from "react-i18next";
import { cmsSchemas } from "../../constants";
import useGetCMSContent from "../../hooks/cms/useGetCMSContent";
import CustomRichText from "../../components/RichText";
import { useGetSidebarCMSContent } from "../../hooks/cms";
import { HygraphSidebarComponents } from "../../components/sidebar/HygraphSidebarComponents";
import { DefaultRouteNames, getRouteByLanguage } from "../../routes";

interface Props extends RouteComponentProps {
  getAltersleistungData: any;
  setLoading: any;
  altersleistungData: AltersleistungData;
}

const Altersleistung = (props: Props) => {
  const schema = cmsSchemas.retirementBenefit.plural;
  const { content: cmsContent } = useGetCMSContent({ schema });
  const { content: cmsSidebarContent } = useGetSidebarCMSContent({ schema });
  const sidebarBoxes = cmsSidebarContent?.sidebar;

  const { t: translate } = useTranslation(["buyInPage", "app"]);
  const t = (
    key: string,
    defaultValue: string,
    values?: Record<string, string>
  ) => translate(key, { ns: "buyInPage", defaultValue, values });

  const getInitialData = async () => {
    try {
      props.setLoading(true);
      await props.getAltersleistungData();
      props.setLoading(false);
    } catch (error) {
      console.error("error in getting austritt page initial data", error);
    }
  };

  useEffect(() => {
    getInitialData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const data = props.altersleistungData;

  const reactManagedSidebarComponents = {
    financeExpertBox: <FinanceBox {...{ ...data.financeExpertBox }} />,
    buyInActionsShortBox: (
      <div className="p-48">
        <h4 className="mb-16">{data.buyInActionsShortBox?.title}</h4>

        <p className="mb-32">{data.buyInActionsShortBox?.text}</p>

        {data.buyInActionsShortBox &&
          data.buyInActionsShortBox.actionsList &&
          data.buyInActionsShortBox.actionsList.map((item, index) => (
            <Button
              key={index}
              icon={<Arrow />}
              className="mb-12"
              type={ButtonTypes.Standard}
              style={ButtonStyles.Secondary}
              color={ButtonColors.Action}
              size={ButtonSizes.Full}
              onClick={() =>
                window.open(item.link, "_self", "noopener noreferrer")
              }
            >
              {item.linkText}
            </Button>
          ))}
      </div>
    ),
  };

  return (
    <div className="altersleitung-page">
      <div className="page-top">
        <Button
          type={ButtonTypes.Text}
          color={ButtonColors.Action}
          onClick={() =>
            props.history.push(getRouteByLanguage(DefaultRouteNames.DASHBOARD))
          }
        >
          <Arrow className="rotate-180" />{" "}
          <span className="ml-16">
            {translate("buttons.backToDashboard", {
              ns: "app",
              defaultValue: "Zurück zum dashboard",
            })}
          </span>
        </Button>
      </div>

      <h1 className="mb-16">
        {t("page.title", "Altersleistungen erhöhen und Steuern sparen")}
      </h1>

      <p className="text-large mb-48 max-w-lg-100 max-w-xl-75 max-w-50">
        {t(
          "page.text",
          "Sie haben verschiedene Möglichkeiten, Ihre Altersleistung selber zu erhöhen. Erfahren Sie mehr dazu und simulieren Sie den Einfluss auf Ihre Altersleistung"
        )}
      </p>

      <div className="versicherungen-page-content two-column-layout">
        <div className="left">
          {data.buyInOverviewBox && data.buyInOverviewBox.purchaseBox && (
            <Card className="mb-48" type={Type.Standard}>
              <Top
                className="mb-58"
                title={formatMoney(
                  data.buyInOverviewBox.purchaseBox.maxPurchaseAmount
                )}
                subText={
                  data.buyInOverviewBox.purchaseBox.homeOwnershipBalance > 0
                    ? translate("buyInOverviewBox.homeOwnershipBalance", {
                        homeOwnershipBalance:
                          data.buyInOverviewBox.purchaseBox
                            .homeOwnershipBalance,
                      })
                    : null
                }
                icon={<ChartIcon />}
                text={data.buyInOverviewBox.purchaseBox.subTitle}
              />

              <div className="big-chart-wrapper">
                <DonutChart
                  data={{
                    total: data.buyInOverviewBox.purchaseBox.maxBalanceAmount,
                    ratio: data.buyInOverviewBox.purchaseBox.balanceMaxRatio,
                    items: [
                      {
                        title: data.buyInOverviewBox.purchaseBox.tooltipTitle,
                        subtitle:
                          data.buyInOverviewBox.purchaseBox.tooltipSubtitle,
                        label:
                          data.buyInOverviewBox.purchaseBox.tooltipLabel.replace(
                            "{0}",
                            formatMoney(
                              data.buyInOverviewBox.purchaseBox
                                .currentCreditBalance
                            ).toString()
                          ),

                        value:
                          data.buyInOverviewBox.purchaseBox
                            ?.currentCreditBalance,
                      },
                    ],
                  }}
                  size={365}
                  backgroundStyles={{
                    width: "24.5rem",
                    height: "24.5rem",
                    borderWidth: "2.4rem",
                  }}
                />
              </div>

              <Button
                type={ButtonTypes.Text}
                color={ButtonColors.Action}
                onClick={() => navigateToSimulator(data.buyInOverviewBox.link)}
              >
                {translate("buttons.simulateNow", {
                  ns: "app",
                  defaultValue: "Jetzt simulieren",
                })}
                <Arrow className="ml-16" />
              </Button>
            </Card>
          )}

          {cmsContent?.main && (
            <Card type={Type.Standard}>
              <CustomRichText
                content={cmsContent?.main}
                simulationBox={data.simulationBox}
              />
            </Card>
          )}
        </div>

        <div className="right">
          <Card>
            {sidebarBoxes && (
              <HygraphSidebarComponents
                boxes={sidebarBoxes}
                data={data}
                reactManagedSidebarComponents={reactManagedSidebarComponents}
              />
            )}
          </Card>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  altersleistungData: state.altersleistung.altersleistungData,
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  getAltersleistungData: bindActionCreators(getAltersleistungData, dispatch),
  setLoading: (val) => dispatch(setLoading(val)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default withRouter(connector(Altersleistung));
