import { useSelector } from "react-redux";

import { RootState } from "../../reducers";

const useIsGetImpersonatedUsersFetched = () => {
  return useSelector(
    (state: RootState) => state?.app?.impersonation?.impersonatedUsers?.isFetched
  ) || false;
};

export default useIsGetImpersonatedUsersFetched;