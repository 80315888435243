import { RichText } from "@graphcms/rich-text-react-renderer";
import { RichTextContent } from "@graphcms/rich-text-types";

import { rendererHygraph } from "../../utils/hygraph";
import { RichTextWrapper } from "./styles";
import { useHistory } from "react-router-dom";

interface SimulationBox {
  link?: string;
  link2?: string;
}

interface Props {
  content: RichTextContent;
  simulationBox?: SimulationBox;
}

const CustomRichText = ({ content, simulationBox }: Props) => {
  const history = useHistory();

  return (
    <RichTextWrapper>
      <RichText
        content={content}
        renderers={rendererHygraph({ simulationBox, history })}
      />
    </RichTextWrapper>
  );
};

export default CustomRichText;
