import {
  defined,
  exists
} from './variableEvaluation';
import {
  EValidationTestType
} from './validationConfigs';
// import { IDictionary } from '../models';

export function hasValue(value: string, validateAgainstValues: any): boolean {
    if(!validateAgainstValues) return defined(value) && value !== '';
    if(validateAgainstValues && typeof validateAgainstValues.required === 'undefined') return defined(value) && value !== '';
    if(validateAgainstValues && validateAgainstValues.required != 'undefined' && validateAgainstValues.required != null && validateAgainstValues.required === true) {
        return defined(value) && value !== '';
    } else {
        return true;
    }
}

export function hasAnyValue(value: string): boolean {
  return defined(value);
}

export function isFirstDayOfTheMonth(value: string): boolean {
  if(/\d{2}\.\d{2}\.\d{4}/gm.test(value)){
    const parts = value.split(".");
    const day = parseInt(parts[0], 10);
    if(day === 1) return true;
  }
  return false;
}

export function isLastDayOfTheMonth(value: string): boolean {
  if(/\d{2}\.\d{2}\.\d{4}/gm.test(value)){
    const parts = value.split(".");
    const formattedDateStr = parts[2] + '-' + parts[1] + '-' + parts[0];
    const date = new Date(formattedDateStr);
    return new Date(date.getTime() + 86400000).getDate() === 1
  }
  return false;
}

export function isPasswordMatch(value1: string, value2: string): boolean {
  return exists(value1) && exists(value2) && value1 === value2;
}

export function isEmail(value: string): boolean {
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(value).toLowerCase());
}

// const swissPhoneRegex = /^(?:(?:|0{1,2}|\+{0,2})41(?:|\(0\))|0)([1-9]\d)(\d{3})(\d{2})(\d{2})$/;

export function isPhoneNumber(value: string): boolean {
  // value = value.replace(/\s/g, '');
  // const matches = swissPhoneRegex.exec(value);
  // return exists(matches);
  return value.length >= 6;
}

export function isPin(value: string): boolean {
  // value = value.replace(/\s/g, '');
  // const matches = swissPhoneRegex.exec(value);
  // return exists(matches);
  return value.length >= 4;
}

export function isPassword(value: string): boolean {
  return (
    value &&
    value.length >= 8 &&
    /[~`!@#$%\^&*+=\-\[\]\\';,/{}|\\":<>\?]/g.test(value)
  );
}

export function isSsn(value: string): boolean {
  if (checkOldSsn(value) || checkNewSsn(value)) {
    return true;
  } else {
    return false;
  }
}

export function isDate(value: string): boolean {
  // First check for the pattern
  if ( !( /^\d{1,2}\.\d{1,2}\.\d{4}$/.test(value) || /^\d{4}\-\d{1,2}\-\d{1,2}/.test(value) ) )
    return false;

  // Parse the date parts to integers
  var parts = value.split(".");
  var day = parseInt(parts[0], 10);
  var month = parseInt(parts[1], 10);
  var year = parseInt(parts[2], 10);
  console.log("parts1: ", parts, year, month , day);

  if (parts.length < 3) {
      parts = value.split("-");
      day = parseInt(parts[2], 10);
      month = parseInt(parts[1], 10);
      year = parseInt(parts[0], 10);
  }
  console.log("parts2: ", parts, year, month, day);

  // Check the ranges of month and year
  if (year < 1000 || year > 3000 || month == 0 || month > 12)
    return false;

  var monthLength = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

  // Adjust for leap years
  if (year % 400 == 0 || (year % 100 != 0 && year % 4 == 0))
    monthLength[1] = 29;

  // Check the range of the day

  return day > 0 && day <= monthLength[month - 1];
}

export function isBeschaftingungsgrad(value: string): boolean {

  if (!/[!@#%$^&*()_+\-=\[\]{};':"\\|,<>\/?]/.test(value) && value) {
    var val = parseFloat(value);
    if (val > 140 || isNaN(val)) {
      return false;
    } else {
      return true;
    }
  } else {
    return false;
  }
  // return /^([0-9]%?|[1-9][0-9]%?|[1][0-3][0-9]%?|140+%?)$/gm.test(value);
}

export function isNumber(value: string): boolean {
  return /^[^a-zA-Z]*$/g.test(value);
}

export function isPositive(value: string): boolean {
  return /^(?!-.*$).*/g.test(value);
}

export function isText(value: string): boolean {
  return /^[^0-9]*$/g.test(value);
}

export function isDegree(value: string): boolean { // degreeDisability, degreeUnemployability
  return /^([0-9]%?|[1-9][0-9]%?|100%?)$/gm.test(value);
}

export function dateBetween(value: string, validateAgainstValues: any): boolean {

    console.log("VALIDATE AGAINST VALUES:", validateAgainstValues, value);
    if(!value || value == "") return true;
    if(!validateAgainstValues) return true;
    if(validateAgainstValues.employer && (validateAgainstValues.from == null && validateAgainstValues.until == null)) return true;
    if(!validateAgainstValues.employer && (validateAgainstValues.from == null && validateAgainstValues.until == null)) return false;

    let onlyFrom = false;
    if(validateAgainstValues.employer && (validateAgainstValues.from != null && validateAgainstValues.until == null)) {
        onlyFrom = true;
    }

    if(validateAgainstValues.unchanged != null && validateAgainstValues.unchanged != "") {
        if(value === validateAgainstValues.unchanged) return true;
    }

    var dateFrom = validateAgainstValues.from;
    if(!onlyFrom) var dateTo = validateAgainstValues.until;
    var dateCheck = value;

    var d1 = dateFrom.split("T")[0].split("-");
    if(!onlyFrom) var d2 = dateTo.split("T")[0].split("-");
    var c = dateCheck.split("T")[0].split("-");

    var from = new Date(parseInt(d1[0]), parseInt(d1[1])-1, parseInt(d1[2]));  // -1 because months are from 0 to 11
    if(!onlyFrom) var to = new Date(parseInt(d2[0]), parseInt(d2[1])-1, parseInt(d2[2]));
    var check = new Date(parseInt(c[0]), parseInt(c[1])-1, parseInt(c[2]));

    if(!onlyFrom) {
        return check >= from && check <= to;
    } else {
        return check >= from;
    }
}

export function backendValid(value: string): boolean {
    console.log("CONSOLE LOG BACKEND VALIDATION", value)
    return false;
}

export function isMonth(value: string): boolean {
    return value != "0";
}

export function isYear(value: string): boolean {
    if(value) {
        return !(value.length < 4);
    }
    return true;
}

export function isDateBeforeCurrentDate(dateString: string): boolean {
  const currentDate = new Date();
  currentDate.setHours(0, 0, 0, 0);

  const [day, month, year] = dateString.split(".");
  const date = new Date(parseInt(year), parseInt(month) - 1, parseInt(day));

  return date < currentDate;
}

// Tests //
export const VALIDATION_TEST: IDictionary <
  (value1: string, validateAgainstValues ? : string) => boolean > = {
    hasValue: (value1: string, validateAgainstValues ? : string) => hasValue(value1, validateAgainstValues),
    hasAnyValue: (value1: string, validateAgainstValues ? : string) => hasAnyValue(value1),
    isPasswordMatch: (value1: string, validateAgainstValues: string) => isPasswordMatch(value1, validateAgainstValues),
    isEmail: (value1: string, validateAgainstValues ? : string) => isEmail(value1),
    isPhoneNumber: (value1: string, validateAgainstValues ? : string) => isPhoneNumber(value1),
    isPassword: (value1: string, validateAgainstValues ? : string) => isPassword(value1),
    isPin: (value1: string, validateAgainstValues ? : string) => isPin(value1),
    isSsn: (value1: string, validateAgainstValues ? : string) => isSsn(value1),
    isDate: (value1: string, validateAgainstValues ? : string) => value1 ? isDate(value1) : true,
    isFirstDayOfTheMonth: (value1: string) => isFirstDayOfTheMonth(value1),
    isLastDayOfTheMonth: (value1: string) => isLastDayOfTheMonth(value1),
    isBeschaftingungsgrad: (value1: string) => isBeschaftingungsgrad(value1),
    isNumber: (value1: string) => isNumber(value1),
    isPositive: (value1: string) => isPositive(value1),
    isText: (value1: string) => isText(value1),
    isDegree: (value1: string) => isDegree(value1),
    dateBetween: (value1: string, validateAgainstValues ? : any) => dateBetween(value1, validateAgainstValues),
    backendValid: (value1: string) => backendValid(value1),
    isDateBeforeCurrentDate: (value1: string) => isDateBeforeCurrentDate(value1),
};

export function testsFailed(
  validationTests:EValidationTestType[],
  value1,
  validateAgainstValues?:any
): EValidationTestType[] {
  return validationTests.filter((testKey: EValidationTestType) => {
      return !VALIDATION_TEST[testKey](value1, validateAgainstValues);
  });
}

export function isValid(validationTests, value1, validateAgainstValues ? ): boolean {
    return testsFailed(validationTests, value1, validateAgainstValues).length === 0;
}

export function isInputValid(
  inputValues,
  validationTests,
  inputName1: string,
  validateAgainstValues ? : any
) {
  if (defined(validationTests)) {
      return isValid(
          validationTests,
          inputValues[inputName1],
          validateAgainstValues
      );
  } else {
    return true;
  }
}
export function checkOldSsn(str): boolean {
  if (str == null || str == '' || str.length != 14) {
    return false;
  }
  var ziffern = [
    parseInt(str.substr(0, 1)),
    parseInt(str.substr(1, 1)),
    parseInt(str.substr(2, 1)),
    parseInt(str.substr(4, 1)),
    parseInt(str.substr(5, 1)),
    parseInt(str.substr(7, 1)),
    parseInt(str.substr(8, 1)),
    parseInt(str.substr(9, 1)),
    parseInt(str.substr(11, 1)),
    parseInt(str.substr(12, 1)),
    parseInt(str.substr(13, 1))
  ];
  var res = str.split(/[\.]/);
  if (res != null) {
    if (res[0].length != 3 || res[1].length != 2 || res[2].length != 3 || res[3].length != 3) {
      return false;
    }
  } else {
    return false;
  }
  var gewicht = [5, 4, 3, 2, 7, 6, 5, 4, 3, 2];
  var sum = 0;
  for (var i = 0; i < ziffern.length - 1; i++) {
    sum += ziffern[i] * gewicht[i];
  }
  var pruef = 11 - (sum % 11);
  if (pruef != ziffern[ziffern.length - 1]) {
    return false;
  }
  return true;
}

export function checkNewSsn(str): boolean {
  if (str == null || str == '' || str.length != 16) {
    return false;
  }
  var ziffern = [
    parseInt(str.substr(0, 1)),
    parseInt(str.substr(1, 1)),
    parseInt(str.substr(2, 1)),
    parseInt(str.substr(4, 1)),
    parseInt(str.substr(5, 1)),
    parseInt(str.substr(6, 1)),
    parseInt(str.substr(7, 1)),
    parseInt(str.substr(9, 1)),
    parseInt(str.substr(10, 1)),
    parseInt(str.substr(11, 1)),
    parseInt(str.substr(12, 1)),
    parseInt(str.substr(14, 1)),
    parseInt(str.substr(15, 1))
  ];
  var res = str.split(/[\.]/);
  if (res != null) {
    if (res[0].length != 3 || res[1].length != 4 || res[2].length != 4 || res[3].length != 2) {
      return false;
    }
  } else {
    return false;
  }
  var sum = 0;
  for (var i = 0; i < ziffern.length - 1; i++) {
    if (i % 2 == 0) {
      sum += ziffern[i];
    } else {
      sum += ziffern[i] * 3;
    }
  }
  var pruef = (sum - (sum % 10)) + 10 - sum;
  pruef = (pruef == 10) ? 0 : pruef;
  if (pruef != ziffern[ziffern.length - 1]) {
    return false;
  }
  return true;
}

(window as any).GenerateSSN = () => {
  let str = Math.random().toString();
  let ahv = [7,5,6];
  for(let i = 0; i < 9; i++) {
	ahv.push(parseInt(str[i+2]));
  }

  let pruef = 0;
  for(let i = 0; i < ahv.length; i++) {
	let mult = (i % 2 == 0) ? 1 : 3 ;
	pruef += ahv[i] * mult;
  }

  let last = (Math.ceil(pruef / 10) * 10) - pruef;
  ahv.push(last)

  return "" +ahv[0] + ahv[1] + ahv[2]+ "." + ahv[3] + ahv[4] + ahv[5] + ahv[6]+ "." + ahv[7] + ahv[8] + ahv[9] + ahv[10] + "."+ ahv[11] + ahv[12];
}
