import { i18n } from "../../../locale";
import { getTenantFromUrl, navigateToSimulator } from "../../../utils";
import { testHashVariableLink } from "../../../utils/hygraph";

interface Props {
  boxKey: string;
  callback: (link: string) => void;
  history: any;
  link: string;
}

export const handleSidebarButtonClick = ({
  boxKey,
  callback,
  history,
  link,
}: Props) => {
  const isHashVariableLink = testHashVariableLink(link);
  const isHttpsLink = link.startsWith("https://");
  const isInternalLink = link.startsWith("/");

  if (boxKey === "simulationBox") {
    navigateToSimulator(link);
  }

  if (isHashVariableLink) {
    callback(link);
    return;
  }

  if (isHttpsLink) {
    window.open(link, "_blank", "noopener noreferrer");
    return;
  }

  if (isInternalLink) {
    history.push(`${getTenantFromUrl()}/${i18n.language}${link}`);
    return;
  }

  console.log("link not tracked");
};
