import { LOG_OUT, SET_AUSTRITT_DATA } from "../constants/actions";
import { Action } from "../actions";
import { AustrittData } from "../actions/austritt";

export interface AustrittState {
  austrittData: AustrittData;
}

const getInitialState = () => {
  return {
    austrittData: {
      title: "Neuer Arbeitgeber / Selbstständigkeit / Auswandern",
      text: "Sie treten aus der Pensionskasse aus? Hier erfahren Sie, was Sie unternehmen müssen",
      leavingBenefitBox: {
        label: "aktuelle Austrittsleistung in CHF",
        value: 0,
      },
      paymentInstructionBox: {
        title: "Zahlungsdaten für neue Vorsorgeeinrichtung melden",
        text: "Übermitteln Sie uns die Zahlungsdaten Ihrer neuem Vorsorgeeinrichtung, damit wir Ihre Austrittleistung überweisen können.",
        link: "",
        linkText: "MELDEN",
        isVisible: false,
      },
      reinstatementBox: {
        title: "Weiterführung der Versicherung melden",
        text: "Voraussetzung: Sie sind älter als 58 JAhre und wurden durch den Arbeitgeber gekündigt.",
        link: "",
        linkText: "MELDEN",
        isVisible: false,
      },
      contactBox: {
        title: "Kontakt",
        text: "Gerne beraten wir Sie persönlich.",
        link: "",
        linkText: "KONTAKT AUFNEHMEN",
        isVisible: false,
      },
      leavingActionsBox: {
        title: "Was beim Austritt zu beachten ist",
        text: "Vergessen Sie Ihr Geld nicht! Ob sie dne Arbeitgeber wechseln, auswandern oder sich selbstständig machen: Informieren Sie uns, was mit Ihrem Guthaben geschehen soll.",
        actionsList: [],
        isVisible: false,
      },
      leavingPageContentBox: {
        content: "",
      },
    },
  };
};

const app = (state: AustrittState = getInitialState(), action: Action) => {
  switch (action.type) {
    case SET_AUSTRITT_DATA: {
      return { ...state, austrittData: action.payload };
    }

    case LOG_OUT: {
      console.log("log out");
      return { ...getInitialState() };
    }

    default: {
      return { ...state };
    }
  }
};

export default app;
