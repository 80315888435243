import { LOG_OUT, SET_ZIVILSTAND_DATA } from "../constants/actions";
import { Action } from "../actions";
import { ZivilstandData } from "../actions/zivilstand";

export interface ZivilstandState {
  zivilstandData: ZivilstandData;
}

const getInitialState = () => {
  return {
    zivilstandData: {
      title: "Zivilstand / Konkubinat",
      text: "Ihr Zivilstand resp. Ihre Lebenspartnerschaft (Konkubinat) hat einen Einfluss auf die Leistungen.",
      civilStatusBox: {
        civilStatus: "",
        subTitle: "aktueller Zivilstand",
        isVisible: false,
      },
      actionsBox: {
        title: "Welcher Einfluss hat der Zivilstand?",
        text: "Im Todesfall werden Leistungen an überlebende Partner ausgerichtet. Im Falle einer Scheidung oder gerichtlichen Auflösung einer eingetragenen Partnerschaft wir das Vorsorgeguthaben geteilt.",
        isVisible: false,
        actionList: [],
      },
      simulationBox: {
        title: "Simulation",
        toolTip: "Erklärung zur Simulation als Tooltip",
        text: "Simulieren Sie verschiedene Vorsorgeszenarien und fin den Sie heraus, wie siche dies auf Ihre Finazielle Situation auswirken",
        link: "",
        linkText: "SIMULIEREN STARTEN",
        isVisible: false,
      },
      civilStatusReportBox: {
        title: "Lebenspartnerschaft (Konkubinat melden)",
        text: "Melden Sie eine LEbenspartnerschaft (Konkubinat) an, um im Versicherungsfall gut geschützt zu sein.",
        link: "",
        linkText: "MELDEN",
        isVisible: false,
      },
      fatalityLinkBox: {
        title: "Im Todesfall",
        text: "Entscheiden Sie, wer im Todesfall al begünstige Person eingesetzt wird.",
        link: "",
        linkText: "MEHR DAZU",
        isVisible: false,
      },
      contactBox: {
        title: "Kontakt",
        text: "Gerne beraten wir Sie persönlich.",
        link: "",
        linkText: "KONTAKT AUFNEHMEN",
        isVisible: false,
      },
      civilStatusContentBox: {
        content: "",
      },
    },
  };
};

const app = (state: ZivilstandState = getInitialState(), action: Action) => {
  switch (action.type) {
    case SET_ZIVILSTAND_DATA: {
      return { ...state, zivilstandData: action.payload };
    }

    case LOG_OUT: {
      console.log("log out");
      return { ...getInitialState() };
    }

    default: {
      return { ...state };
    }
  }
};

export default app;
