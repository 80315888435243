import {useEffect, useState} from "react";

interface MousePositionProps {
    recordPosition?: boolean;
}
const useMousePosition = ({recordPosition = true}: MousePositionProps) => {
    const [mousePosition, setMousePosition] = useState({x: null, y: null});

    const updateMousePosition = (ev) => {
        setMousePosition({x: ev.clientX, y: ev.clientY});
    };

    useEffect(() => {
        if (recordPosition) {
            window.addEventListener("mousemove", updateMousePosition);
        } else {
            window.removeEventListener("mousemove", updateMousePosition);
        }

        return () =>
            window.removeEventListener("mousemove", updateMousePosition);
    }, [recordPosition]);

    return mousePosition;
};

export default useMousePosition;
