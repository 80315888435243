import { NotificationBanner } from "@icr-ui/core";
import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { Route, Switch, useLocation, withRouter } from "react-router-dom";
import { Dispatch, bindActionCreators } from "redux";
import { useHistory } from "react-router";

import ReactGA from "react-ga4";
import classNames from "classnames";

import {
  Action,
  checkIfTenantIsValid,
  current,
  globalSettings,
  logOutLocally,
  setI18NLoading,
  setLanguageItemsList,
} from "./actions";
import { getImpersonatedUsers } from "./actions/impersonation";
import { i18n } from "./locale";
import { RootState } from "./reducers";
import {
  getLanguageFromUrl,
  getQueryParameter,
  getTenantFromUrl,
} from "./utils";
import {
  Altersleistung,
  Austritt,
  Dashboard,
  Dokumente,
  Forbidden,
  Fragen,
  Kontakt,
  Login,
  Pensionierung,
  Profile,
  Register,
  Terms,
  Todesfall,
  Urlaub,
  Versicherungen,
  Wohneigentum,
  Zivilstand,
  Zusatzsparen,
} from "./containers";

import Header from "./components/Header";
import Loader from "./components/Loader";
import AuthRoute from "./components/Routes/AuthRoute";

import ImpersonatedAlertBar from "./components/ImpersonatedAlertBar";
import ImpersonatedActionButton from "./components/ImpersonatedActionButton";
import useIsUserAbleToImpersonate from "./hooks/impersonation/useIsUserAbleToImpersonate";
import useHasActiveImpersonation from "./hooks/impersonation/useHasActiveImpersonation";
import useIsDashboardFaqVisible from "./hooks/dashboard/useIsDashboardFaqVisible";
import useIsGetImpersonatedUsersFetched from "./hooks/impersonation/useIsGetImpersonatedUsersFetched";
import "./styles/App.scss";

import { SET_DRAWER_LOADING, localStorageKeys } from "./constants";
import LoaderDrawer from "./components/LoaderDrawer/loader-drawer";
import { toaster } from "./utils/toaster";
import { CustomToasterStyleOverride } from "./utils/toaster/styles";
import {
  DefaultRouteNames,
  RouteURLsByLanguage,
  getRouteByLanguage,
  getRouteByValue,
} from "./routes";
import NotificationLabel from "./components/NotificationLabel";
import { persistCSRFToken, setCSRFToken } from "./utils/api";

interface Props {
  location: any;
  loading: boolean;
  isDrawerLoading: boolean;
  isReady: boolean;
  googleAnalyticsTags: string[];
  i18nLoading: boolean;
  current: any;
  getImpersonatedUsers: () => void;
  currentUser: any;
  globalSettings: any;
  favoriteIcon: string | null;
  dashboardMessageBox: { title: string; text: string; isVisible: boolean };
  dashboardProcessingMessageBox: {
    processingDate: string;
    showProcessingDate: boolean;
  };
}

const App = ({
  favoriteIcon,
  getImpersonatedUsers,
  loading,
  isDrawerLoading,
  isReady,
  googleAnalyticsTags,
  i18nLoading,
  dashboardMessageBox,
  dashboardProcessingMessageBox,
}: Props) => {
  const trackingIds = googleAnalyticsTags?.map((tag) => ({
    trackingId: tag,
  }));
  trackingIds.length > 0 && ReactGA.initialize(trackingIds);

  const dispatch = useDispatch();
  const location = useLocation();
  const { locizeSettings, languageItemsList } = useSelector(
    (state: RootState) => state.app
  );
  const hasActiveImpersonation = useHasActiveImpersonation();
  const isUserAbleToImpersonate = useIsUserAbleToImpersonate();
  const isGetImpersonatedUsersFetched = useIsGetImpersonatedUsersFetched();
  const isDashboardFaqVisible = useIsDashboardFaqVisible();
  const history = useHistory();
  const t = (key: string, defaultValue: string) =>
    i18n.t(key, { ns: "common", defaultValue });

  const [forbidden, setForbidden] = useState(true);

  const showImpersonateActionButton =
    !isDashboardFaqVisible &&
    isUserAbleToImpersonate &&
    isGetImpersonatedUsersFetched;

  const handleImpersonatedMessage = () => {
    const showimpersonatedMessage = localStorage.getItem(
      localStorageKeys.showImpersonatedMessage
    );

    if (showimpersonatedMessage) {
      toaster({
        message: t(
          "validation.success.impersonationSuccess",
          "Profil erfolgreich geändert"
        ),
        timeOut: 8000,
        type: "success",
      });
      localStorage.removeItem(localStorageKeys.showImpersonatedMessage);
    }
  };

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: location.pathname });
  }, [location]);

  useEffect(() => {
    // Load CSRF token from session storage and set it in the meta tag
    const csrfToken = sessionStorage.getItem("csrfToken");
    if (csrfToken) {
      setCSRFToken(csrfToken);
    } else {
      logOutLocally();
    }

    const handleBeforeUnload = () => {
      persistCSRFToken();
    };

    // Add event listener for beforeunload to save CSRF token to session storage
    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      // Remove event listener on cleanup
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    (async () => {
      // get the current url splitted by dashs
      const splittedHref = window.location.href.split("/");
      // get tenant query string
      const oldTenantQueryString = getQueryParameter("tenantName");
      // get the current route application, even if is multiple like: login/sms, or login/email
      let currentRoute =
        splittedHref.length === 7
          ? `/${splittedHref[splittedHref.length - 2]}/${
              splittedHref[splittedHref.length - 1]
            }`
          : `/${splittedHref[splittedHref.length - 1]}`;

      if (currentRoute.includes("access-revoked")) return;
      if (currentRoute.includes("forbidden")) {
        setForbidden(true);
        return;
      } else setForbidden(false);

      if (splittedHref.length < 6) {
        // if url contains just the tenant
        let currentTenant = oldTenantQueryString ?? null;

        if (!currentTenant) {
          if (splittedHref.length === 4) {
            currentTenant = splittedHref[splittedHref.length - 1];
          } else {
            currentTenant =
              currentRoute.length === 1 ||
              currentRoute[currentRoute.length - 1] !== "/"
                ? splittedHref[splittedHref.length - 2].replace("/", "")
                : currentRoute.replace("/", "");
          }
        }

        // case base route
        if (
          currentTenant.includes(window.location.hostname) ||
          currentTenant === ""
        ) {
          window.open(`${window.location.origin}/PKSO/de-CH/login`, "_self");
        } else {
          const isTenantValid = await checkIfTenantIsValid(currentTenant);

          if (isTenantValid?.data?.data) {
            window.open(
              `${
                window.location.origin
              }/${currentTenant}/${checkIfLanguageIsOnHrefString(
                splittedHref
              )}/login`,
              "_self"
            );
          } else {
            window.open(`${window.location.origin}/PKSO/de-CH/login`, "_self");
          }
        }
      } else {
        // get the older query string parameters ex: ?tenantName=PKSO&language=de-CH
        const oldLanguageQueryString = getQueryParameter("language");

        // if the url is not valid and the query string parameters are not present, open default url
        if (
          splittedHref.length < 6 &&
          (!oldLanguageQueryString || !oldTenantQueryString)
        ) {
          window.open(`${window.location.origin}/PKSO/de-CH/login`, "_self");
        }

        // if the url is not valid and the query string parameters are present, open the url with the query string parameters
        if (oldLanguageQueryString && oldTenantQueryString) {
          window.open(
            `${window.location.origin}/${oldTenantQueryString}/${oldLanguageQueryString}/login`,
            "_self"
          );
        } else if (!oldLanguageQueryString && oldTenantQueryString) {
          window.open(
            `${window.location.origin}/${oldTenantQueryString}/de-CH${
              RouteURLsByLanguage[
                `${
                  DefaultRouteNames[getRouteByValue(currentRoute.split("?")[0])]
                }-${i18n.language}`
              ]
            }`,
            "_self"
          );
        } else if (oldLanguageQueryString && !oldTenantQueryString) {
          window.open(
            `${window.location.origin}${
              getTenantFromUrl() || "/PKSO"
            }/${oldLanguageQueryString}${
              RouteURLsByLanguage[
                `${
                  DefaultRouteNames[getRouteByValue(currentRoute.split("?")[0])]
                }-${i18n.language}`
              ]
            }`,
            "_self"
          );
        }

        // check if the url contains any route, or the last dash
        if (
          currentRoute === "/" ||
          (splittedHref.length === 5 && currentRoute.includes("-"))
        ) {
          window.open(
            `${
              window.location.href.endsWith("/")
                ? window.location.href
                : window.location.href + "/"
            }login`,
            "_self"
          );
          return;
        }

        // TODO: remove this if after remove tenant param from backend
        if (
          currentRoute.includes("sessionid=") &&
          getQueryParameter("tenantName")
        ) {
          const urlWithoutTenant = `${window.location.href.replace(
            `&tenantName=${getQueryParameter("tenantName")}`,
            ""
          )}`;
          window.open(urlWithoutTenant, "_self");
        }

        // if have login query strings
        const splittedLoginQueryStrings = currentRoute.split("?");
        currentRoute = splittedLoginQueryStrings[0];

        // if every needed information to build url based on language and tenant is valid, rebuild the url
        if (
          i18n.language &&
          getLanguageFromUrl() &&
          (i18n.language !== getLanguageFromUrl() ||
            RouteURLsByLanguage[
              `${DefaultRouteNames[getRouteByValue(currentRoute)]}-${
                i18n.language
              }`
            ] !== currentRoute)
        ) {
          window.open(
            window.location.href
              .replace(getLanguageFromUrl(), i18n.language)
              .replace(
                currentRoute,
                RouteURLsByLanguage[
                  `${DefaultRouteNames[getRouteByValue(currentRoute)]}-${
                    i18n.language
                  }`
                ]
              ),
            "_self"
          );
        }
      }
    })();
  }, [i18n.language, window.location.href]);

  const checkIfLanguageIsOnHrefString = (splittedHref: string[]) => {
    const foundLanguage = splittedHref.find((href) =>
      ["de-CH", "fr-FR"].includes(href)
    );

    return foundLanguage ?? "de-CH";
  };

  useEffect(() => {
    if (
      i18n.isInitialized &&
      getLanguageFromUrl() &&
      i18n.language !== getLanguageFromUrl()
    ) {
      console.clear();
      setI18NLoading(true);
      i18n.changeLanguage(getLanguageFromUrl());
    }
  }, [i18n.isInitialized, i18n.language]);

  const openDocumentAndCloseLoaderDrawer = () => {
    dispatch({
      type: SET_DRAWER_LOADING,
      paylod: false,
    });
    history.push(getRouteByLanguage(DefaultRouteNames.DOCUMENTS));
  };

  useEffect(() => {
    if (!locizeSettings) {
      dispatch(setI18NLoading(true));
    }
  }, [locizeSettings]);

  useEffect(() => {
    i18n.on("languageChanged", () => {
      dispatch(setI18NLoading(false));
    });
    i18n.on("initialized", async () => {
      const data = await i18n.services.backendConnector.backend.getLanguages();
      dispatch(setLanguageItemsList(data));
      dispatch(setI18NLoading(false));
    });
  }, [i18n.language, i18n.isInitialized]);

  useEffect(() => {
    handleImpersonatedMessage();
  }, []);

  useEffect(() => {
    if (isUserAbleToImpersonate) {
      getImpersonatedUsers();
    }
  }, [isUserAbleToImpersonate]);

  useEffect(() => {
    const fi = document.getElementById("favicon") as any;
    fi.href = favoriteIcon;
  }, [favoriteIcon]);

  useEffect(() => {
    if (loading || i18nLoading) {
      document.body.classList.add("loading");
    } else {
      document.body.classList.remove("loading");
    }
  }, [languageItemsList, locizeSettings, loading, i18nLoading]);

  const contentClasses = classNames("content-wrapper", {
    "has-active-impersonation": hasActiveImpersonation,
  });

  return (
    <div className="main-container">
      {(loading || i18nLoading) && <Loader />}
      <div className="main-content">
        <Switch>
          <Route
            path={`${getTenantFromUrl()}/${i18n.language}/forbidden`}
            component={Forbidden}
          />
          <Route
            path={`${getTenantFromUrl()}/${i18n.language}/login`}
            component={Login}
          />
          <Route
            path={getRouteByLanguage(DefaultRouteNames.REGISTER)}
            component={Register}
          />
          <Route
            render={() => (
              <React.Fragment>
                {hasActiveImpersonation && <ImpersonatedAlertBar />}
                {isDrawerLoading && (
                  <LoaderDrawer
                    label={
                      isReady
                        ? t(
                            "DocumentArrivalConfirmation",
                            "Ihr Dokument ist jetzt angekommen"
                          )
                        : t(
                            "DocumentGenerationInProgress",
                            "Ihr Dokument wird generiert. Dies kann einige Minuten dauern"
                          )
                    }
                    buttonProps={
                      !isReady
                        ? undefined
                        : {
                            label: i18n.t("buttons.open", {
                              ns: "app",
                              defaultValue: "ÖFFNEN",
                            }),
                            onClick: () => openDocumentAndCloseLoaderDrawer(),
                          }
                    }
                  />
                )}
                <Header />
                <div className={contentClasses}>
                  {showImpersonateActionButton && <ImpersonatedActionButton />}
                  {dashboardProcessingMessageBox?.showProcessingDate && (
                    <NotificationLabel
                      processingDate={
                        dashboardProcessingMessageBox.processingDate
                      }
                    />
                  )}
                  {dashboardMessageBox?.isVisible && (
                    <NotificationBanner
                      title={dashboardMessageBox?.title}
                      description={dashboardMessageBox?.text}
                      customStyles={{
                        minHeight: "96px",
                        marginBottom: "48px",
                        gap: "16px",
                        padding: "24px",
                        height: "auto",
                        flexWrap: "nowrap",

                        "&>div:nth-child(2)": {
                          gap: "16px",
                        },
                        "@media only screen and (max-width: 883px)": {
                          marginBottom: "24px",
                        },
                      }}
                    />
                  )}
                  <Switch>
                    <AuthRoute
                      path={getRouteByLanguage(DefaultRouteNames.DASHBOARD)}
                    >
                      <Dashboard />
                    </AuthRoute>
                    <AuthRoute
                      path={getRouteByLanguage(DefaultRouteNames.PROFILE)}
                    >
                      <Profile />
                    </AuthRoute>
                    <AuthRoute
                      path={getRouteByLanguage(DefaultRouteNames.TERMS)}
                    >
                      <Terms />
                    </AuthRoute>
                    <AuthRoute
                      path={getRouteByLanguage(DefaultRouteNames.INSURANCE)}
                    >
                      <Versicherungen />
                    </AuthRoute>
                    <AuthRoute
                      path={getRouteByLanguage(DefaultRouteNames.RETIREMENT)}
                    >
                      <Pensionierung />
                    </AuthRoute>
                    <AuthRoute
                      path={getRouteByLanguage(DefaultRouteNames.HOMEOWNERSHIP)}
                    >
                      <Wohneigentum />
                    </AuthRoute>
                    <AuthRoute
                      path={getRouteByLanguage(DefaultRouteNames.BENEFITS)}
                    >
                      <Altersleistung />
                    </AuthRoute>
                    <AuthRoute
                      path={getRouteByLanguage(
                        DefaultRouteNames.ADDITIONALSAVINGS
                      )}
                    >
                      <Zusatzsparen />
                    </AuthRoute>
                    <AuthRoute
                      path={getRouteByLanguage(DefaultRouteNames.CIVILSTATUS)}
                    >
                      <Zivilstand />
                    </AuthRoute>
                    <AuthRoute
                      path={getRouteByLanguage(DefaultRouteNames.DEATH)}
                    >
                      <Todesfall />
                    </AuthRoute>
                    <AuthRoute
                      path={getRouteByLanguage(DefaultRouteNames.EXIT)}
                    >
                      <Austritt />
                    </AuthRoute>
                    <AuthRoute
                      path={getRouteByLanguage(DefaultRouteNames.DOCUMENTS)}
                    >
                      <Dokumente />
                    </AuthRoute>
                    <AuthRoute
                      path={getRouteByLanguage(DefaultRouteNames.CONTACT)}
                    >
                      <Kontakt />
                    </AuthRoute>
                    <AuthRoute
                      path={getRouteByLanguage(DefaultRouteNames.VACATION)}
                    >
                      <Urlaub />
                    </AuthRoute>
                    <AuthRoute
                      path={getRouteByLanguage(DefaultRouteNames.QUESTIONS)}
                    >
                      <Fragen />
                    </AuthRoute>
                  </Switch>
                </div>
              </React.Fragment>
            )}
          />
        </Switch>
      </div>
      <CustomToasterStyleOverride
        $hasActiveImpersonation={hasActiveImpersonation}
      />
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  ...state.app,
  loading: state.app.loading,
  i18nLoading: state.app.i18nLoading,
  currentUser: state.app.currentUser,
  favoriteIcon: state.app.favoriteIcon,
  googleAnalyticsTags: state.app.googleAnalyticsTags,
  isDrawerLoading: state.app.isDrawerLoading,
  isReady: state.document.documentData.isReady,
  dashboardMessageBox: state.dashboard.dashboardData.messageBox,
  dashboardProcessingMessageBox:
    state.dashboard.dashboardData.processingMessageBox,
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  current: bindActionCreators(current, dispatch),
  getImpersonatedUsers: bindActionCreators(getImpersonatedUsers, dispatch),
  globalSettings: bindActionCreators(globalSettings, dispatch),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
export default withRouter(connector(App));
