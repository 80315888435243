import { useEffect } from "react";
import { useDispatch } from "react-redux";

import { getTenantFromUrl } from "../utils";
import { globalSettings } from "../actions";

const defaultTenant = process.env.REACT_APP_DEFAULT_TENANT || "PKSO";

const GlobalSettingsProvider = ({ children }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (window.location.href.includes("forbidden")) return;

    let tenant = getTenantFromUrl().replace("/", "");

    if (tenant) {
      window.localStorage.setItem("tenantName", tenant);
    } else {
      tenant = window.localStorage.getItem("tenantName");
    }

    dispatch(globalSettings(tenant || defaultTenant));
  }, [dispatch]);

  return children;
};

export default GlobalSettingsProvider;
