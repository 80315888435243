import { Button, ButtonColors, ButtonTypes } from "icr-shared-components";
import { useEffect } from "react";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { bindActionCreators, Dispatch } from "redux";

import { Action, setLoading } from "../../actions";
import {
  getPensionierungData,
  PensionierungData,
} from "../../actions/pensionierung";
import { ReactComponent as Arrow } from "../../assets/icons/arrow-right.svg";
import { ReactComponent as SparenIcon } from "../../assets/icons/Desktop/sparen-icon.svg";
import Card, { Type } from "../../components/Card";
import { Top } from "../../components/Card/Content/Top";
import BarChart from "../../components/Chart/BarChart";
import { FinanceBox } from "../../components/sidebar";
import { RootState } from "../../reducers";
import { navigateToSimulator } from "../../utils";
import { formatMoney } from "../../utils/currency";
import "../Versicherungen/Versicherungen.scss";
import { useTranslation } from "react-i18next";
import { cmsSchemas } from "../../constants";
import CustomRichText from "../../components/RichText";
import { getRouteByLanguage, DefaultRouteNames } from "../../routes";
import { useGetCMSContent, useGetSidebarCMSContent } from "../../hooks/cms";
import { HygraphSidebarComponents } from "../../components/sidebar/HygraphSidebarComponents";

interface Props extends RouteComponentProps {
  getPensionierungData: any;
  setLoading: any;
  pensionierungData: PensionierungData;
}

const Pensionierung = (props: Props) => {
  const schema = cmsSchemas.retirement.plural;
  const { content: cmsContent } = useGetCMSContent({ schema });
  const { content: cmsSidebarContent } = useGetSidebarCMSContent({ schema });
  const sidebarBoxes = cmsSidebarContent?.sidebar;

  const getInitialData = async () => {
    try {
      props.setLoading(true);
      await props.getPensionierungData();
      props.setLoading(false);
    } catch (error) {
      console.error("error in getting austritt page initial data", error);
    }
  };

  const { t: translate } = useTranslation(["retirementPage", "app"]);
  const t = (key: string, defaultValue: string) =>
    translate(key, { ns: "retirementPage", defaultValue });

  useEffect(() => {
    getInitialData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const data = props.pensionierungData;

  const reactManagedSidebarComponents = {
    financeExpertBox: <FinanceBox {...{ ...data.financeExpertBox }} />,
  };

  return (
    <div className="pensionierung-page">
      <div className="page-top">
        <Button
          type={ButtonTypes.Text}
          color={ButtonColors.Action}
          onClick={() =>
            props.history.push(getRouteByLanguage(DefaultRouteNames.DASHBOARD))
          }
        >
          <Arrow className="rotate-180" />{" "}
          <span className="ml-16">
            {translate("buttons.backToDashboard", {
              ns: "app",
              defaultValue: "Zurück zum dashboard",
            })}
          </span>
        </Button>
      </div>

      <h1 className="mb-16">{t("title", "Pensionierung")}</h1>

      <p className="text-large mb-48 max-w-lg-100 max-w-xl-75 max-w-50">
        {t(
          "text",
          "Teilpensionierung? Frühpensionierung? Kapitalbezug oder Rente? Hier finden Sie die Antworten und die Auswirkungen der unterschiedlichen Varianten."
        )}
      </p>

      <div className="pensionierung-page-content two-column-layout">
        <div className="left">
          {data.retirementBox && data.retirementBox.isVisible && (
            <Card className="mb-48" type={Type.Standard}>
              <Top
                className="mb-58"
                title={formatMoney(data.retirementBox.amount)}
                icon={<SparenIcon />}
                text={t(
                  "retirementBox.subTitle",
                  "Voraussichtliche Monatsrente in CHF"
                )}
              />

              <div>
                <BarChart
                  data={data.retirementBox.projectedGraph}
                  defaultRetirementAge={data.retirementBox.defaultRetirementAge}
                />
              </div>

              <Button
                type={ButtonTypes.Text}
                color={ButtonColors.Action}
                onClick={() => navigateToSimulator(data.retirementBox.link)}
              >
                {translate("buttons.simulateNow", {
                  ns: "app",
                  defaultValue: "Jetzt simulieren",
                })}
                <Arrow className="ml-16" />
              </Button>
            </Card>
          )}

          {cmsContent?.main && (
            <Card type={Type.Standard}>
              <CustomRichText
                content={cmsContent?.main}
                simulationBox={data.simulationBox}
              />
            </Card>
          )}
        </div>

        <div className="right">
          <Card>
            {sidebarBoxes && (
              <HygraphSidebarComponents
                boxes={sidebarBoxes}
                data={data}
                reactManagedSidebarComponents={reactManagedSidebarComponents}
              />
            )}
          </Card>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  pensionierungData: state.pensionierung.pensionierungData,
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  getPensionierungData: bindActionCreators(getPensionierungData, dispatch),
  setLoading: (val) => dispatch(setLoading(val)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default withRouter(connector(Pensionierung));
