import React, { FunctionComponent, useState } from "react";
import { RootState } from "../../../reducers";
import { Dispatch } from "redux";
import { Action } from "../../../actions";
import { connect } from "react-redux";
import {
  Button,
  ButtonColors,
  ButtonTypes,
  Input,
} from "icr-shared-components";
import { useHistory } from "react-router";
import { setLastname, setName } from "../../../actions/register";
import { ButtonStyles } from "icr-shared-components/dist";
import { useValidateFrom } from "../../../utils/useValidateFrom";
import { formConfig } from "./formConfig";
import { useTranslation } from "react-i18next";
import { DefaultRouteNames, getRouteByLanguage } from "../../../routes";

interface Props {
  name: string;
  lastname: string;
  setName: (value: string) => void;
  setLastname: (value: string) => void;
}

const Name: FunctionComponent<Props> = ({
  name,
  lastname,
  setName,
  setLastname,
}) => {
  const { t: translate } = useTranslation(["register", "app"]);
  const t = (key: string, defaultValue: string) =>
    translate(key, { ns: "register", defaultValue });
  const [isBlurred, setIsBlurred] = useState({ name: false, lastname: false });
  const history = useHistory();

  const next = () => {
    history.push(getRouteByLanguage(DefaultRouteNames.REGISTERBIRTHDAY));
  };

  const back = () => {
    history.push(getRouteByLanguage(DefaultRouteNames.REGISTERSTART));
  };

  const validation = useValidateFrom("register", formConfig, {
    name,
    lastname,
  });

  return (
    <div className="register-start-page">
      <div className="row">
        <div className="col-sm-12 col-md-6">
          <Input
            id="registerNameInput"
            type="text"
            name="registerNameInput"
            value={name}
            onChange={(name, value) => setName(value)}
            onBlur={() => setIsBlurred((pre) => ({ ...pre, name: true }))}
            placeholder={t(
              "personalInfo.firstName.placeholder",
              "Vorname eingeben"
            )}
            inputWrapperClass="mb-48"
            validationMessageConfig={
              (isBlurred.name ||
                validation?.name?.validation?.type === "success") && {
                config: validation.name.validation,
                visible: true,
              }
            }
          >
            {t("personalInfo.firstName.label", "Vorname")}
          </Input>
        </div>

        <div className="col-sm-12 col-md-6">
          <Input
            id="registerLastnameInput"
            type="text"
            name="registerLastnameInput"
            value={lastname}
            onChange={(name, value) => setLastname(value)}
            onBlur={() => setIsBlurred((pre) => ({ ...pre, lastname: true }))}
            placeholder={t("personalInfo.surName.placeholder", "Name eingeben")}
            inputWrapperClass="mb-48"
            validationMessageConfig={
              (isBlurred.lastname ||
                validation?.lastname?.validation?.type === "success") && {
                config: validation.lastname.validation,
                visible: true,
              }
            }
          >
            {t("personalInfo.surName.label", "Name")}
          </Input>
        </div>
      </div>

      <div className="modal-buttons">
        <Button
          id="registerBackButton"
          onClick={() => back()}
          type={ButtonTypes.Text}
          color={ButtonColors.Action}
        >
          {translate("buttons.goBack", { ns: "app", defaultValue: "Zurück" })}
        </Button>

        <Button
          id="registerNextButton"
          isDisabled={
            validation?.lastname?.validation?.type !== "success" ||
            validation?.name?.validation?.type !== "success"
          }
          type={ButtonTypes.Standard}
          style={ButtonStyles.Primary}
          color={ButtonColors.Action}
          onClick={() => next()}
        >
          {translate("buttons.next", { ns: "app", defaultValue: "Weiter" })}
        </Button>
      </div>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  name: state.app.name,
  lastname: state.app.lastname,
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  setName: (value) => dispatch(setName(value)),
  setLastname: (value) => dispatch(setLastname(value)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(Name);
