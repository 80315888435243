import {
  Button,
  ButtonColors,
  ButtonSizes,
  ButtonStyles,
  ButtonTypes,
  ExpandableRow,
} from "icr-shared-components";
import { RegularRetirementBox as DataType } from "../../actions/versicherungen";
import { formatMoney } from "../../utils/currency";
import Hint from "../Hint";
import { useTranslation } from "react-i18next";

interface RegularRetirementBoxProps {
  data: DataType;
}

export default function RegularRetirementBox({
  data,
}: RegularRetirementBoxProps) {
  const { t: translate } = useTranslation(["creditBalancePage", "common"]);
  const t = (key: string, defaultValue: string) =>
    translate(key, { ns: "creditBalancePage", defaultValue });

  return data.isVisible ? (
    <ExpandableRow
      expandable
      className="mt-24"
      content={
        <div className="expandable-row-content">
          <p className="regular-retirement-box-title">
            <b>
              {t(
                "regularRetirementBox.title",
                "Voraussichtliche Altersleistung ab 65. Altersjahr"
              )}
            </b>
            <br className="mobile-line-break" />
            <span className="expended-more-box-subtitle">
              {t("regularRetirementBox.subTitle", "mit 2 % Zins pro Jahr")}
            </span>
          </p>
          <p>
            <b>{formatMoney(data.annuityDetailsObject.annuityPerYear)}</b>
          </p>
        </div>
      }
      more={
        <div>
          <div className="expended-more-box">
            <p>
              <b>
                {t(
                  "regularRetirementBox.annuityDetailsObject.capitalGroupLabel",
                  "Kapital"
                )}
              </b>
            </p>

            <div className="expended-more-box-row">
              <div>
                {t(
                  "regularRetirementBox.annuityDetailsObject.capitalLabel",
                  "voraussichtliches Kapital"
                )}
                <Hint
                  text={t(
                    "regularRetirementBox.annuityDetailsObject.capitalHelpText",
                    "Das ist ein Hilfetext zum voraussichtlichen Kapital"
                  )}
                />
              </div>
              <div className="mt-16-tablet">
                {formatMoney(data.annuityDetailsObject.capital)}
              </div>
            </div>
            <div className="expended-more-box-row">
              <div>
                {t(
                  "regularRetirementBox.annuityDetailsObject.conversionRateLabel",
                  "Umwandlungssatz"
                )}
                <Hint
                  text={t(
                    "regularRetirementBox.annuityDetailsObject.conversionRateHelpText",
                    "Das ist ein Hilfetext zum voraussichtlichen Kapital"
                  )}
                />
              </div>
              <div>{data.annuityDetailsObject.conversionRate}</div>
            </div>
          </div>
          <hr className="divider" />
          <div className="expended-more-box">
            <p>
              <b>
                {t(
                  "regularRetirementBox.annuityDetailsObject.annuityGroupLabel",
                  "Altersrente"
                )}
              </b>
            </p>
            <div className="expended-more-box-row tablet-hidden">
              <div />
              <div>
                <div className="expended-more-table-header">
                  {translate("byMonth", {
                    ns: "common",
                    defaultValue: "pro Monat",
                  })}
                </div>
                <div className="expended-more-table-header">
                  {translate("byYear", {
                    ns: "common",
                    defaultValue: "pro Jahr",
                  })}
                </div>
              </div>
            </div>
            <div className="expended-more-box-row">
              <div>
                {t(
                  "regularRetirementBox.annuityDetailsObject.annuityLabel",
                  "voraussichtliche Altersrente"
                )}
              </div>
              <div>
                <div className="mr-12-tablet">
                  <div className="expended-more-table-header table-visible">
                    {translate("byMonth", {
                      ns: "common",
                      defaultValue: "pro Monat",
                    })}
                  </div>
                  {formatMoney(data.annuityDetailsObject.annuityPerMonth)}
                </div>
                <div className="flex-column">
                  <div className="expended-more-table-header table-visible">
                    {translate("byYear", {
                      ns: "common",
                      defaultValue: "pro Jahr",
                    })}
                  </div>
                  {formatMoney(data.annuityDetailsObject.annuityPerYear)}
                </div>
              </div>
            </div>
          </div>
          <hr className="divider" />
          <div className="expended-more-buttons-wrapper">
            {/* TODO: apply approach to translations */}
            {/* <p>
              Disclaimer Lorem ipsum dolor sit amet, consectetuer adipiscing
              elit. Aenean commodo ligula eget dolor. <br /> Aenean massa. Cum
              sociis natoque penatibus et magnis dis parturient montes, nascetur
              ridiculus mus.
              <br /> Donec quam felis, ultricies nec, pellentesque eu, pretium
              quis, sem.
            </p> */}

            <div className="pensionierung-button-wrapper mt-4">
              <div>
                <Button
                  type={ButtonTypes.Standard}
                  color={ButtonColors.Action}
                  style={ButtonStyles.Primary}
                  size={ButtonSizes.Full}
                  onClick={() => console.log("NEXT")}
                >
                  {t(
                    "annuityGroupBox.button.simulateRetirement",
                    "Pensionierung simulieren"
                  )}
                </Button>
              </div>
            </div>
          </div>
        </div>
      }
    />
  ) : (
    <></>
  );
}
