import React, { Component } from "react";
import "./Nutzungsbedingungen.scss";
import Card, { Type } from "../../components/Card";
import {
  Input,
  Button,
  ButtonColors,
  ButtonSizes,
  ButtonTypes,
} from "icr-shared-components";
import { ReactComponent as Arrow } from "../../assets/icons/arrow-right.svg";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { getRouteByLanguage, DefaultRouteNames } from "../../routes";

interface Props {
  history: any;
}

interface State {}

class Nutzungsbedingungen extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div className="nutzungsbedingungen-page">
        <div className="page-top">
          <Button
            type={ButtonTypes.Text}
            color={ButtonColors.Action}
            onClick={() =>
              this.props.history.push(
                getRouteByLanguage(DefaultRouteNames.DASHBOARD)
              )
            }
          >
            <Arrow className="rotate-180" />{" "}
            <span className="ml-16">Zurück</span>
          </Button>
        </div>
        <div className="nutzungsbedingungen-page-content">
          <h1 className="mb-48">Nutzungsbedingungen</h1>
          <Card type={Type.Standard}>
            <h4 className="mb-16">Lorem ipsum</h4>
            <p className="mb-48">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean
              euismod bibendum laoreet. Proin gravida dolor sit amet lacus
              accumsan et viverra justo commodo. Proin sodales pulvinar sic
              tempor. Sociis natoque penatibus et magnis dis parturient montes,
              nascetur ridiculus mus. Nam fermentum, nulla luctus pharetra
              vulputate, felis tellus mollis orci, sed rhoncus pronin sapien
              nunc accuan eget. <br /> <br />
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean
              euismod bibendum laoreet. Proin gravida dolor sit amet lacus
              accumsan et viverra justo commodo. Proin sodales pulvinar sic
              tempor. Sociis natoque penatibus et magnis dis parturient montes,
              nascetur ridiculus mus. Nam fermentum, nulla luctus pharetra
              vulputate, felis tellus mollis orci, sed rhoncus pronin sapien
              nunc accuan eget.
            </p>

            <h4 className="mb-16">Lorem ipsum</h4>
            <p className="mb-48">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean
              euismod bibendum laoreet. Proin gravida dolor sit amet lacus
              accumsan et viverra justo commodo. Proin sodales pulvinar sic
              tempor. Sociis natoque penatibus et magnis dis parturient montes,
              nascetur ridiculus mus. Nam fermentum, nulla luctus pharetra
              vulputate, felis tellus mollis orci, sed rhoncus pronin sapien
              nunc accuan eget.
            </p>

            <h4 className="mb-16">Lorem ipsum</h4>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean
              euismod bibendum laoreet. Proin gravida dolor sit amet lacus
              accumsan et viverra justo commodo. Proin sodales pulvinar sic
              tempor. Sociis natoque penatibus et magnis dis parturient montes,
              nascetur ridiculus mus. Nam fermentum, nulla luctus pharetra
              vulputate, felis tellus mollis orci, sed rhoncus pronin sapien
              nunc accuan eget. <br /> <br />
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean
              euismod bibendum laoreet. Proin gravida dolor sit amet lacus
              accumsan et viverra justo commodo. Proin sodales pulvinar sic
              tempor. Sociis natoque penatibus et magnis dis parturient montes,
              nascetur ridiculus mus. Nam fermentum, nulla luctus pharetra
              vulputate, felis tellus mollis orci, sed rhoncus pronin sapien
              nunc accuan eget. <br /> <br />
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean
              euismod bibendum laoreet. Proin gravida dolor sit amet lacus
              accumsan et viverra justo commodo. Proin sodales pulvinar sic
              tempor. Sociis natoque penatibus et magnis dis parturient montes,
              nascetur ridiculus mus. Nam fermentum, nulla luctus pharetra
              vulputate, felis tellus mollis orci, sed rhoncus pronin sapien
              nunc accuan eget.
            </p>
          </Card>
        </div>
      </div>
    );
  }
}

export default withRouter(connect(null, null)(Nutzungsbedingungen));
