import { LOG_OUT, SET_URLAUB_DATA } from "../constants/actions";
import { Action } from "../actions";
import { UrlaublData } from "../actions/urlaub";

export interface UrlaubState {
  urlaubData: UrlaublData;
}

const getInitialState = () => {
  return {
    urlaubData: {
      title: "Unbezahlter Urlaub",
      text: "Planen Sie einen unbezahlten Urlaub? Hier erfahren Sie mehr über Ihre Möglichkeiten, die Pensionskasse in dieser ZEit weiterzuführen.",
      unpaidVacationBox: {
        amount: 0,
        subTitle: "mögliche Finanzierungen",
        isVisible: false,
      },
      actionsBox: {
        title: "Welche Möglichkeiten gibt es?",
        text: "Sie haben die Wahl zwischen drei Varianten: Die Versicherung läuft wie bisher weiter, nur die Risikoversicherung (Invalidität und Tod) wird weitergeführt oder die Versicherung ruht während Ihres Urlaubs.",
        isVisible: false,
        actionList: [],
      },
      optionsBox: {
        isVisible: false,
        title: "Option wählen",
        text: "Wenden Sie sich an die Personalabteilung Ihres Arbeitgebers. Er wird die entsprechenden Meldungen tätigen.",
      },
      contactBox: {
        title: "Kontakt",
        text: "Gerne beraten wir Sie persönlich.",
        link: "",
        linkText: "KONTAKT AUFNEHMEN",
        isVisible: false,
      },
      unpaidVacationPageContentBox: {
        content: "",
      },
    },
  };
};

const app = (state: UrlaubState = getInitialState(), action: Action) => {
  switch (action.type) {
    case SET_URLAUB_DATA: {
      return { ...state, urlaubData: action.payload };
    }

    case LOG_OUT: {
      console.log("log out");
      return { ...getInitialState() };
    }

    default: {
      return { ...state };
    }
  }
};

export default app;
