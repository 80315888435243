import { LOG_OUT, SET_ALTERSLEISTUNG_DATA } from "../constants/actions";
import { Action } from "../actions";
import { AltersleistungData } from "../actions/altersleistung";
import { financeExpertBox } from "../constants/mockups";

export interface AltersleistungState {
  altersleistungData: AltersleistungData;
}

const getInitialState = () => {
  return {
    altersleistungData: {
      title: "Altersleistungen erhöhen und Steuern sparen", //page.title
      text: "Sie haben verschiedene Möglichkeiten, Ihre Altersleistung selber zu erhöhen. Erfahren Sie mehr dazu und simulieren Sie den Einfluss auf Ihre Altersleistung",
      buyInOverviewBox: {
        purchaseBox: {
          homeOwnershipBalance: 0,
          maxPurchaseAmount: 0,
          subTitle: "Aktuell maximal möglicher Einkauf in CHF",
          maxBalanceAmount: 0,
          balanceMaxRatio: 0,
          currentCreditBalance: 0,
          tooltipLabel: "",
          tooltipSubtitle: "",
          tooltipTitle: "",
          isVisible: false,
        },
        link: "",
        linkText: "JETZT SIMULIEREN", //buttons.simulateNow
      },
      buyInActionsBox: {
        title: "Welche Optionen stehen mir zur Verfügung?",
        text: "Nebst dem Kapitaleinkauf haben Sie auch die Möglichkeit, die Spareinträge freiwillig zu erhöhen.",
        actionList: [],
        isVisible: false,
      },
      simulationBox: {
        title: "Simulation",
        toolTip: "Erklärung zur Simulation als Tooltip",
        text: "Simulieren Sie verschiedene Vorsorgeszenarien und fin den Sie heraus, wie siche dies auf Ihre Finazielle Situation auswirken",
        link: "",
        linkText: "SIMULIEREN STARTEN",
        isVisible: false,
      },
      buyInActionsShortBox: {
        title: "Jetzt aktiv werden",
        text: "Werden Sie jetzt aktiv und erhöhen Sie einfach Ihre Altersleistungen",
        isVisible: false,
        actionsList: [],
      },
      contactBox: {
        title: "Kontakt",
        text: "Gerne beraten wir Sie persönlich.",
        link: "",
        linkText: "KONTAKT AUFNEHMEN",
        isVisible: false,
      },
      financeExpertBox,
      buyInPageContentBox: {
        content: "",
      },
    },
  };
};

const app = (
  state: AltersleistungState = getInitialState(),
  action: Action
) => {
  switch (action.type) {
    case SET_ALTERSLEISTUNG_DATA: {
      return { ...state, altersleistungData: action.payload };
    }

    case LOG_OUT: {
      console.log("log out");
      return { ...getInitialState() };
    }

    default: {
      return { ...state };
    }
  }
};

export default app;
