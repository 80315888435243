import { Provider as ReduxProvider } from "react-redux";
import { GlobalStyles, theme } from "icr-shared-components";
import { I18nextProvider } from "react-i18next";
import { ThemeProvider } from "@emotion/react";
import ReduxToastr from "react-redux-toastr";

import i18n from "../locale/i18n";
import store from "../store";
import GlobalSettingsProvider from "./GlobalSettingsProvider";
import UrqlProvider from "./UrqlProvider";

const toastrOptions = {
  timeOut: 4000,
  newestOnTop: false,
  preventDuplicates: true,
  position: "top-right",
  getState: (state) => state.toastr,
  transitionIn: "fadeIn",
  transitionOut: "fadeOut",
  progressBar: true,
  closeOnToastrClick: true,
};

const AppProvider = ({ children }) => {
  return (
    <I18nextProvider i18n={i18n}>
      <ReduxProvider store={store}>
        <GlobalSettingsProvider>
          <UrqlProvider>
            <ThemeProvider theme={theme}>
              <GlobalStyles />
              {children}
              <ReduxToastr {...toastrOptions} />
            </ThemeProvider>
          </UrqlProvider>
        </GlobalSettingsProvider>
      </ReduxProvider>
    </I18nextProvider>
  );
};

export default AppProvider;
