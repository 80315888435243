import {
  Button,
  ButtonColors,
  ButtonTypes,
  Input,
} from "icr-shared-components";
import { ButtonStyles } from "icr-shared-components/dist";
import React, { FunctionComponent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import { Dispatch, bindActionCreators } from "redux";
import { Action, setLoading } from "../../../actions";
import { login, patchLogin, setSmsCode } from "../../../actions/login";
import { resendSms } from "../../../actions/register";
import { RootState } from "../../../reducers";
import { useValidateFrom } from "../../../utils/useValidateFrom";
import "./Sms.scss";
import { formConfig } from "./formConfig";
import { DefaultRouteNames, getRouteByLanguage } from "../../../routes";
import { i18n } from "../../../locale";
import { getTenantFromUrl } from "../../../utils";
import { toaster } from "../../../utils/toaster";

interface Props {
  smsCode: string;
  setSmsCode: any;
  sessionId: string;
  patchLogin: any;
  resendSms: any;
  setLoading: any;
  login: any;
  phoneNumber: string;
  loginPhoneNumber: string;
}

const Sms: FunctionComponent<Props> = ({
  smsCode,
  setSmsCode,
  patchLogin,
  sessionId,
  login,
  loginPhoneNumber,
  resendSms,
  setLoading,
}) => {
  const [isBlurred, setIsBlurred] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const { t: translate } = useTranslation(["auth", "app"]);
  const t = (key: string, defaultValue: string) =>
    translate(key, { ns: "auth", defaultValue });
  const history = useHistory();

  useEffect(() => {
    if (!sessionId || !loginPhoneNumber) {
      history.push(`${getTenantFromUrl()}/${i18n.language}/login`);
    }
  }, []);

  const next = async () => {
    setLoading(true);
    try {
      await patchLogin({ smsCode: smsCode }, sessionId).then(
        (res) => {
          login(res.data.data);
          history.push(getRouteByLanguage(DefaultRouteNames.DASHBOARD));
        },
        (err) => {
          const error = err.response?.data?.errors[0];
          if (error && error.detail) {
            toaster({
              message: error.detail,
              type: "error",
            });
          }
        }
      );
      setDisableButton(false);
      setLoading(false);
    } catch (error) {
      setDisableButton(false);
      setLoading(false);
      console.error("error in registering", error);
    }
  };

  const resend = async () => {
    setLoading(true);
    try {
      await resendSms(sessionId);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("error in registering", error);
    }
  };

  const validation = useValidateFrom("login", formConfig, { smsCode });

  return (
    <React.Fragment>
      <form onSubmit={(e) => e.preventDefault()}>
        <h1 className="mb-40">{t("login.sms.title", "SMS Code")}</h1>

        <p className="mb-24">
          {t("login.sms.smsSent", "Sie erhalten ein SMS auf die Nummer")}
          <br />
          {loginPhoneNumber}
        </p>

        <p className="mb-32">
          {t(
            "login.sms.enterCode",
            "Geben Sie hier bitte Ihren Zugangscode ein."
          )}
        </p>

        <Input
          id="loginSmsInput"
          type="text"
          name="email"
          isFocused
          value={smsCode}
          onChange={(name, value) => setSmsCode(value)}
          onBlur={() => setIsBlurred(true)}
          placeholder={t("login.sms.code.placeholder", "SMS Code")}
          inputWrapperClass="mb-24"
          validationMessageConfig={
            (isBlurred ||
              validation?.smsCode?.validation?.type === "success") &&
            validation.smsCode.validation
          }
        >
          {t("login.sms.code.label", "SMS Code")}
        </Input>

        <h4 className="mb-16 mt-16">
          {" "}
          {t("login.sms.noSMSReceived", "Kein SMS erhalten?")}
        </h4>

        <Button
          id="loginResendButton"
          onClick={() => resend()}
          type={ButtonTypes.Text}
          color={ButtonColors.Action}
          className="align-self-baseline mb-16"
        >
          {t("login.sms.resendSMSCode", "SMS Code erneut senden")}
        </Button>
        <Button
          id="loginNextButton"
          isDisabled={
            validation?.smsCode?.validation?.type !== "success" || disableButton
          }
          className="mx-auto bottom-button"
          style={ButtonStyles.Primary}
          type={ButtonTypes.Standard}
          submit
          color={ButtonColors.Action}
          onClick={() => {
            setDisableButton(true);
            next();
          }}
        >
          {translate("buttons.next", { ns: "app", defaultValue: "Weiter" })}
        </Button>
      </form>
    </React.Fragment>
  );
};

const mapStateToProps = (state: RootState) => ({
  smsCode: state.app.smsCode,
  sessionId: state.app.sessionId,
  loginPhoneNumber: state.app.loginPhoneNumber,
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  setSmsCode: (value) => dispatch(setSmsCode(value)),
  setLoading: (value) => dispatch(setLoading(value)),
  login: (value) => dispatch(login(value)),
  patchLogin: bindActionCreators(patchLogin, dispatch),
  resendSms: bindActionCreators(resendSms, dispatch),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(Sms);
