import React, { FunctionComponent, useState } from "react";
import { RootState } from "../../../reducers";
import { Dispatch } from "redux";
import { Action } from "../../../actions";
import { connect } from "react-redux";
import {
  Button,
  ButtonColors,
  ButtonTypes,
  Input,
} from "icr-shared-components";
import { useHistory } from "react-router";
import { ButtonStyles } from "icr-shared-components/dist";
import { Trans, useTranslation } from "react-i18next";
import { getRouteByLanguage, DefaultRouteNames } from "../../../routes";
import { i18n } from "../../../locale";

interface Props {
  configurations: string;
}

const Start: FunctionComponent<Props> = ({ configurations }) => {
  const { t: translate } = useTranslation(["register", "app"]);
  const t = (key: string, defaultValue: string) =>
    translate(key, { ns: "register", defaultValue });
  const history = useHistory();
  const [agreeTerms, setAgreeTerms] = useState<boolean>(false);

  const next = () => {
    history.push(getRouteByLanguage(DefaultRouteNames.REGISTERNAME));
  };

  const onTermsLinkClick = (e: any) => {
    e.preventDefault();
    window.open(configurations[i18n.language]?.conditionsOfUse, "_blank");
  };

  return (
    <div className="register-start-page">
      <p className="mb-32">
        {t(
          "start.requirements.title",
          "Für die einmalige Registrierung sind die folgenden Angaben notwendig:"
        )}{" "}
        <br /> <br />
        {t(
          "start.requirements.personalInfo",
          "– Persönliche Angaben (Name, Geburtsdatum und AHV-Nummer)"
        )}{" "}
        <br /> <br />
        {t(
          "start.requirements.mobileAndAuth",
          "– Mobiltelefonnummer für die Authentifizierung"
        )}{" "}
        <br /> <br />
        {t(
          "start.requirements.registrationCode",
          "– Den einmaligen Registrierungscode, den Sie per Post erhalten. Wenn Sie diesen Code noch nicht haben, können Sie ihn im Verlauf des Registrierungsprozesses bestellen"
        )}
      </p>

      <Input
        id="agreeTermsInput"
        name="agreeTermsInput"
        type="checkbox"
        checked={agreeTerms}
        defaultValue={agreeTerms}
        checkboxLabel={
          <span className="paragraph z-index-high">
            <Trans i18nKey="start.acceptUseTerms" ns="register">
              Ich bin mit den{" "}
              <span
                className="terms-link z-index-high"
                onClick={onTermsLinkClick}
              >
                Nutzungsbedingungen
              </span>{" "}
              einverstanden.
            </Trans>
          </span>
        }
        onChange={(name, value) => setAgreeTerms(value)}
      />

      <Button
        id="registerNextButton"
        isDisabled={!agreeTerms}
        className="ml-auto bottom-button"
        type={ButtonTypes.Standard}
        style={ButtonStyles.Primary}
        color={ButtonColors.Action}
        onClick={() => next()}
      >
        {translate("buttons.start", {
          ns: "register",
          defaultValue: "Starten",
        })}
      </Button>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  configurations: state.app.configurations,
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(Start);
