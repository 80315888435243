import { ExpandableRow } from "icr-shared-components";
import { CreditBalanceDetailsBox as DataType } from "../../actions/versicherungen";
import { calculateValuesInArray } from "../../utils";
import { formatMoney } from "../../utils/currency";
import { useTranslation } from "react-i18next";

interface CreditBalanceDetailsBoxProps {
  data: DataType;
}
export default function CreditBalanceDetailsBox({
  data,
}: CreditBalanceDetailsBoxProps) {
  const { t: translate } = useTranslation(["creditBalancePage"]);
  const t = (key: string, defaultValue: string) =>
    translate(key, { defaultValue });

  return data.isVisible ? (
    <ExpandableRow
      expandable
      content={
        <div className="expandable-row-content">
          <p>
            <b>
              {t("creditbalanceDetailsBox.title", "Aktuelles Sparguthaben")}
            </b>
          </p>
          <p>
            <b>
              {formatMoney(
                calculateValuesInArray(data.creditBalanceDetailsList)
              )}
            </b>
          </p>
        </div>
      }
      more={
        <div className="expended-more-box">
          <p>
            <b>
              {t(
                "creditbalanceDetailsBox.subTitle",
                "Zusammensetzung Sparguthaben"
              )}
            </b>
          </p>
          {data.creditBalanceDetailsList.map((item, index) => (
            <div key={index} className="expended-more-box-row">
              <div>{item.text}</div>
              <div>{formatMoney(item.value)}</div>
            </div>
          ))}
        </div>
      }
    />
  ) : (
    <></>
  );
}
