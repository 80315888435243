import { useEffect, useRef } from "react";
import { connect } from "react-redux";
import Modal from "react-modal";
import { Route, Switch } from "react-router";

import "./Profile.scss";
import Card, { Type } from "../../components/Card";
import { Button, ButtonColors, ButtonTypes } from "icr-shared-components/dist";
import { ReactComponent as Arrow } from "../../assets/icons/arrow-right.svg";
import { ReactComponent as EditIcon } from "../../assets/icons/edit.svg";
import Columns from "../../components/Columns";
import Email from "../../components/Modals/Email";
import Recovery from "../../components/Modals/Recovery";
import Address from "../../components/Modals/Address";
import { RootState } from "../../reducers";
import { bindActionCreators, Dispatch } from "redux";
import { Action, setLoading } from "../../actions";
import { getProfile } from "../../actions/profile";
import { parseDate } from "../../utils";
import Phone from "../../components/Modals/Phone";
import PhoneVerification from "../../components/Modals/PhoneVerification";
import CivilStatus from "../../components/Modals/CivilStatus";
import Header from "../../components/Header";
import { RouteComponentProps, useLocation, withRouter } from "react-router-dom";
import EmailVerification from "../../components/Modals/EmailVerification";
import { useTranslation } from "react-i18next";
import { getRouteByLanguage, DefaultRouteNames } from "../../routes";
import { useGetSidebarCMSContent } from "../../hooks/cms";
import { cmsSchemas } from "../../constants";
import { HygraphSidebarComponents } from "../../components/sidebar/HygraphSidebarComponents";

interface Props extends RouteComponentProps {
  history: any;
  getProfile: any;
  profilePersonalInformation: any;
  profileLoginData: any;
  profileAddress: any;
  setLoading: (value: any) => any;
  isUserProfileReadOnly: boolean;
  //profile
  disableAddressChange: boolean;
  disableCivilStatusChange: boolean;
  disableEmailChange: boolean;
  disableMobileNumberChange: boolean;
}

const Profile = (props: Props) => {
  const schema = cmsSchemas.profile.plural;
  const { content: cmsSidebarContent } = useGetSidebarCMSContent({ schema });
  const sidebarBoxes = cmsSidebarContent?.sidebar;

  const tenantName = window.localStorage.getItem("tenantName") || "";
  const { t: translate } = useTranslation(["profile", "app"]);
  const t = (key: string, defaultValue: string) =>
    translate(key, { ns: "profile", defaultValue });
  const currentLocation = useLocation();
  const previousPath = useRef(currentLocation.pathname);

  const getProfileData = async () => {
    const { getProfile, setLoading } = props;
    setLoading(true);

    await getProfile();
    setLoading(false);
  };

  useEffect(() => {
    getProfileData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      previousPath.current.includes("telefon") &&
      currentLocation.pathname.includes("/profil")
    ) {
      getProfileData(); // Chama a função para atualizar os dados do perfil
    }

    previousPath.current = currentLocation.pathname;
  }, [currentLocation.pathname]);

  const validateIfHideEditButton = (buttonProp) => {
    return props.isUserProfileReadOnly || buttonProp;
  };

  const getModalTitles = (location) => {
    switch (location) {
      case getRouteByLanguage(DefaultRouteNames.PROFILEEMAIL):
        return t("modal.title.email", "E-Mail-Adresse ändern");
      case getRouteByLanguage(DefaultRouteNames.PROFILERECOVERY):
        return t("modal.title.recovery", "Wiederherstellungs  E-Mail-Adresse");
      case getRouteByLanguage(DefaultRouteNames.PROFILEADDRESS):
        return t("modal.title.address", "Adresse ändern");
      case getRouteByLanguage(DefaultRouteNames.PROFILEPHONE):
        return t("modal.title.phone", "Mobiltelefonnummer ändern");
      case getRouteByLanguage(DefaultRouteNames.PROFILEPHONEVERIFICATION):
        return t("modal.title.phoneVerification", "SMS-Code eingeben");
      case getRouteByLanguage(DefaultRouteNames.PROFILEEMAILVERIFICATION):
        return t("modal.title.emailVerification", "Email-Code eingeben");
      case getRouteByLanguage(DefaultRouteNames.PROFILECIVILSTATUS):
        return t("modal.title.civilStatus", "Zivilstand ändern");
    }
  };

  const pathname = props.location.pathname;
  const isModalOpen =
    pathname === getRouteByLanguage(DefaultRouteNames.PROFILEEMAIL) ||
    pathname === getRouteByLanguage(DefaultRouteNames.PROFILERECOVERY) ||
    pathname === getRouteByLanguage(DefaultRouteNames.PROFILEADDRESS) ||
    pathname === getRouteByLanguage(DefaultRouteNames.PROFILEPHONE) ||
    pathname ===
      getRouteByLanguage(DefaultRouteNames.PROFILEPHONEVERIFICATION) ||
    pathname ===
      getRouteByLanguage(DefaultRouteNames.PROFILEEMAILVERIFICATION) ||
    pathname === getRouteByLanguage(DefaultRouteNames.PROFILECIVILSTATUS);

  const civilStatusTitle = (
    <div className="d-flex">
      {t("personalInfo.civilStatusName.label", "Zivilstand")}
      <div className="button profile-civil-status-button">
        {tenantName !== "PKSO" && !props.disableCivilStatusChange && (
          <Button
            type={ButtonTypes.Text}
            color={ButtonColors.Action}
            onClick={() =>
              history.push(
                getRouteByLanguage(DefaultRouteNames.PROFILECIVILSTATUS)
              )
            }
          >
            <EditIcon />
          </Button>
        )}
      </div>
    </div>
  );

  const cityTitle = (
    <div className="d-flex">
      {t("addressBox.location.label", "Ort")}
      <div className="button profile-civil-status-button"></div>
    </div>
  );

  const {
    history,
    location,
    profileAddress,
    profilePersonalInformation,
    profileLoginData,
  } = props;

  return (
    <div className="profile-page">
      <div className="page-top">
        <Button
          type={ButtonTypes.Text}
          color={ButtonColors.Action}
          onClick={() =>
            props.history.push(getRouteByLanguage(DefaultRouteNames.DASHBOARD))
          }
        >
          <Arrow className="rotate-180" />{" "}
          <span className="ml-16">
            {translate("buttons.backToDashboard", {
              ns: "app",
              defaultValue: "Zurück zum dashboard",
            })}
          </span>
        </Button>
      </div>

      <h1 className="mb-48">{t("page.title", "Profil")}</h1>

      <div className="profile-page-content">
        <Card className="card-1" type={Type.Standard}>
          <h2 className="mb-32">
            {t("personalDetailsBox.title", "Personalien")}
          </h2>
          <div className="row">
            <div className="col-md-12 col-sm-12 pr-0">
              <Columns
                className="personal-details"
                titles={[
                  t("personalInfo.firstname.label", "Vorname"),
                  t("personalInfo.lastname.label", "Name"),
                  t("personalInfo.birthdate.label", "Geburtsdatum"),
                  t("personalInfo.socialSecurityNumber.label", "AHV-Nummer"),
                  civilStatusTitle,
                ]}
                data={[
                  profilePersonalInformation?.firstname,
                  profilePersonalInformation?.lastname,
                  parseDate(profilePersonalInformation?.birthdate),
                  profilePersonalInformation?.socialSecurityNumber,
                  profilePersonalInformation?.civilStatusName,
                ]}
              />
            </div>
          </div>
        </Card>

        <Card className="card-2">
          {sidebarBoxes && (
            <HygraphSidebarComponents avoidBoxValidation boxes={sidebarBoxes} />
          )}
        </Card>

        <Card className="card-3">
          <div className="list-group-top">
            <h2>{t("loginDataBox.title", "Logindaten")}</h2>
          </div>

          <hr />

          <div className="list-group-row">
            <div className="top">
              <div className="left">
                {t("loginDataBox.email", "E-Mail-Adresse")}
              </div>

              <div className="right">
                <b>{profileLoginData?.emailAddress}</b>
                <div className="button ml-40">
                  {!validateIfHideEditButton(props.disableEmailChange) && (
                    <Button
                      type={ButtonTypes.Text}
                      color={ButtonColors.Action}
                      onClick={() =>
                        history.push(
                          getRouteByLanguage(DefaultRouteNames.PROFILEEMAIL)
                        )
                      }
                    >
                      <EditIcon />
                    </Button>
                  )}
                </div>
              </div>
            </div>
          </div>

          <hr />

          <div className="list-group-row">
            <div className="top">
              <div className="left">
                {t("loginDataBox.mobileNumber", "Mobiltelefonnummer")}
              </div>

              <div className="right">
                <b className="mobile">{profileLoginData?.mobileNumber}</b>
                <div className="button ml-40">
                  {!validateIfHideEditButton(
                    props.disableMobileNumberChange
                  ) && (
                    <Button
                      type={ButtonTypes.Text}
                      color={ButtonColors.Action}
                      onClick={() =>
                        history.push(
                          getRouteByLanguage(DefaultRouteNames.PROFILEPHONE)
                        )
                      }
                    >
                      <EditIcon />
                    </Button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Card>

        <Card className="card-4 pr-73" type={Type.Standard}>
          <div className="d-flex justify-content-between align-items-center mb-32">
            <h2>{t("addressBox.title", "Adresse")}</h2>

            <div className="button profile-address-edit-button">
              {!validateIfHideEditButton(props.disableAddressChange) && (
                <Button
                  type={ButtonTypes.Text}
                  color={ButtonColors.Action}
                  onClick={() =>
                    history.push(
                      getRouteByLanguage(DefaultRouteNames.PROFILEADDRESS)
                    )
                  }
                >
                  <EditIcon />
                </Button>
              )}
            </div>
          </div>

          <div className="row">
            <div className="col-md-12 col-sm-12 pr-0">
              <Columns
                titles={[
                  t("addressBox.addressRow1", "Adresszeile 1"),
                  t("addressBox.addressRow2", "Adresszeile 2"),
                  t("addressBox.postCode.label", "PLZ"),
                  cityTitle,
                ]}
                data={[
                  profileAddress?.addressRow1,
                  profileAddress?.addressRow2,
                  profileAddress?.zipCode,
                  profileAddress?.city,
                ]}
              />
            </div>
          </div>
        </Card>
      </div>

      <Modal
        ariaHideApp={false}
        isOpen={isModalOpen}
        className="modal profile-modal"
        overlayClassName="modal-overlay profile-modal-overlay"
        contentLabel="Profile modal"
      >
        <div className="mobile-modal-header">
          <Header />
        </div>

        <div className="profile-modal-wrapper">
          <Button
            className="mt-58"
            type={ButtonTypes.Text}
            color={ButtonColors.Action}
            onClick={() =>
              props.history.push(getRouteByLanguage(DefaultRouteNames.PROFILE))
            }
          >
            <Arrow className="rotate-180" />{" "}
            <span className="ml-16">{t("modal.goBackButton", "Zurück")}</span>
          </Button>

          <h2 className="mt-94 mobile-modal-title">
            {getModalTitles(location.pathname)}
          </h2>

          <Card className="mt-48 profile-modal-content" type={Type.Modal}>
            <Switch>
              <Route
                exact
                path={getRouteByLanguage(DefaultRouteNames.PROFILEEMAIL)}
                component={Email}
              />
              <Route
                exact
                path={getRouteByLanguage(
                  DefaultRouteNames.PROFILEEMAILVERIFICATION
                )}
                component={EmailVerification}
              />
              <Route
                path={getRouteByLanguage(DefaultRouteNames.PROFILERECOVERY)}
                component={Recovery}
              />
              <Route
                exact
                path={getRouteByLanguage(DefaultRouteNames.PROFILEPHONE)}
                component={Phone}
              />
              <Route
                exact
                path={getRouteByLanguage(
                  DefaultRouteNames.PROFILEPHONEVERIFICATION
                )}
                component={PhoneVerification}
              />
              <Route
                path={getRouteByLanguage(DefaultRouteNames.PROFILEADDRESS)}
                component={Address}
              />
              <Route
                path={getRouteByLanguage(DefaultRouteNames.PROFILECIVILSTATUS)}
                component={CivilStatus}
              />
            </Switch>
          </Card>
        </div>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  profilePersonalInformation: state.profile.profilePersonalInformation,
  profileLoginData: state.profile.profileLoginData,
  profileAddress: state.profile.profileAddress,
  isUserProfileReadOnly: state.profile.isUserProfileReadOnly,
  //profile
  disableAddressChange: state.profile.disableAddressChange,
  disableCivilStatusChange: state.profile.disableCivilStatusChange,
  disableEmailChange: state.profile.disableEmailChange,
  disableMobileNumberChange: state.profile.disableMobileNumberChange,
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  getProfile: bindActionCreators(getProfile, dispatch),
  setLoading: (val) => dispatch(setLoading(val)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default withRouter(connector(Profile));
