export function formatMoney(
    amount: any,
    decimalCount = 2,
    decimal = ".",
    thousands = "’"
) {
    try {
        decimalCount = Math.abs(decimalCount);
        decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

        const negativeSign = amount < 0 ? "-" : "";

        // deal with decimals
        const splitted = amount.toString().split(".");

        amount = splitted[0];

        let i = parseFloat(
            (amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))
        ).toString();
        let j = i.length > 3 ? i.length % 3 : 0;

        return (
            negativeSign +
            (j ? i.substr(0, j) + thousands : "") +
            i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (splitted[1] ? "." + splitted[1] : "")
        );
    } catch (e) {
        console.log(e);
    }
}
