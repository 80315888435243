import { useTranslation } from "react-i18next";
import { StyledWrapper } from "./styles";
import { time } from "d3";

export interface NotificationProps {
  processingDate: string;
}

const NotificationLabel = ({ processingDate }: NotificationProps) => {
  const { t: translate } = useTranslation(["common"]);

  const parse = time.format("%Y-%m-%d").parse;
  const options = {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  };
  const baseDate = parse(processingDate.slice(0, 10))
    .toLocaleDateString("de-DE", options)
    .replace(/\//g, ".");

  return (
    <StyledWrapper>
      {translate &&
        translate(
          "forBaseDate",
          "Die Informationen im Versichertenportal basieren auf den per {{baseDate}} verarbeiteten Daten",
          { baseDate }
        )}
    </StyledWrapper>
  );
};

export default NotificationLabel;
