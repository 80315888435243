import { Action } from "../actions";
import * as constants from "../constants/actions";

export type IBearerToken = {
  exp: number;
  aud: string;
  iss: string;
  jt: string;
  language: string;
  socialSecurityNumber: string;
  tenantCode: string;
  userId: string;
};

export type ILocizeSettings = {
  tenantCode: string;
  apiKey: string;
  projectId: string;
  version: string;
};

export type ILocizelanguage = {
  name: string;
  nativeName: string;
  isReferenceLanguage: boolean;
  region: string;
};

export type IImpersonation = {
  impersonationBox: {
    title: string;
    text: string;
    loggedSSN: string;
    impersonatedSSN: string;
    isVisible: boolean;
  };
  impersonatedUsers: {
    isFetched: boolean;
    data: {
      tenant: string;
      users: {
        isActive: boolean;
        ssn: string;
      }[];
    }[];
  };
};

export interface AppState {
  i18nLoading: boolean;
  loading: boolean;
  isDrawerLoading: boolean;
  sessionId: string;
  userLanguage: string;
  email: string;
  smsCode: string;
  name: string;
  lastname: string;
  birthday: string;
  ahvNumber: string;
  phoneNumber: string;
  loginPhoneNumber: string;
  registerSmsCode: string;
  registrationCode: string;
  registrationId: string;
  faq: Array<any>;
  currentUser: any;
  logoUrl: string | null;
  backgroundUrl: string | null;
  googleAnalyticsTags: string[];
  favoriteIcon: string | null;
  contact: any;
  conditionsOfUse: string;
  configurations: any;
  locizeSettings?: ILocizeSettings;
  languageItemsList?: Record<string, ILocizelanguage>;
  hasMultiLanguageSupport: boolean;
  impersonation: IImpersonation;
  hygraph: {
    url: string;
    schemaRetryContentList: { [key: string]: number };
  };
  // these state keys were separated to avoid an extra hygraph fetch (main/sidebar) due to the
  // deep equality from the same root key, and a future test with shallowEqual can be done
  hygraphSchemaRetryContentList: { [key: string]: number };
  hygraphSchemaRetrySidebarList: { [key: string]: number };
  //profile
  disableAddressChange: boolean;
  disableCivilStatusChange: boolean;
  disableEmailChange: boolean;
  disableMobileNumberChange: boolean;
}

const impersonation = {
  impersonationBox: {
    title: null,
    text: null,
    loggedSSN: null,
    impersonatedSSN: null,
    isVisible: false,
  },
  impersonatedUsers: {
    isFetched: false,
    data: [],
  },
};

const getInitialState = () => {
  return {
    i18nLoading: false,
    loading: false,
    isDrawerLoading: false,
    sessionId: localStorage.getItem("ip_session_id") || "",
    userLanguage: "de-CH",
    email: "",
    smsCode: "",
    name: "",
    lastname: "",
    birthday: "",
    ahvNumber: "",
    phoneNumber: "",
    loginPhoneNumber: "",
    registerSmsCode: "",
    registrationCode: "",
    registrationId: "",
    faq: [],
    currentUser: null,
    logoUrl: "",
    backgroundUrl: "",
    googleAnalyticsTags: [],
    contact: null,
    favoriteIcon: "",
    conditionsOfUse: "",
    configurations: {},
    hasMultiLanguageSupport: false,
    impersonation,
    hygraph: {
      url: "",
      schemaRetryContentList: {},
    },
    hygraphSchemaRetryContentList: {},
    hygraphSchemaRetrySidebarList: {},
    //profile
    disableAddressChange: false,
    disableCivilStatusChange: false,
    disableEmailChange: false,
    disableMobileNumberChange: false,
  };
};

const app = (state: AppState = getInitialState(), action: Action) => {
  switch (action.type) {
    case constants.LOGIN: {
      // const user: IBearerToken = jwt(bearerToken);
      const language = "de-CH";
      return {
        ...state,
        userLanguage: language,
      };
    }

    case constants.SET_I18N_LOADING: {
      return { ...state, i18nLoading: action.payload };
    }
    case constants.SET_LANGUAGES_LIST: {
      return { ...state, languageItemsList: action.payload };
    }

    case constants.SET_LOADING: {
      return { ...state, loading: action.payload };
    }

    case constants.SET_DRAWER_LOADING: {
      return { ...state, isDrawerLoading: action.payload };
    }

    case constants.SET_FAQ: {
      return { ...state, faq: action.payload };
    }

    case constants.SET_EMAIL: {
      return { ...state, email: action.payload };
    }

    case constants.SET_SESSION: {
      localStorage.setItem("ip_session_id", action.payload);
      return { ...state, sessionId: action.payload };
    }

    case constants.SET_LOGIN_SMS_CODE: {
      return { ...state, smsCode: action.payload };
    }

    case constants.SET_NAME: {
      return { ...state, name: action.payload };
    }

    case constants.SET_LASTNAME: {
      return { ...state, lastname: action.payload };
    }

    case constants.SET_BIRTHDAY: {
      return { ...state, birthday: action.payload };
    }

    case constants.SET_AHV_NUMBER: {
      return { ...state, ahvNumber: action.payload };
    }

    case constants.SET_PHONE_NUMBER: {
      return { ...state, phoneNumber: action.payload };
    }

    case constants.SET_LOGIN_PHONE_NUMBER: {
      return { ...state, loginPhoneNumber: action.payload };
    }

    case constants.SET_REGISTRATION_CODE: {
      return { ...state, registrationCode: action.payload };
    }

    case constants.SET_REGISTER_SMS_CODE: {
      return { ...state, registerSmsCode: action.payload };
    }

    case constants.SET_REGISTRATION_ID: {
      return { ...state, registrationId: action.payload };
    }

    case constants.SET_CURRENT_USER: {
      return { ...state, currentUser: action.payload };
    }

    case constants.SET_IMPERSONATION_DATA: {
      return {
        ...state,
        impersonation: {
          ...state.impersonation,
          impersonationBox: action.payload,
        },
      };
    }

    case constants.SET_IMPERSONATED_USERS: {
      const impersonatedUsersData = action.payload.map((item) => {
        return {
          tenant: item.attributes.tenantCode,
          users: item.attributes.impersonateds.map((subItem) => {
            return {
              isActive: subItem.isActive,
              ssn: subItem.socialSecurityNumber,
            };
          }),
        };
      });

      return {
        ...state,
        impersonation: {
          ...state.impersonation,
          impersonatedUsers: {
            isFetched: true,
            data: impersonatedUsersData,
          },
        },
      };
    }

    case constants.GLOBAL_SETTINGS: {
      if (!action.payload) {
        return { ...state };
      }
      const logoUrl = action.payload.customDesign?.logoImage;
      const backgroundUrl = action.payload.customDesign?.backgroundImage;
      const favoriteIcon = action.payload.favoriteIcon;
      const conditionsOfUse = action.payload.condidtionsOfUse;
      const locizeSettings = action.payload.locizeSettings;
      const hasMultiLanguageSupport = action.payload.hasMultiLanguageSupport;
      const hygraphUrl = action.payload.hygraph;
      const googleAnalyticsTags = action.payload.googleAnalyticsTags;
      // new configuration based on language
      const configurations = action.payload.configurations;
      // profile
      const disableAddressChange = action.payload.disableAddressChange;
      const disableCivilStatusChange = action.payload.disableCivilStatusChange;
      const disableEmailChange = action.payload.disableEmailChange;
      const disableMobileNumberChange =
        action.payload.disableMobileNumberChange;

      return {
        ...state,
        logoUrl: logoUrl ? logoUrl : null,
        backgroundUrl: backgroundUrl ? backgroundUrl : null,
        favoriteIcon: favoriteIcon ? favoriteIcon : null,
        conditionsOfUse,
        locizeSettings,
        configurations,
        hasMultiLanguageSupport,
        hygraph: {
          ...state.hygraph,
          url: hygraphUrl || "",
        },
        googleAnalyticsTags,
        //profile
        disableAddressChange,
        disableCivilStatusChange,
        disableEmailChange,
        disableMobileNumberChange,
      };
    }

    case constants.LOG_OUT: {
      localStorage.removeItem("ip_refresh_token");
      localStorage.removeItem("ip_access_token");
      localStorage.removeItem("ip_session_id");
      const {
        logoUrl,
        backgroundUrl,
        favoriteIcon,
        conditionsOfUse,
        locizeSettings,
        languageItemsList,
        configurations,
      } = state;
      return {
        ...getInitialState(),
        logoUrl,
        backgroundUrl,
        favoriteIcon,
        conditionsOfUse,
        locizeSettings,
        configurations,
        languageItemsList,
      };
    }

    case constants.SET_CONTACT: {
      return { ...state, contact: action.payload };
    }

    case constants.SET_HYGRAPH_RETRY_CONTENT_LIST: {
      const { schema, initialCount } = action.payload;

      const schemaAlreadyExists =
        state.hygraphSchemaRetryContentList.hasOwnProperty(schema);

      const newSchemaRetryContentList = {
        ...state.hygraphSchemaRetryContentList,
      };

      const RETRY_LIMIT = 5;

      newSchemaRetryContentList[schema] = schemaAlreadyExists
        ? newSchemaRetryContentList[schema] + 1
        : initialCount;

      return {
        ...state,
        hygraphSchemaRetryContentList: newSchemaRetryContentList,
      };
    }

    case constants.SET_HYGRAPH_RETRY_SIDEBAR_LIST: {
      const { schema, initialCount } = action.payload;

      const schemaAlreadyExists =
        state.hygraphSchemaRetrySidebarList.hasOwnProperty(schema);

      const newSchemaRetrySidebarList = {
        ...state.hygraphSchemaRetrySidebarList,
      };

      newSchemaRetrySidebarList[schema] = schemaAlreadyExists
        ? newSchemaRetrySidebarList[schema] + 1
        : initialCount;

      return {
        ...state,
        hygraphSchemaRetrySidebarList: newSchemaRetrySidebarList,
      };
    }

    default: {
      return { ...state };
    }
  }
};

export default app;
