import React, { FunctionComponent, useState } from "react";
import { RootState } from "../../../reducers";
import { bindActionCreators, Dispatch } from "redux";
import { Action, setLoading } from "../../../actions";
import { connect } from "react-redux";
import {
  Button,
  ButtonColors,
  ButtonTypes,
  Input,
} from "icr-shared-components";
import { useHistory } from "react-router";
import {
  resendSms,
  setRegisterSmsCode,
  validateSms,
} from "../../../actions/register";
import { ButtonStyles } from "icr-shared-components/dist";
import { formConfig } from "./formConfig";
import { useValidateFrom } from "../../../utils/useValidateFrom";
import { useTranslation } from "react-i18next";
import { toaster } from "../../../utils/toaster";
import { getRouteByLanguage, DefaultRouteNames } from "../../../routes";

interface Props {
  registerSmsCode: string;
  setRegisterSmsCode: (value: string) => void;
  phoneNumber: string;
  validateSms: any;
  sessionId: string;
  resendSms: any;
  setLoading: any;
}

const Sms: FunctionComponent<Props> = ({
  registerSmsCode,
  setRegisterSmsCode,
  phoneNumber,
  validateSms,
  resendSms,
  sessionId,
  setLoading,
}) => {
  const { t: translate } = useTranslation(["register", "app"]);
  const t = (key: string, defaultValue: string) =>
    translate(key, { ns: "register", defaultValue });
  const [isBlurred, setIsBlurred] = useState(false);
  const [disableButton, setDisableButton] = useState(false);

  const validation = useValidateFrom("register", formConfig, {
    registerSmsCode,
  });

  const history = useHistory();

  const next = async () => {
    setLoading(true);
    try {
      const data = {
        smsCode: registerSmsCode,
      };
      const response = await validateSms(data, sessionId);
      setLoading(false);
      if (response.isSuccessfull) {
        history.push(getRouteByLanguage(DefaultRouteNames.REGISTERMESSAGE));
      }
      setDisableButton(false);
    } catch (error) {
      setDisableButton(false);
      console.error("error in registering", error);
    }
  };

  const resend = async () => {
    setLoading(true);
    try {
      const response = await resendSms(sessionId);
      toaster({
        message: t(
          "login.success.sentPhoneSms",
          "Die SMS wurde an Ihr Telefon gesendet!"
        ),
        type: "success",
      });
      setLoading(false);
    } catch (error) {
      console.error("error in registering", error);
    }
  };

  const back = () => {
    history.push(getRouteByLanguage(DefaultRouteNames.REGISTERPHONE));
  };

  return (
    <div className="register-birthday-page">
      <p className="mb-32">
        {t(
          "authentication.smsSent",
          "Wir haben Ihnen ein SMS an die angegebene Nummer"
        )}{" "}
        <br className="mobile-remove-line-break" />
        {`${phoneNumber.substr(0, 6)}*****${phoneNumber.substr(
          phoneNumber.length - 2,
          phoneNumber.length
        )}`}{" "}
        <br /> <br />
        {t(
          "authentication.enterCode",
          "Geben Sie hier bitte Ihren Bestätigungscode ein."
        )}
      </p>

      <div className="row">
        <div className="col-sm-12 col-md-6">
          <Input
            id="registerSmsInput"
            type="text"
            name="registerSmsInput"
            value={registerSmsCode}
            onChange={(name, value) => setRegisterSmsCode(value)}
            placeholder={t(
              "authentication.smsCode.placeholder",
              "SMS Code eingeben"
            )}
            inputWrapperClass="mb-32"
            onBlur={() => setIsBlurred(true)}
            validationMessageConfig={
              (isBlurred ||
                validation?.registerSmsCode?.validation?.type ===
                  "success") && {
                config: validation.registerSmsCode.validation,
                visible: true,
              }
            }
          >
            {t("authentication.smsCode.label", "SMS Code")}
          </Input>
        </div>
      </div>

      <h5 className="mb-16">
        {t("authentication.noSms", "Kein SMS erhalten?")}
      </h5>
      <div className="left-align-button-wrapper">
        <Button
          id="registerResendButton"
          onClick={() => resend()}
          type={ButtonTypes.Text}
          color={ButtonColors.Action}
          className="mb-48"
        >
          {t("authentication.resendSMS", "SMS Code erneut senden")}
        </Button>
      </div>

      <div className="modal-buttons">
        <Button
          id="registerBackButton"
          onClick={() => back()}
          type={ButtonTypes.Text}
          color={ButtonColors.Action}
        >
          {translate("buttons.goBack", { ns: "app", defaultValue: "Zurück" })}
        </Button>

        <Button
          id="registerNextButton"
          isDisabled={
            validation?.registerSmsCode?.validation?.type !== "success" ||
            disableButton
          }
          type={ButtonTypes.Standard}
          style={ButtonStyles.Primary}
          color={ButtonColors.Action}
          onClick={() => {
            setDisableButton(true);
            next();
          }}
        >
          {translate("buttons.next", { ns: "app", defaultValue: "Weiter" })}
        </Button>
      </div>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  registerSmsCode: state.app.registerSmsCode,
  phoneNumber: state.app.phoneNumber,
  sessionId: state.app.sessionId,
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  setRegisterSmsCode: (value) => dispatch(setRegisterSmsCode(value)),
  validateSms: bindActionCreators(validateSms, dispatch),
  resendSms: bindActionCreators(resendSms, dispatch),
  setLoading: (value) => dispatch(setLoading(value)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(Sms);
