import styled, { keyframes } from "styled-components";
import { grayScale } from "@icr-ui/theme";
import { Button } from "icr-shared-components/dist";

interface LoaderDrwerProps {
  percent?: number;
  customStyles?: Object;
  fullScreen?: boolean;
}

const gradientAnimation = keyframes`
 0% {
    background-position: 0% 50%;
  }
  10% {
    background-position: 10% 50%;
  }
  20% {
    background-position: 20% 52%;
  }
  30% {
    background-position: 30% 55%;
  }
  40% {
    background-position: 40% 60%;
  }
  50% {
    background-position: 50% 65%;
  }
  60% {
    background-position: 60% 70%;
  }
  70% {
    background-position: 70% 75%;
  }
  80% {
    background-position: 80% 78%;
  }
  90% {
    background-position: 90% 80%;
  }
  100% {
    background-position: 100% 50%;
  }
`;

export const Wrapper = styled.div<LoaderDrwerProps>`
  width: 100%;

  align-items: flex-start;
  position: absolute;
  z-index: 9999;
  top: 0;
  left: 0;
  background-color: ${({ fullScreen }) =>
    fullScreen ? "rgba(255, 255, 255, 0.5)" : "transparent"};
`;

export const StyledMutationLoaderWrapper = styled.div<LoaderDrwerProps>`
  width: 100%;
  min-height: 88px;
  padding: 0 48px;

  display: flex;
  align-items: center;
  justify-content: center;

  background: linear-gradient(
    90deg,
    #110000 0%,
    #950e2d 4.85%,
    #a27a80 10.08%,
    #a28591 14.82%,
    #a29997 19.42%,
    #a2a39a 24.65%,
    #8f9ea1 29.46%,
    #8cc4c3 34.54%,
    #679ca9 39.35%,
    #396c89 46.04%,
    #154670 52.87%,
    #689daa 60.47%,
    #8cc4c3 68.55%,
    #8f9fa2 74.48%,
    #a3a39a 79.85%,
    #a29897 85.07%,
    #a28591 89.67%,
    #870d28 94.94%,
    #110000 100%
  );
  animation: ${gradientAnimation} 14s cubic-bezier(1, 1, 1, 1) infinite;
  background-size: 700% 100%;
  z-index: 999999;

  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    max-width: 100%;
    width: ${({ percent }) => percent + "%"};
    height: 4px;
    background-color: ${grayScale[50]};
    opacity: 0.75;
  }
  ${({ customStyles }): {} => customStyles || {}}

  @media (max-width: 768px) {
    padding: 20px;
  }
`;

export const StyledMutationLabel = styled.span`
  color: white;
  font-size: 18px;
  font-weight: 600;
  line-height: 26px;
`;

export const StyledMutationButton = styled(Button)`
  color: white;
  border: 2px solid rgb(255, 255, 255);
  background-color: transparent;
  padding: 0 32px;
  min-width: 154.57px;
  width: auto;
  height: 36px;
  border-radius: 26px;
  font-size: 14px;
  font-weight: 700;
  transition: filter 0.2s;

  &:hover {
    filter: brightness(0.8);
  }
`;

export const StyledMutationLoader = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  z-index: 999;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 24px;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;
