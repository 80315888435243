import { Dispatch, useEffect } from "react";
import { connect } from "react-redux";
import { Action } from "redux";
import { setLoading } from "../../actions";
import "./Forbidden.scss";

const Forbidden = (props) => {
  const { setLoading } = props;

  useEffect(() => {
    setLoading(false);
  }, []);

  return (
    <>
      <div className="main-text">Forbidden</div>
      <div className="minor-text">You don't have permission to access this resource.</div>
    </>
  );
};

const mapStateToProps = null;

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  setLoading: (value) => dispatch(setLoading(value)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(Forbidden);
