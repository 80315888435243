import { forwardRef, Ref } from "react";
import {
  Wrapper,
  StyledMutationLoaderWrapper,
  StyledMutationLoader,
  StyledMutationLabel,
  StyledMutationButton,
} from "./styles";

interface LoaderDrawerProps {
  fullScreen?: boolean;
  label: string;
  buttonProps?: {
    label?: string;
    onClick?: (args?: any) => void;
  };
  percent?: number;
  customStyles?: Object;
}

const LoaderDrawer = forwardRef(
  (
    {
      label,
      buttonProps,
      fullScreen = false,
      percent,
      customStyles,
    }: LoaderDrawerProps,
    ref: Ref<HTMLDivElement>
  ): JSX.Element => {
    return (
      <Wrapper fullScreen={fullScreen}>
        <StyledMutationLoaderWrapper {...{ percent, ref, customStyles }}>
          <StyledMutationLoader>
            <StyledMutationLabel>{label}</StyledMutationLabel>
            {buttonProps && (
              <StyledMutationButton onClick={buttonProps?.onClick}>
                {buttonProps?.label}
              </StyledMutationButton>
            )}
          </StyledMutationLoader>
        </StyledMutationLoaderWrapper>
      </Wrapper>
    );
  }
);

LoaderDrawer.displayName = "LoaderDrawer";

export default LoaderDrawer;
