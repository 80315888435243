import React, { Fragment, useState } from "react";
import {
  Button,
  ButtonColors,
  ButtonStyles,
  ButtonTypes,
  Input,
} from "icr-shared-components/dist";
import { useHistory } from "react-router";
import { RootState } from "../../reducers";
import { bindActionCreators, Dispatch } from "redux";
import { Action } from "../../actions";
import { updateProfileLoginData } from "../../actions/profile";
import { connect } from "react-redux";
import { formConfig } from "./formConfig";
import { useValidateFrom } from "../../utils/useValidateFrom";
import { getRouteByLanguage, DefaultRouteNames } from "../../routes";
import { useTranslation } from "react-i18next";

interface Props {
  profileLoginData: any;
  updateProfileLoginData: any;
}

const Recovery = ({ profileLoginData, updateProfileLoginData }: Props) => {
  const [restoreEmail, setRestoreEmail] = useState("");
  const [isBlurred, setIsBlurred] = useState(false);
  const { t: translate } = useTranslation(["profile", "common"]);
  const t = (key: string, defaultValue: string) =>
    translate(key, { ns: "profile", defaultValue });

  const history = useHistory();
  const validation = useValidateFrom("profile", formConfig, { restoreEmail });

  const handleSubmit = async (e) => {
    const payload = {
      ...profileLoginData,
      restoreEmailAddress: restoreEmail,
    };
    const response = await updateProfileLoginData(payload);
    console.log(response);
  };

  return (
    <Fragment>
      <p className="mb-48">
        {t(
          "recovery.modalHeader",
          "Geben Sie eine Wiederherstellungs E-Mail-Adresse ein. Diese muss sich von der primären E-Mail-Adresse unterscheiden. Lassen Sie sich dann ein Bestätigungsmail senden. Im Mail finden Sie einen Link um die E-Mail-Adresse zu bestätigen."
        )}
      </p>

      <div className="row">
        <div className="col-md-5 col-xs-12">
          <Input
            type="text"
            name="email"
            value={restoreEmail}
            onChange={(name, value) => setRestoreEmail(value)}
            placeholder={t(
              "recovery.restoreEmailPlaceholder",
              "Wiederherstellungs-E-Mail-Adresse eingeben"
            )}
            inputWrapperClass="mb-48"
            onBlur={() => setIsBlurred(true)}
            validationMessageConfig={
              (isBlurred ||
                validation?.restoreEmail?.validation?.type === "success") && {
                config: validation.restoreEmail.validation,
                visible: true,
              }
            }
          >
            {t(
              "recovery.restoreEmail",
              "Ihre neue Wiederherstellungs-E-Mail-Adresse"
            )}
          </Input>
        </div>
      </div>

      <div className="modal-buttons">
        <Button
          type={ButtonTypes.Text}
          color={ButtonColors.Action}
          onClick={() =>
            history.push(getRouteByLanguage(DefaultRouteNames.PROFILE))
          }
        >
          {t("recovery.goBackButton", "abbrechen")}
        </Button>

        <Button
          type={ButtonTypes.Standard}
          style={ButtonStyles.Primary}
          color={ButtonColors.Action}
          onClick={handleSubmit}
        >
          {t("recovery.confirmButton", "bestätigen")}
        </Button>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state: RootState) => ({
  profileLoginData: state.profile.profileLoginData,
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  updateProfileLoginData: bindActionCreators(updateProfileLoginData, dispatch),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(Recovery);
