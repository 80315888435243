import { useSelector } from "react-redux";

import { RootState } from "../../reducers";

const useIsDashboardFaqVisible = () => {
  return useSelector(
    (state: RootState) => state?.dashboard?.dashboardData?.faqBox?.isVisible
  ) || false;
};

export default useIsDashboardFaqVisible;