import {EValidationTestType} from "../../../utils/validationConfigs";

export const formConfig = {
    register: {
        registrationCode: {
            model: "register",
            name: "registrationCode",
            standardMessage: undefined,
            validationTests: [
                EValidationTestType.hasValue,
                EValidationTestType.isPin
            ],
            visible: true,
            showSuccess: true,
        },
    },
};
