import { useEffect } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Route, Switch } from "react-router";
import Modal from "react-modal";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { Button, ButtonColors, ButtonTypes } from "icr-shared-components";

import Card, { Type } from "../../components/Card";
import "../Versicherungen/Versicherungen.scss";
import { ReactComponent as ChartIcon } from "../../assets/icons/Desktop/chart-icon.svg";
import { ReactComponent as Arrow } from "../../assets/icons/arrow-right.svg";
import Header from "../../components/Header";
import { Top } from "../../components/Card/Content/Top";
import DonutChart from "../../components/Chart/DonutChart";
import "./Zusatzsparen.scss";
import {
  ZusatzsparenData,
  getZusatzsparenData,
} from "../../actions/zusatzsparen";
import { RootState } from "../../reducers";
import { formatMoney } from "../../utils/currency";
import { Action, setLoading } from "../../actions";
import ZusatzsparenCustomize from "../../components/Modals/ZusatzsparenCustomize";
import ZusatzsparenConfirmation from "../../components/Modals/ZusatzsparenConfirmation";
import ZusatzsparenApply from "../../components/Modals/ZusatzsparenApply";
import { navigateToSimulator } from "../../utils";
import { FinanceBox } from "../../components/sidebar";
import { useTranslation } from "react-i18next";
import { cmsSchemas } from "../../constants";
import CustomRichText from "../../components/RichText";
import { getRouteByLanguage, DefaultRouteNames } from "../../routes";
import { useGetCMSContent, useGetSidebarCMSContent } from "../../hooks/cms";
import { HygraphSidebarComponents } from "../../components/sidebar/HygraphSidebarComponents";

interface Props extends RouteComponentProps {
  getZusatzsparenData: any;
  setLoading: any;
  zusatzsparenData: ZusatzsparenData;
  history: any;
}
const Zusatzsparen = (props: Props) => {
  const schema = cmsSchemas.increasePension.plural;
  const { content: cmsContent } = useGetCMSContent({ schema });
  const { content: cmsSidebarContent } = useGetSidebarCMSContent({ schema });
  const sidebarBoxes = cmsSidebarContent?.sidebar;

  const { t: translate } = useTranslation(["additionalSavingsPage", "app"]);
  const t = (key: string, defaultValue: string) =>
    translate(key, { defaultValue });

  const getInitialData = async () => {
    try {
      props.setLoading(true);
      await props.getZusatzsparenData();
      props.setLoading(false);
    } catch (error) {
      console.error("error in getting austritt page initial data", error);
    }
  };

  useEffect(() => {
    getInitialData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isModalOpen = () => {
    const { location } = props;
    const pathname = location.pathname;

    return (
      pathname ===
        getRouteByLanguage(DefaultRouteNames.ADDITIONALSAVINGSCUSTOMIZE) ||
      pathname ===
        getRouteByLanguage(DefaultRouteNames.ADDITIONALSAVINGSCONFIRMATION) ||
      pathname === getRouteByLanguage(DefaultRouteNames.ADDITIONALSAVINGSAPPLY)
    );
  };

  const data = props.zusatzsparenData;

  const reactManagedSidebarComponents = {
    financeExpertBox: <FinanceBox {...{ ...data.financeExpertBox }} />,
  };

  return (
    <>
      <Modal
        ariaHideApp={false}
        isOpen={isModalOpen()}
        className="modal"
        overlayClassName="modal-overlay"
        contentLabel="Profile modal"
      >
        <div className="mobile-modal-header">
          <Header />
        </div>

        <Button
          className="mt-58"
          type={ButtonTypes.Text}
          color={ButtonColors.Action}
          onClick={() =>
            props.history.push(
              getRouteByLanguage(DefaultRouteNames.ADDITIONALSAVINGS)
            )
          }
        >
          <Arrow className="rotate-180" />
          <span className="ml-16">
            {translate("buttons.goBack", { ns: "app", defaultValue: "Zurück" })}
          </span>
        </Button>
        <h2 className="mt-94 mobile-modal-title">
          {t("customize.title", "Zusatzsparen anpassen")}
        </h2>

        <div className="zusatzsparen-modal-content">
          <Card className="mt-48 profile-modal-content" type={Type.Modal}>
            <Switch>
              <Route
                exact
                path={getRouteByLanguage(
                  DefaultRouteNames.ADDITIONALSAVINGSCUSTOMIZE
                )}
                component={ZusatzsparenCustomize}
              />
              <Route
                exact
                path={getRouteByLanguage(
                  DefaultRouteNames.ADDITIONALSAVINGSCONFIRMATION
                )}
                component={ZusatzsparenConfirmation}
              />
              <Route
                exact
                path={getRouteByLanguage(
                  DefaultRouteNames.ADDITIONALSAVINGSAPPLY
                )}
                component={ZusatzsparenApply}
              />
            </Switch>
          </Card>
        </div>
      </Modal>

      <div className="page-top">
        <Button
          type={ButtonTypes.Text}
          color={ButtonColors.Action}
          onClick={() =>
            props.history.push(getRouteByLanguage(DefaultRouteNames.DASHBOARD))
          }
        >
          <Arrow className="rotate-180" />{" "}
          <span className="ml-16">
            {translate("buttons.backToDashboard", {
              ns: "app",
              defaultValue: "Zurück zum dashboard",
            })}
          </span>
        </Button>
      </div>

      <h1 className="mb-16">
        {t("title", "Altersleistungen erhöhen und Steuern sparen")}
      </h1>

      <p className="text-large mb-48 max-w-lg-100 max-w-xl-75 max-w-50">
        {t(
          "text",
          "Sie haben verschiedene Möglichkeiten, Ihre Altersleistung selber zu erhöhen. Erfahren Sie mehr dazu und simulieren Sie den Einfluss auf Ihre Altersleistung."
        )}
      </p>

      <div className="versicherungen-page-content two-column-layout">
        <div className="left">
          {data.additionalSavingsBox && data.additionalSavingsBox.isVisible && (
            <Card className="mb-48" type={Type.Standard}>
              <Top
                className="mb-58"
                doubleText={{
                  doubleTextTitle: formatMoney(
                    data.additionalSavingsBox.maxPurchaseAmount
                  ),
                  doubleTextSubtitle: t(
                    "additionalSavingsBox.subTitle",
                    "aktuell maximal möglicher Einkauf"
                  ),
                }}
                title={data.additionalSavingsBox.planName}
                icon={<ChartIcon />}
                text={t(
                  "additionalSavingsBox.planSubtile",
                  "Zusatzsparen Option aktuell"
                )}
              />

              <div className="big-chart-wrapper">
                <DonutChart
                  data={{
                    total: data.additionalSavingsBox.maxBalanceAmount,
                    ratio: data.additionalSavingsBox.balanceMaxRatio,
                    items: [
                      {
                        title: t(
                          "additionalSavingsBox.title",
                          "Altersleistungen erhöhen und Steuern sparen"
                        ),
                        subtitle: t(
                          "additionalSavingsBox.subTitle",
                          "aktuell maximal möglicher Einkauf"
                        ),
                        label: formatMoney(
                          data.additionalSavingsBox.currentCreditBalance
                        ).toString(),
                        value: data.additionalSavingsBox?.currentCreditBalance,
                      },
                    ],
                  }}
                  size={365}
                  backgroundStyles={{
                    width: "24.5rem",
                    height: "24.5rem",
                    borderWidth: "2.4rem",
                  }}
                />
              </div>

              {data.simulationBox && data.simulationBox.isVisible && (
                <div className="flexWrapper">
                  <Button
                    type={ButtonTypes.Text}
                    color={ButtonColors.Action}
                    onClick={() => navigateToSimulator(data.simulationBox.link)}
                  >
                    {t("simulationBox.linkText", "ZUSATZSPAREN SIMULIEREN")}

                    <Arrow className="ml-16" />
                  </Button>

                  <Button
                    type={ButtonTypes.Text}
                    color={ButtonColors.Action}
                    onClick={() =>
                      navigateToSimulator(data.simulationBox.link2)
                    }
                  >
                    {t("simulationBox.link2Text", "KAPITALEINKAUF SIMULIEREN")}

                    <Arrow className="ml-16" />
                  </Button>
                  <div className="finalSpacer"></div>
                </div>
              )}
            </Card>
          )}

          {cmsContent?.main && (
            <Card type={Type.Standard}>
              <CustomRichText
                content={cmsContent?.main}
                simulationBox={data.simulationBox}
              />
            </Card>
          )}
        </div>

        <div className="right">
          <Card>
            {sidebarBoxes && (
              <HygraphSidebarComponents
                boxes={sidebarBoxes}
                data={data}
                reactManagedSidebarComponents={reactManagedSidebarComponents}
              />
            )}
          </Card>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  zusatzsparenData: state.zusatzsparen.zusatzsparenData,
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  getZusatzsparenData: bindActionCreators(getZusatzsparenData, dispatch),
  setLoading: (val) => dispatch(setLoading(val)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default withRouter(connector(Zusatzsparen));
