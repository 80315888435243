import styled from "styled-components";
import { breakpoints, palette } from "@icr-ui/theme";

interface Props {
  $colorType?: string;
}

export const StyledWrapper = styled.div<Props>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 32px;
  background: ${(props) => props.$colorType};
  padding: 0 4.8rem;
  transition: all 0.3s ease;

  @media (max-width: ${breakpoints.sm}px) {
    height: 48px;
    padding: 0 2.2rem;
  }

  p {
    font-size: 12px;
    line-height: 16px;
    font-weight: 600;
    text-decoration: underline;
    color: ${palette.text.inverted};
  }

  button {
    display: flex;
    align-items: center;
    gap: 24px;
    background: none;
    border: none;
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    color: ${palette.text.inverted};

    @media (max-width: 768px) {
      span {
        display: none;
      }
    }

    svg {
      width: 18px;

      line {
        stroke: ${palette.text.inverted};
      }
    }
  }
`;
