import React, { useState } from "react";
import {
  Button,
  ButtonColors,
  ButtonStyles,
  ButtonTypes,
  Input,
} from "icr-shared-components/dist";
import { useHistory } from "react-router";
import { bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";

import { RootState } from "../../reducers";
import { Action } from "../../actions";
import {
  updateProfileLoginData,
  setNewEmailAddress,
} from "../../actions/profile";
import { formConfig } from "./formConfig";
import { useValidateFrom } from "../../utils/useValidateFrom";
import { getRouteByLanguage, DefaultRouteNames } from "../../routes";
import { useTranslation } from "react-i18next";

interface Props {
  updateProfileLoginData: any;
  setNewEmailAddress: any;
}

const Email = ({ updateProfileLoginData, setNewEmailAddress }: Props) => {
  const [email, setEmail] = useState("");
  const [isBlurred, setIsBlurred] = useState(false);
  const history = useHistory();
  const validation = useValidateFrom("profile", formConfig, { email });
  const { t: translate } = useTranslation(["profile", "common"]);
  const t = (key: string, defaultValue: string) =>
    translate(key, { ns: "profile", defaultValue });

  const handleSubmit = async () => {
    const payload = {
      emailAddress: email,
    };

    const response = await updateProfileLoginData(payload);
    setNewEmailAddress(email);

    history.push(
      getRouteByLanguage(DefaultRouteNames.PROFILEEMAILVERIFICATION)
    );
  };

  return (
    <>
      <p className="mb-48 profile-mobile-modal-text">
        {t(
          "email.modalHeader",
          "Geben Sie Ihre neue E-Mail-Adresse ein. Lassen Sie sich dann eine Bestätigungsmail senden. In der Mail finden Sie einen Link, um den Wechsel der E-Mail-Adresse abzuschliessen."
        )}
      </p>

      <div className="row">
        <div className="col-md-5 col-xs-12">
          <Input
            type="text"
            name="email"
            value={email}
            onChange={(name, value) => setEmail(value)}
            placeholder={t("email.newEmail", "Ihre neue E-Mail-Adresse")}
            inputWrapperClass="mb-48"
            onBlur={() => setIsBlurred(true)}
            validationMessageConfig={
              (isBlurred ||
                validation?.email?.validation?.type === "success") && {
                config: validation.email.validation,
                visible: true,
              }
            }
          >
            {t("email.newEmail", "Ihre neue E-Mail-Adresse")}
          </Input>
        </div>
      </div>

      <div className="modal-buttons">
        <Button
          type={ButtonTypes.Text}
          color={ButtonColors.Action}
          onClick={() =>
            history.push(getRouteByLanguage(DefaultRouteNames.PROFILE))
          }
        >
          {t("email.goBackButton", "abbrechen")}
        </Button>

        <Button
          isDisabled={
            !email.trim() || validation?.email?.validation?.type !== "success"
          }
          type={ButtonTypes.Standard}
          style={ButtonStyles.Primary}
          color={ButtonColors.Action}
          onClick={handleSubmit}
        >
          {t("email.confirmButton", "EMAIL CODE SENDEN")}
        </Button>
      </div>
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  profileLoginData: state.profile.profileLoginData,
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  updateProfileLoginData: bindActionCreators(updateProfileLoginData, dispatch),
  setNewEmailAddress: bindActionCreators(setNewEmailAddress, dispatch),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(Email);
