import { useTranslation } from "react-i18next";
import { CircularLoader } from "../CircularLoader";

import {
  StyledDescription,
  StyledLoaderWrapper,
  StyledTitle,
  StyledWrapper,
} from "./styles";

const InpersonatedLoader = () => {
  const { t: translate } = useTranslation(["dashboard"]);
  const t = (key: string, defaultValue: string) =>
    translate(key, { defaultValue });

  return (
    <StyledWrapper>
      <StyledLoaderWrapper>
        <StyledTitle>
          {t("impersonatedLoader.title", "Benutzeransicht wird geladen")}
        </StyledTitle>
        <StyledDescription>
          {t(
            "impersonatedLoader.description",
            "Bitte warten Sie. Es kann ein paar Sekunden dauern."
          )}
        </StyledDescription>
        <CircularLoader />
      </StyledLoaderWrapper>
    </StyledWrapper>
  );
};

export default InpersonatedLoader;
