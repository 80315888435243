import { Button, ButtonColors, ButtonTypes } from "icr-shared-components";
import { Fragment, useEffect } from "react";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { bindActionCreators, Dispatch } from "redux";

import { Action, setLoading } from "../../actions";
import { getTodesfallData, TodesfallData } from "../../actions/todesfall";
import { ReactComponent as Arrow } from "../../assets/icons/arrow-right.svg";
import { ReactComponent as TodesfallIcon } from "../../assets/icons/Desktop/todesfall-icon.svg";
import Card, { Type } from "../../components/Card";
import { Top } from "../../components/Card/Content/Top";
import { RootState } from "../../reducers";
import { parseDate } from "../../utils";
import { formatMoney } from "../../utils/currency";
import "../Versicherungen/Versicherungen.scss";
import "./Todesfall.scss";
import { useTranslation } from "react-i18next";
import { cmsSchemas } from "../../constants";
import useGetCMSContent from "../../hooks/cms/useGetCMSContent";
import CustomRichText from "../../components/RichText";
import { getRouteByLanguage, DefaultRouteNames } from "../../routes";
import { useGetSidebarCMSContent } from "../../hooks/cms";
import { HygraphSidebarComponents } from "../../components/sidebar/HygraphSidebarComponents";

interface Props extends RouteComponentProps {
  getTodesfallData: any;
  setLoading: any;
  todesfallData: TodesfallData;
}

const Todesfall = (props: Props) => {
  const schema = cmsSchemas.death.plural;
  const { content: cmsContent } = useGetCMSContent({ schema });
  const { content: cmsSidebarContent } = useGetSidebarCMSContent({ schema });
  const sidebarBoxes = cmsSidebarContent?.sidebar;

  const getInitialData = async () => {
    try {
      props.setLoading(true);
      await props.getTodesfallData();
      props.setLoading(false);
    } catch (error) {
      console.error("error in getting Todesfall page initial data", error);
    }
  };

  const { t: translate } = useTranslation(["fatalityPage", "app"]);
  const t = (key: string, defaultValue: string) =>
    translate(key, { ns: "fatalityPage", defaultValue });

  useEffect(() => {
    getInitialData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const data = props.todesfallData;

  return (
    <div className="pensionierung-page">
      <div className="page-top">
        <Button
          type={ButtonTypes.Text}
          color={ButtonColors.Action}
          onClick={() =>
            props.history.push(getRouteByLanguage(DefaultRouteNames.DASHBOARD))
          }
        >
          <Arrow className="rotate-180" />{" "}
          <span className="ml-16">
            {translate("buttons.backToDashboard", {
              ns: "app",
              defaultValue: "Zurück zum dashboard",
            })}
          </span>
        </Button>
      </div>

      <h1 className="mb-16">{t("title", "Im Todesfall")}</h1>

      <p className="text-large mb-48 max-w-lg-100 max-w-xl-75 max-w-50">
        {t(
          "text",
          "Entscheiden Sie, wer im Todesfall als begünstigte Person eingesetzt wird."
        )}
      </p>

      <div className="versicherungen-page-content two-column-layout">
        <div className="left">
          <Card className="mb-48">
            {data.fatalityBox && data.fatalityBox.isVisible && (
              <Top
                className="p-48 p-24-tablet"
                title={formatMoney(data.fatalityBox.amount)}
                icon={<TodesfallIcon />}
                text={t("fatalityBox.subTitle", "Todesfallkapital in CHF")}
              />
            )}

            {data.beneficariesList?.length > 0 && (
              <>
                <hr />

                <div className="p-48">
                  {data.beneficariesList.map((item, index) => (
                    <Fragment key={index}>
                      <div className="row">
                        <div className="col-md-3 col-sm-12" key={index}>
                          <p>
                            <b>{item.fullName}</b>
                          </p>
                        </div>

                        <div className="col-md-3 col-5">
                          <p>{parseDate(item.birthDate)}</p>
                        </div>

                        <div className="col-md-2 col-3">
                          <p>{item.ratio}</p>
                        </div>

                        <div className="col-md-3 col-4">
                          <p>{item.relationLabel}</p>
                        </div>
                      </div>

                      {index !== data.beneficariesList.length - 1 && (
                        <hr className="mb-32 mt-32" key={index} />
                      )}
                    </Fragment>
                  ))}
                </div>
              </>
            )}
          </Card>

          {cmsContent?.main && (
            <Card type={Type.Standard}>
              <CustomRichText
                content={cmsContent?.main}
                simulationBox={data.simulationBox}
              />
            </Card>
          )}
        </div>

        <div className="right">
          <Card>
            {sidebarBoxes && (
              <HygraphSidebarComponents boxes={sidebarBoxes} data={data} />
            )}
          </Card>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  todesfallData: state.todesfall.todesfallData,
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  getTodesfallData: bindActionCreators(getTodesfallData, dispatch),
  setLoading: (val) => dispatch(setLoading(val)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default withRouter(connector(Todesfall));
